import { UserDto } from '../models/user/UserDto.types';

import { Role, RoleName } from '../constants/role-constants';

export function getUserTypeByRoles(roles: string[]): Role[] {
    const userRoles: Role[] = [];

    // Admin User
    if (roles?.some((role) => role === RoleName.ADMIN)) {
        userRoles.push(Role.ROLE_ADMIN);
    }

    // Cinema User
    if (roles?.some((role) => role === RoleName.CINEMA)) {
        userRoles.push(Role.ROLE_CINEMA);
    }

    // Distributor User
    if (roles?.some((role) => role === RoleName.DISTRIBUTOR)) {
        userRoles.push(Role.ROLE_DISTRIBUTOR);
    }

    // TODO: What's the fallback user type?
    userRoles.push(Role.ROLE_USER);

    return userRoles;
}

export function getUsername(user: UserDto, deletedUserText: string): string {
    if (user.isDeleted) {
        return deletedUserText;
    }
    return user.username;
}

export function getRealName(user: UserDto, deletedUserText: string): string | null {
    if (user.isDeleted) {
        return deletedUserText;
    }
    const realName: string[] = [user.firstName, user.lastName].filter((name) => name);
    return realName.join(' ');
}

export function getUsernameOrRealName(user: UserDto, deletedUserText: string): string {
    if (user.isDeleted) {
        return deletedUserText;
    }
    const realName: string[] = [user.firstName, user.lastName].filter((name) => name);
    return user.isRealNameDisplayEnabled && realName.length > 0 ? realName.join(' ') : user.username;
}
