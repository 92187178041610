import classnames from 'classnames';
import { signIn } from 'next-auth/react';
import { useTranslation } from 'next-i18next';
import React, { useRef, useState } from 'react';

import MenuButtonWithItems from '@/cineamo-frontend-lib/components/ui/buttons/MenuButtonWithItems';
import NotificationsInDropDown from '@/cineamo-frontend-lib/components/ui/notification-dropdown/NotificationsInDropDown';
import NavigationDefaultMenuButtonItems from '@/components/navigation/NavigationDefaultMenuButtonItems';

import { loadAsset } from '@/cineamo-frontend-lib/helper/asset-helper';

import { useNotificationsStore } from '@/cineamo-frontend-lib/store/notifications/notificationsStore';
import { useUserStore } from '@/store/user/userStore';

const defaultUserImage = loadAsset('/images/placeholders/im-avatar.png');

function NavigationDefaultProfileComponent() {
    const { t } = useTranslation('common');

    const { user } = useUserStore();

    const { unreadNotificationsCount } = useNotificationsStore();

    const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState<boolean>(false);

    // <--- NOTIFICATION LOADING --->
    const [showNotifications, setShowNotifications] = useState<boolean>(false);

    const notificationsDropDownRef = useRef<HTMLDivElement | null>(null);

    return (
        <div className="relative flex flex-col h-full">
            <MenuButtonWithItems
                menuClassName={classnames({ hidden: showNotifications })}
                id="login-menu-button"
                menuOpen={isProfileDropdownOpen}
                setMenuOpen={setIsProfileDropdownOpen}
                leadingImageSrc={user?.profileImageUrl ? user.profileImageUrl : defaultUserImage}
                leadingImageSrcFallback={defaultUserImage}
                leadingImageAlt={user?.username ? `${user?.username} Profile Image` : 'User Profile Image'}
                unreadNotificationsNumber={unreadNotificationsCount}>
                {!user ? (
                    <>
                        <MenuButtonWithItems.MenuItem
                            title={t('login')}
                            onClick={() => signIn('cineamo')}
                        />
                        <MenuButtonWithItems.MenuItem
                            title={t('join-us')}
                            href={'/registration'}
                        />
                    </>
                ) : (
                    <NavigationDefaultMenuButtonItems onNotificationsClick={() => setShowNotifications(true)} />
                )}
            </MenuButtonWithItems>

            {showNotifications && (
                <NotificationsInDropDown
                    showNotifications={showNotifications}
                    setShowNotifications={setShowNotifications}
                    notificationsDropDownRef={notificationsDropDownRef}
                    onBackClick={() => {
                        setShowNotifications(false);
                        setIsProfileDropdownOpen(true);
                    }}
                    user={user}
                    t={t}
                />
            )}
        </div>
    );
}

export default NavigationDefaultProfileComponent;
