import create from 'zustand';

import { CinemaDto } from '@/cineamo-frontend-lib/models/cinema/CinemaDto.types';

import { useCinemasBrowserStorage } from '@/src/hooks/useCinemasBrowserStorage';

import { IsomorphicStore, createIsomorphicStore } from '../index';
import { CinemaState, CinemaStore } from './cinemaStore.types';

/**
 * Here you can add more state related to a cinema
 * Note that you can consume cinemaId using the useCinemaStore
 * or by accessing it via props in page components.
 */

// todo update doc
const initialState: CinemaState = {
    selectedCinemas: [],
    selectedCinemaIds: [],
    favoriteCinemas: [],
    cinemasNearby: [],
    cinemasNearbyCount: null,
    eventRequestCinema: null
};

const { setCinemaStateInBrowserStorage } = useCinemasBrowserStorage();

const initializeStore: IsomorphicStore<CinemaStore, CinemaState> = (preloadedState) => {
    // InitialState is merged with preloadedState
    // Preloaded state is available when the server can pre-populate the store
    const combinedState = { ...initialState, ...preloadedState };
    combinedState.selectedCinemaIds = combinedState.selectedCinemas.map((selectedCinema) => selectedCinema.id);

    return create<CinemaStore>((set) => ({
        ...combinedState,

        // Selected cinemas
        addCinemaToSelected(cinema: CinemaDto) {
            const selectedCinemas: CinemaDto[] = combinedState.selectedCinemas || [];
            selectedCinemas.push(cinema);
            combinedState.selectedCinemas = selectedCinemas;
            combinedState.selectedCinemaIds = selectedCinemas.map((cinema) => cinema.id);
            set(combinedState);
            setCinemaStateInBrowserStorage(combinedState);
        },
        addCinemasToSelected(cinemas: CinemaDto[]) {
            const selectedCinemas: CinemaDto[] = combinedState.selectedCinemas || [];
            cinemas.forEach((cinema: CinemaDto) => {
                selectedCinemas.push(cinema);
            });
            combinedState.selectedCinemas = selectedCinemas;
            combinedState.selectedCinemaIds = selectedCinemas.map((cinema) => cinema.id);
            set(combinedState);
            setCinemaStateInBrowserStorage(combinedState);
        },
        deleteCinemaFromSelected(cinema: CinemaDto) {
            const filteredCinemas = combinedState.selectedCinemas.filter((arrayCinema) => arrayCinema.id !== cinema.id);
            combinedState.selectedCinemas = filteredCinemas;
            combinedState.selectedCinemaIds = filteredCinemas.map((cinema) => cinema.id);
            set(combinedState);
            setCinemaStateInBrowserStorage(combinedState);
        },
        deleteAllCinemasFromSelected() {
            combinedState.selectedCinemas = [];
            combinedState.selectedCinemaIds = [];
            set(combinedState);
            setCinemaStateInBrowserStorage(combinedState);
        },
        isCinemaSelected(cinema: CinemaDto): boolean {
            return combinedState.selectedCinemas?.some((selectedCinema) => selectedCinema.id === cinema.id);
        },

        // Favorite cinemas
        addCinemaToFavorites(cinema: CinemaDto, isSelected?: boolean) {
            // Add to favorites
            if (!combinedState.favoriteCinemas.some((favoriteCinema) => favoriteCinema.id === cinema.id)) {
                combinedState.favoriteCinemas.push(cinema);
            }

            // Optionally preselect
            if (isSelected === true) {
                const selectedCinemas: CinemaDto[] = combinedState.selectedCinemas || [];
                selectedCinemas.push(cinema);
                combinedState.selectedCinemas = selectedCinemas;
                combinedState.selectedCinemaIds = selectedCinemas.map((cinema) => cinema.id);
            }
            set(combinedState);
            setCinemaStateInBrowserStorage(combinedState);
        },
        addCinemasToFavorites(cinemas: CinemaDto[]) {
            const favoriteCinemas: CinemaDto[] = combinedState.favoriteCinemas?.filter((it) => !!it) || [];
            cinemas.forEach((cinema: CinemaDto) => {
                if (!favoriteCinemas.some((favoriteCinema) => favoriteCinema.id === cinema.id)) {
                    favoriteCinemas.push(cinema);
                }
            });
            combinedState.favoriteCinemas = favoriteCinemas;
            set(combinedState);
            setCinemaStateInBrowserStorage(combinedState);
        },
        deleteCinemaFromFavorites(cinema: CinemaDto) {
            // Remove from selected
            const filteredCinemas = combinedState.selectedCinemas.filter((arrayCinema) => arrayCinema.id !== cinema.id);
            combinedState.selectedCinemas = filteredCinemas;
            combinedState.selectedCinemaIds = filteredCinemas.map((cinema) => cinema.id);

            // Remove from favorites
            combinedState.favoriteCinemas = combinedState.favoriteCinemas.filter(
                (arrayCinema) => arrayCinema.id !== cinema.id
            );
            set(combinedState);
            setCinemaStateInBrowserStorage(combinedState);
        },
        deleteAllCinemasFromFavorites() {
            combinedState.favoriteCinemas = [];
            set(combinedState);
            setCinemaStateInBrowserStorage(combinedState);
        },
        isCinemaInFavorites(cinema: CinemaDto): boolean {
            return combinedState.favoriteCinemas?.some((arrayCinema) => arrayCinema.id === cinema.id);
        },

        // Cinemas nearby
        updateCinemasNearby(cinemas: CinemaDto[]) {
            combinedState.cinemasNearby = cinemas;
            set(combinedState);
            setCinemaStateInBrowserStorage(combinedState);
        },

        updateCinemasNearbyCount(cinemasCount: number) {
            combinedState.cinemasNearbyCount = cinemasCount;
            set(combinedState);
            setCinemaStateInBrowserStorage(combinedState);
        },

        deleteAllCinemasNearby() {
            combinedState.cinemasNearby = [];
            set(combinedState);
            setCinemaStateInBrowserStorage(combinedState);
        },

        //Event request cinema
        setCinemaAsEventRequestCinema(cinema: CinemaDto) {
            combinedState.eventRequestCinema = cinema;
            set(combinedState);
            setCinemaStateInBrowserStorage(combinedState);
        }
    }));
};

const {
    StoreProvider: CinemaStoreProvider,
    useStore: useCinemaStore,
    useCreateStore: useCreateCinemaStore
} = createIsomorphicStore<typeof initializeStore, CinemaStore, CinemaState>(initializeStore);

export { CinemaStoreProvider, useCinemaStore, useCreateCinemaStore };
