import Head from 'next/head';
import { Thing } from 'schema-dts';

interface StructuredDataProps {
    schema: Thing;
}

// noinspection HtmlRequiredTitleElement
const StructuredData = (props: StructuredDataProps): JSX.Element => (
    <Head>
        <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
                __html: JSON.stringify({
                    '@context': 'https://schema.org',
                    ...(typeof props.schema !== 'string' && props.schema)
                })
            }}
        />
    </Head>
);

export default StructuredData;
