import Axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';

export function createDefaultAxiosClient(config: AxiosRequestConfig = undefined) {
    return Axios.create({
        baseURL: config?.baseURL,
        headers: {
            'Content-Type': 'application/json',
            ...config?.headers,
            ...getServiceNameHeader()
        }
    });
}

function getServiceNameHeader(): AxiosRequestHeaders {
    const serviceName = process.env.NEXT_PUBLIC_ENV_SERVICE_NAME;
    if (serviceName) {
        return {
            'X-Cineamo-App': serviceName
        };
    }

    // If no service name environment variable is set, it will not add a custom x-cineamo-app header.
    return undefined;
}
