import classnames from 'classnames';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';

import MovieSearchBarSearchResult from '@/cineamo-frontend-lib/components/page/movie/MovieSearchBarSearchResult';
import ListLoadingSpinner from '@/cineamo-frontend-lib/components/ui/animation/ListLoadingSpinner';
import MultipurposeSearchBar from '@/cineamo-frontend-lib/components/ui/input/MultipurposeSearchBar';
import TmpPaginator from '@/cineamo-frontend-lib/components/ui/table/TmpPaginator';

import { getMovies } from '@/cineamo-frontend-lib/api/movie/movie-api';

import { getRandomMovieSearchQuery, movieSlugOrCineamoId } from '@/cineamo-frontend-lib/helper/movie-helper';

type NavMovieSearchBarProps = {
    className?: string;
    searchBarClassName?: string;
    inputClassName?: string;
    locale?: string;
};

function NavMovieSearchBar(props: NavMovieSearchBarProps): JSX.Element {
    const { className, searchBarClassName, inputClassName, locale } = props;

    const { t } = useTranslation(['common']);

    const searchInputRef = useRef(null);
    const dropdownRef = useRef(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = useState(1);
    const perPage = 10;

    const {
        data: moviesResponse,
        isLoading: isLoadingMoviesResponse,
        isFetching: isFetchingMoviesResponse
    } = useQuery(
        ['movies', searchQuery, page, isDropdownOpen],
        async () =>
            getMovies({
                query: searchQuery || getRandomMovieSearchQuery(),
                page: page,
                perPage: perPage
            }),
        {
            keepPreviousData: true,
            staleTime: 60000,
            refetchOnWindowFocus: false
        }
    );

    const searchResults = moviesResponse?._embedded?.movies || [];

    function handleClickOutside(event: Event) {
        if (!dropdownRef?.current?.contains(event.target) && !searchInputRef?.current?.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    function handleChange(value) {
        if (value) {
            setSearchQuery(value);
            setIsDropdownOpen(searchQuery?.length >= 0 || searchResults.length > 0);
        }
    }

    function onItemSelect() {
        setSearchQuery('');
        setIsDropdownOpen(false);
    }

    return (
        <div className={classnames(className, 'h-48 relative')}>
            <MultipurposeSearchBar
                className={classnames(
                    'h-full transition-all duration-500 absolute right-0 top-0 z-dropdown bg-white',
                    isDropdownOpen ? 'w-440 bg-opacity-100 rounded-t-8' : 'w-48 bg-opacity-10 rounded-8'
                )}
                placeholder={t('navigation.movie-search-placeholder')}
                value={searchQuery}
                basicInputProps={{
                    className: classnames(searchBarClassName),
                    inputWrapperClassName: classnames(inputClassName, 'text-darkBlue'),
                    inputRadiusClassName: 'radius-8',
                    inputBackgroundClassName: classnames(isDropdownOpen ? 'bg-opacity-100 ' : 'bg-opacity-10'),
                    inputTextClassName: 'text-darkBlue'
                }}
                onInputClick={() => setIsDropdownOpen(true)}
                trailingIconSize={'24'}
                trailingIconColor={isDropdownOpen ? 'text-gray' : 'text-white'}
                onChange={handleChange}
                ref={searchInputRef}
                showInput={isDropdownOpen}
            />

            <div
                className={classnames(
                    'absolute z-dropdown top-full w-440  flex flex-col bg-white right-0 border-t-1 border-darkBlue border-opacity-20 overflow-hidden',
                    'transition-all duration-500',
                    isDropdownOpen ? 'max-h-480 opacity-100 delay-500 rounded-b-8' : 'max-h-0 opacity-0 rounded-8'
                )}
                ref={dropdownRef}>
                <div className="w-full relative flex flex-col h-auto text-darkBlue divide-y-1 divide-darkBlue divide-opacity-20 overflow-auto flex-none">
                    {searchResults.length > 0 ? (
                        searchResults.map((movie, index) => (
                            <MovieSearchBarSearchResult
                                key={`movieListItem#${index}`}
                                movie={movie}
                                onClick={onItemSelect}
                                onClickHref={`/movies/${movieSlugOrCineamoId(movie)}`}
                                locale={locale}
                                t={t}
                            />
                        ))
                    ) : isLoadingMoviesResponse || isFetchingMoviesResponse ? (
                        <ListLoadingSpinner dotClass="bg-darkBlue" />
                    ) : searchResults.length <= 0 && searchQuery?.length <= 0 ? (
                        <span className="text-body-bold-14 text-center opacity-50 py-16">
                            {t('navigation.movie-search-placeholder')}
                        </span>
                    ) : (
                        <span className="text-body-bold-14 text-center opacity-50 py-16">
                            {t('navigation.movie-search-no-results')}
                        </span>
                    )}
                </div>

                <TmpPaginator
                    className="flex-none text-darkBlue my-8"
                    page={page}
                    setPage={setPage}
                    totalItems={moviesResponse?._total_items ?? 0}
                    pageCount={moviesResponse?._page_count ?? 0}
                />
            </div>
        </div>
    );
}

export default NavMovieSearchBar;
