import classnames from 'classnames';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';

import { ImageCineamo24 } from '@/cineamo-frontend-lib/logos/im-cineamo';

export type NavigationCineamoLogoComponentProps = {
    href?: string;
    className?: string;
};

function NavigationCineamoLogoComponent(props: NavigationCineamoLogoComponentProps): JSX.Element {
    const { t } = useTranslation('common');

    return (
        <div className={classnames(props.className)}>
            <Link
                href={props.href || '/'}
                aria-label={t('common:homepage')}>
                <span className="flex items-center space-x-12 lg:space-x-16 clickable-element">
                    <ImageCineamo24 className="w-auto h-28 lg:h-32" />
                </span>
            </Link>
        </div>
    );
}

export default NavigationCineamoLogoComponent;
