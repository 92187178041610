import {CustomSVGProps} from '../types/CustomSVGProps.types';


export function IconHome24(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M22 22.0001V9.07048L12 2.40381L2 9.07048V22.0001H7V16.0001C7 14.3433 8.34315 13.0001 10 13.0001H14C15.6569 13.0001 17 14.3433 17 16.0001V22.0001H22ZM0.8906 7.40638C0.334202 7.77731 0 8.40177 0 9.07048V24.0001H9V16.0001C9 15.4478 9.44772 15.0001 10 15.0001H14C14.5523 15.0001 15 15.4478 15 16.0001V24.0001H24V9.07048C24 8.40177 23.6658 7.77731 23.1094 7.40638L13.1094 0.739708C12.4376 0.291842 11.5624 0.291842 10.8906 0.739708L0.8906 7.40638Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}
