import { AxiosRequestConfig } from 'axios';

import { CartDto } from '../../models/cart/CartDto.types';
import { CartInputDto } from '../../models/cart/CartInputDto';

import { ApiErrorResponse } from '../ApiErrorResponse.types';
import { ApiResponse } from '../ApiResponse.types';
import apiClient from '../cineamoApiClient';

// <------------------------ Cart ------------------------>

/**
 * This endpoint returns a cart
 *
 * @example
 * // Get a cart by id:
 * getCartById(1);
 *
 * @param id
 * @returns {ApiResponse<CartDto>}
 */

export async function getCartById(id: number | string): Promise<ApiResponse<CartDto>> {
    return apiClient
        .get(`/carts/${id}`)
        .then((response) => response.data)
        .catch((error) => error);
}

// <------------------------ Create Cart ------------------------>

/**
 * This endpoint returns a cart.
 *
 * @example
 * // Create a cart:
 * createCart({...cart});
 *
 * @param cart
 * @param options
 * @returns {ApiResponse<CartDto> | ApiErrorResponse<CartDto>}
 */

export async function createCart(
    cart: CartInputDto,
    options?: { confidentialClientAccessToken?: string }
): Promise<ApiResponse<CartDto> | ApiErrorResponse<CartInputDto>> {
    const config: AxiosRequestConfig = {};
    if (options?.confidentialClientAccessToken) {
        config.headers = {
            Authorization: 'Bearer ' + options.confidentialClientAccessToken
        };
    }

    return apiClient
        .post('/carts', JSON.stringify(cart), config)
        .then((response) => response.data)
        .catch((error) => error);
}

// <------------------------ Update Cart ------------------------>

/**
 * This endpoint returns a cart.
 *
 * @example
 * // Update a cart:
 * updateCartById(1, {...cart});
 *
 * @param cart
 * @param cartId
 * @returns {ApiResponse<CartDto> | ApiErrorResponse<CartDto>}
 */

export async function updateCartById(
    cartId: number | string,
    cart: CartInputDto
): Promise<ApiResponse<CartDto> | ApiErrorResponse<CartInputDto>> {
    return apiClient
        .patch(`/carts/${cartId}`, cart)
        .then((response) => response.data)
        .catch((error) => error);
}

// <------------------------ Delete Cart ------------------------>

/**
 * This endpoint returns a boolean.
 *
 * @example
 * // Delete a cart by id:
 * deleteCartById(1);
 *
 * @param cart
 * @returns {boolean}
 */

export async function deleteCartById(cart: number | string): Promise<boolean> {
    return apiClient
        .delete(`/carts/${cart}`)
        .then((response) => response.status === 204)
        .catch(() => false);
}

// <------------------------ Download Cart assets ------------------------>

/**
 * A helper for downloading files with AJAX (axios).
 * Useful, when the endpoint requires Authorization header to be present in Request.
 *
 * @param data
 * @param filename
 */
function saveAs(data: BlobPart, filename: string) {
    const url = window.URL.createObjectURL(new Blob([data]));

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
}

export async function downloadCartTickets(cartUuid: string): Promise<ApiResponse<CartDto>> {
    return apiClient
        .get(`/carts/${cartUuid}/tickets`, {
            responseType: 'blob',
            headers: {
                Accept: 'application/pdf',
                Authorization: `CineamoCart ${cartUuid}`
            }
        })
        .then((response) => saveAs(response.data, 'tickets.pdf'))
        .catch((error) => error);
}

export async function downloadCartInvoice(cartUuid: string): Promise<ApiResponse<CartDto>> {
    return apiClient
        .get(`/carts/${cartUuid}/invoice`, {
            responseType: 'blob',
            headers: {
                Accept: 'application/pdf',
                Authorization: `CineamoCart ${cartUuid}`
            }
        })
        .then((response) => saveAs(response.data, 'invoice.pdf'))
        .catch((error) => error);
}
