import { Tab } from '@headlessui/react';
import React from 'react';

import ActiveLink from '@/components/navigation/ActiveLink';

export type MobileNavigationItemProps = {
    leadingIcon: JSX.Element | string;
    title: string;
    active?: boolean;
    href: string;
    as?: string;
    isNot?: string[];
};

function MobileNavigationItem(props: MobileNavigationItemProps): JSX.Element {
    return (
        <Tab className="flex flex-col text-white justify-center items-center">
            <ActiveLink
                activeClassName="text-cyan font-montserrat-semibold px-24"
                inactiveClassName="opacity-70 hover:opacity-100 active:opacity-100 transition-all duration-150"
                href={props.href}
                as={props.as}
                isNot={props.isNot}>
                <div className="relative flex flex-col items-center space-y-6">
                    <div className="flex-none">{props.leadingIcon}</div>
                    <span className="text-body-bold-14">{props.title}</span>
                </div>
            </ActiveLink>
        </Tab>
    );
}

export default MobileNavigationItem;
