import {CustomSVGProps} from '../types/CustomSVGProps.types';

export function IconNewRequest48(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M28.9986 1.01358e-06C29.4365 -0.00061953 29.8239 0.28375 29.9545 0.701725L32.5401 8.97575L40.2577 11.0338C40.6954 11.1505 41 11.5469 41 12C41 12.4531 40.6954 12.8495 40.2577 12.9662L32.5401 15.0242L29.9545 23.2983C29.824 23.7157 29.4374 24 29 24C28.5626 24 28.176 23.7157 28.0455 23.2983L25.4599 15.0242L17.7423 12.9662C17.3044 12.8495 16.9998 12.4528 17 11.9996C17.0002 11.5464 17.3051 11.15 17.7431 11.0336L25.4856 8.97572L28.0447 0.704431C28.1741 0.286088 28.5607 0.000621558 28.9986 1.01358e-06ZM29.0048 4.36788L27.2327 10.0956C27.1309 10.4245 26.867 10.678 26.5342 10.7664L21.887 12.0016L26.5077 13.2338C26.8391 13.3222 27.1022 13.5743 27.2045 13.9017L29 19.6474L30.7955 13.9017C30.8978 13.5743 31.1609 13.3222 31.4923 13.2338L36.119 12L31.4923 10.7662C31.1609 10.6778 30.8978 10.4257 30.7955 10.0983L29.0048 4.36788Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M15.4803 10.3716L10.5988 8.24304C7.95659 7.09088 5 9.0271 5 11.9096V39.6937C5 42.5762 7.95659 44.5124 10.5988 43.3603L42.4573 29.4682C45.6589 28.0722 45.6589 23.5311 42.4573 22.1351L33.6588 18.2985L32.997 20.1917L41.6579 23.9684C43.2587 24.6664 43.2587 26.9369 41.6579 27.6349L9.79941 41.527C8.47829 42.103 7 41.1349 7 39.6937L7 11.9096C7 10.4684 8.47829 9.50025 9.79941 10.0763L15.0207 12.3531C15.007 12.2367 15 12.1185 15 11.9989C15.0002 11.4048 15.1748 10.8441 15.4803 10.3716Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconNewRequest24(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M13 5V2H15V5H18V7H15V10H13V7H10V5H13Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M8 4.62882L5.24141 3.37491C3.25511 2.47205 1 3.92415 1 6.10601V19.894C1 22.0759 3.25511 23.528 5.24141 22.6251L20.4082 15.7311C22.753 14.6653 22.753 11.3347 20.4082 10.2689L17.6166 9H17V10.9166L19.5806 12.0896C20.3622 12.4449 20.3622 13.5551 19.5806 13.9104L4.4138 20.8044C3.7517 21.1053 3 20.6213 3 19.894L3 6.10601C3 5.37872 3.7517 4.89469 4.4138 5.19564L8 6.82573V4.62882Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconNewRequest16(props: CustomSVGProps): JSX.Element {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 1H10V3L12 2.99999L12 4.99999L10 5V7H8V5L6 4.99999V2.99999L8 3V1Z"
                fill={props.fill || 'currentColor'}/>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 2.64348L2.79522 1.68807C1.47459 1.1158 0 2.08389 0 3.52318V13.4768C0 14.9161 1.47459 15.8842 2.79522 15.3119L14.2802 10.3351C15.8866 9.63901 15.8866 7.36098 14.2802 6.66488L12 5.67681V7.85651L13.485 8.5L2 13.4768L2 3.52318L5 4.82318V2.64348Z"
                fill={props.fill || 'currentColor'}/>
        </svg>

    );
}