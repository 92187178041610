import classnames from 'classnames';
import { useRouter } from 'next/router';
import React from 'react';

import MobileOrDefaultRenderer from '@/components/layouts/MobileOrDefaultRenderer';
import TabListLine from '@/components/misc/TabListLine';
import { BottomButtonWebappMobile } from '@/components/navigation/BottomButtonWebappMobile';
import CenterNavigationLayout from '@/components/navigation/CenterNavigationLayout';
import NavigationCineamoLogoComponent from '@/components/navigation/NavigationCineamoLogoComponent';
import NavigationSearchProfileAndNotificationComponent from '@/components/navigation/NavigationSearchProfileAndNotificationComponent';
import NavigationWebappDefault from '@/components/navigation/NavigationWebappDefault';
import NavigationWebappMobile from '@/components/navigation/NavigationWebappMobile';

function Navigation() {
    const router = useRouter();

    const isBookingPage = router.pathname.includes('/booking/[showingId]');

    return (
        <div className="relative">
            <nav
                className={classnames(
                    'w-full h-auto z-40 transition-colors',
                    'duration-300 items-center text-white flex flex-col'
                )}>
                {/* Start | First row of navigation */}
                <MobileOrDefaultRenderer
                    mobileContent={
                        <div className="w-full">
                            <div className="w-full">
                                <div className="w-full flex justify-between items-center p-9">
                                    <NavigationCineamoLogoComponent className="justify-start" />

                                    <NavigationSearchProfileAndNotificationComponent />
                                </div>
                            </div>
                            <TabListLine />
                        </div>
                    }
                    defaultContent={
                        <div className="flex justify-between items-center w-full px-16 lg:px-48">
                            <NavigationCineamoLogoComponent className="justify-start mr-16 lg:mr-0 lg:w-full" />
                            {!isBookingPage && <NavigationWebappDefault />}
                            <NavigationSearchProfileAndNotificationComponent />
                        </div>
                    }
                />

                {/* Start | Second row of navigation | ENTER NAVIGATION MENU */}
                <CenterNavigationLayout>
                    <MobileOrDefaultRenderer
                        mobileContent={
                            <>
                                <BottomButtonWebappMobile />
                                <NavigationWebappMobile />
                            </>
                        }
                    />
                </CenterNavigationLayout>
            </nav>
        </div>
    );
}

export default Navigation;
