import classnames from 'classnames';
import { useEffect, useState } from 'react';
import { Tab, TabList, Tabs } from 'react-tabs';

type TabSwitchProps = {
    className?: string;
    textClassName?: string;
    tabClassName?: string;
    activeTabClassName?: string;
    state?: boolean;
    disabled?: boolean;
    offText?: string;
    onText?: string;
    offLeadingIcon?: JSX.Element;
    onLeadingIcon?: JSX.Element;
    onChange?: (state) => void;
};
export function TabSwitch(props: TabSwitchProps) {
    const { className, state, offText, onText, onChange, textClassName, activeTabClassName, tabClassName } = props;

    const _offText = offText || '';
    const _onText = onText || '';
    function getActiveTabIndex(switchState: boolean): 0 | 1 {
        switch (switchState) {
            case true:
                return 1;
            default:
                return 0;
        }
    }

    function getStateFromActiveTabIndex(index: 0 | 1) {
        switch (index) {
            case 1:
                return true;
            default:
                return false;
        }
    }

    const [activeId, setActiveId] = useState<number>(getActiveTabIndex(state));

    useEffect(() => {
        setActiveId(getActiveTabIndex(state));
    }, [state]);

    return (
        <Tabs className={className}>
            <TabList
                className="flex flex-row justify-items-stretch cursor-pointer whitespace-pre"
                activeTab={activeId}
                onSelect={(index) => onChange(getStateFromActiveTabIndex(index))}>
                <Tab
                    className={classnames(
                        'w-full px-12',
                        'tab-slider--new',
                        tabClassName,
                        activeId === 0 && (activeTabClassName || 'tab-slider--active'),
                        props.offLeadingIcon && 'flex flex-row items-center space-x-12'
                    )}
                    onClick={() => onChange && onChange(false)}>
                    {props.offLeadingIcon}
                    {_offText &&
                        <span className={classnames('font-montserrat-bold text-button-14 md:text-button-16', textClassName)}>{_offText}</span>}
                </Tab>
                <Tab
                    className={classnames(
                        'w-full px-12',
                        'text-button-14 md:text-button-16',
                        'tab-slider--new',
                        tabClassName,
                        activeId === 1 && (activeTabClassName || 'tab-slider--active'),
                        props.onLeadingIcon && 'flex flex-row items-center space-x-12'
                    )}
                    onClick={() => onChange && onChange(true)}>
                    {props.onLeadingIcon}
                    {_onText &&
                        <span className={classnames('font-montserrat-bold text-button-14 md:text-button-16', textClassName)}>{_onText}</span>}
                </Tab>
            </TabList>
        </Tabs>
    );
}
