import classnames from 'classnames';
import Image from 'next/image';
import React, { ReactElement } from 'react';

import { IconMenu24 } from '../../../icons/menu/ic-menu';
import { IconMenuUnread24 } from '../../../icons/menu/ic_menu_unread';

import TrailingBadgeLink from '../badge/TrailingBadgeLink';
import LinkWithStyle from '../link/LinkWithStyle';

export type MenuButtonWithItemsProps = {
    children?: React.ReactNode;
    id?: string;
    className?: string;
    menuClassName?: string;
    menuOpen?: boolean;
    setMenuOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    menuRef?: React.Ref<unknown>;
    menuIcon?: string | React.ReactNode;
    menuTitle?: string;
    leadingImageSrc?: string;
    leadingImageSrcFallback?: StaticImageData;
    leadingImageAlt?: string;
    trailingImageSrc?: string;
    trailingImageAlt?: string;
    hoverBackground?: string;
    invertedHoverEffect?: boolean;
    onClick?: (menuOpen, setMenuOpen) => void;
    showActiveIcon?: boolean;
    activeItemIndex?: number;
    unreadNotificationsNumber?: number;
};

export type MenuItemProps = {
    className?: string;
    title?: string;
    href?;
    index?: number;
    menuOpen?: boolean;
    onClick?: (index: number, event?, item?, href?: string) => void;
    isActive?: boolean;
    showActiveIcon?: boolean;
    trailingBadgeText?: string;
    leadingIcon?: JSX.Element;
};

export default class MenuButtonWithItems extends React.Component {
    static propTypes: MenuButtonWithItemsProps;

    setMenuOpen;
    menuRef;
    onClick;

    state: {
        menuOpen: boolean;
        activeItemIndex?: number;
    };

    constructor(props: MenuButtonWithItemsProps) {
        super(props);

        this.state = {
            menuOpen: props.menuOpen,
            activeItemIndex: props.activeItemIndex
        };
        this.setMenuOpen = props.setMenuOpen;
        this.menuRef = React.createRef();
        this.onClick = props.onClick;
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount(): void {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount(): void {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate(): void {
        if (this.setMenuOpen) {
            this.setMenuOpen(this.state.menuOpen);
        }
    }

    handleClickOutside(event: Event): void {
        if (this.onClick) {
            return;
        }
        if (this.menuRef && !this.menuRef.current.contains(event.target)) {
            if (this.state.menuOpen) {
                this.setState({ menuOpen: !this.state.menuOpen });
            }
        }
    }

    static MenuItem(props: MenuItemProps): JSX.Element {
        const { className, title, href, index, onClick, isActive, showActiveIcon, trailingBadgeText, leadingIcon } =
            props;

        if (trailingBadgeText) {
            return (
                <TrailingBadgeLink
                    key={title}
                    className={classnames(
                        className,
                        'group hover:bg-cyan hover:bg-opacity-20 px-16 py-8 select-none flex flex-row items-center'
                    )}
                    href={href}
                    trailingBadgeText={trailingBadgeText}
                    onClick={(e) => {
                        if (onClick) {
                            onClick(index, e, title, href);
                        }
                    }}>
                    <div className="flex flex-row space-x-8 items-center">
                        {leadingIcon}
                        <span>{title}</span>
                    </div>
                    {showActiveIcon &&
                        (isActive ? (
                            <div className="ml-16 block w-6 h-6 bg-darkBlue rounded-full group-hover:bg-white" />
                        ) : (
                            <div className="ml-16 block w-6 h-6 rounded-full" />
                        ))}
                </TrailingBadgeLink>
            );
        } else {
            return (
                <LinkWithStyle
                    key={title}
                    className={classnames(
                        className,
                        'group hover:bg-cyan hover:bg-opacity-20 px-16 py-8 select-none flex flex-row items-center justify-between'
                    )}
                    href={href}
                    onClick={(e) => {
                        if (onClick) {
                            onClick(index, e, title, href);
                        }
                    }}>
                    <div className="flex flex-row space-x-8 items-center">
                        {leadingIcon}
                        <span>{title}</span>
                    </div>
                    {showActiveIcon &&
                        (isActive ? (
                            <div className="ml-16 block w-6 h-6 bg-darkBlue rounded-full group-hover:bg-white" />
                        ) : (
                            <div className="ml-16 block w-6 h-6 rounded-full" />
                        ))}
                </LinkWithStyle>
            );
        }
    }

    render(): JSX.Element {
        const {
            children,
            id,
            className,
            menuClassName,
            menuOpen,
            setMenuOpen,
            menuRef,
            menuIcon,
            menuTitle,
            leadingImageSrc,
            leadingImageSrcFallback,
            leadingImageAlt,
            trailingImageSrc,
            trailingImageAlt,
            hoverBackground,
            invertedHoverEffect,
            showActiveIcon,
            activeItemIndex,
            unreadNotificationsNumber
        } = this.props as MenuButtonWithItemsProps;

        const background = hoverBackground || 'bg-white';

        if (setMenuOpen) {
            this.setMenuOpen = setMenuOpen;
        }

        const childrenWithProps = React.Children.map(children, (child: ReactElement, index) => {
            if (!child) return <></>;
            if (child.type === (<MenuButtonWithItems.MenuItem />).type) {
                return React.cloneElement(child, {
                    ...child.props,
                    key: `menu_item_${index}`,
                    setMenuOpen,
                    menuOpen,
                    index,
                    onClick: (index, e, title, href) => {
                        this.setState({ menuOpen: !this.state.menuOpen });
                        if (child.props.onClick) {
                            child.props.onClick(index, e, title, href);
                        }
                    },
                    showActiveIcon: showActiveIcon,
                    isActive: index === activeItemIndex
                });
            }
            return child;
        });

        if (menuRef) {
            this.menuRef = menuRef;
        }

        return (
            <div className="flex-none h-full">
                <div
                    className={classnames(className, 'flex flex-col items-end z-menu-button h-full')}
                    ref={this.menuRef}
                    onClick={() => {
                        if (this.onClick) {
                            const setMenuOpen = (state: boolean) => {
                                this.setState({ menuOpen: state });
                            };
                            this.onClick(setMenuOpen);
                        }
                    }}>
                    <div
                        id={id}
                        className={classnames(
                            'relative hover:${background} hover:bg-opacity-30 active:bg-opacity-20 rounded-8 cursor-pointer h-full',
                            invertedHoverEffect !== true ? background + ' bg-opacity-20' : ''
                        )}
                        onClick={() => {
                            this.setState({ menuOpen: !this.state.menuOpen });
                            if (setMenuOpen) {
                                setMenuOpen(!this.state.menuOpen);
                            }
                        }}>
                        <div className="flex flex-row items-center select-none px-10 py-6 h-full">
                            {leadingImageSrc && (
                                <div className="flex mr-8">
                                    <Image
                                        height="32px"
                                        width="32px"
                                        src={leadingImageSrc || leadingImageSrcFallback}
                                        alt={leadingImageAlt}
                                        className="rounded-full object-cover ml-3"
                                    />
                                </div>
                            )}
                            {menuTitle && <span className="flex min-h-24 items-center">{menuTitle}</span>}
                            {menuIcon && typeof menuIcon === 'string' ? (
                                <div className="w-24 h-24 relative">
                                    <Image
                                        layout="fill"
                                        src={menuIcon}
                                        alt="menu icon"
                                    />
                                </div>
                            ) : menuIcon && React.isValidElement(menuIcon) ? (
                                <div className="flex h-24 items-center">{menuIcon}</div>
                            ) : !menuTitle ? (
                                unreadNotificationsNumber > 0 ? (
                                    <IconMenuUnread24 />
                                ) : (
                                    <IconMenu24 />
                                )
                            ) : null}
                            {trailingImageSrc && (
                                <div className="flex ml-8">
                                    <Image
                                        height="32px"
                                        width="32px"
                                        src={trailingImageSrc}
                                        alt={trailingImageAlt}
                                        className="rounded-full object-cover ml-3"
                                    />
                                </div>
                            )}
                        </div>
                        <div
                            className={classnames(
                                menuClassName,
                                'z-menu-button right-0 absolute whitespace-nowrap flex flex-col w-auto min-w-72 ml-4 my-8 rounded-4 bg-white',
                                'text-black text-body-14 overflow-hidden transform transition-all duration-300',
                                {
                                    'max-h-screen h-auto': this.state.menuOpen,
                                    'max-h-0': !this.state.menuOpen
                                }
                            )}>
                            {childrenWithProps}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
