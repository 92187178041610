import React, { ReactElement } from 'react';

import LinkWithStyle from '@/components/ui/link/LinkWithStyle';

interface VerticalViewProps {
    className?: string;
    title?: string;
    titelHref?: string;
    titleStyle?: string;
    children: React.ReactNode;
}

function VerticalView(props: VerticalViewProps): ReactElement {
    const { className, title, titleStyle, titelHref } = props;

    return (
        <div className={`flex flex-col first:ml-0 text-white ${className}`}>
            {title && titelHref ? (
                <h3>
                    <LinkWithStyle
                        className={`${titleStyle} font-montserrat-bold`}
                        href={titelHref}>
                        {title}
                    </LinkWithStyle>
                </h3>
            ) : (
                <h3 className={`${titleStyle} font-montserrat-bold`}>{title}</h3>
            )}
            {props.children}
        </div>
    );
}

export default VerticalView;
