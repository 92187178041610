import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import { IconDown16 } from '../../../icons/arrows/ic-down';

import ErrorLabel from '../label/ErrorLabel';
import InputLabel from '../label/InputLabel';

export type BasicDropdownNewOption<TValue = string | number> = {
    value: TValue;
    label?: string | JSX.Element;
    isSelectable?: boolean;
};

export enum BasicDropdownNewStyle {
    default = 'default',
    light = 'light',
    dropdown_on_white_bg = 'dropdown_on_white_bg',
    bg_white = 'bg_white'
}

export type BasicDropdownNewStyleConfig = {
    itemBgColorClass?: string;
    listBgColorClass?: string;
    selectedItemClassName?: string;
    style?: BasicDropdownNewStyle;
};

export type BasicDropdownNewProps<TValue = string | number> = {
    className?: string;
    itemClassName?: string;
    style?: BasicDropdownNewStyle | BasicDropdownNewStyleConfig;
    height?: 'default-48' | 'custom';
    label?: string;
    placeholder?: string | JSX.Element;
    preselectedIndex?: number;
    options: BasicDropdownNewOption<TValue>[];
    onChange?: (selectedItem: BasicDropdownNewOption<TValue>, index: number) => boolean | void;
    onClose?: () => void;
    customIcon?: JSX.Element;
    disabled?: boolean;
    isDropdownAbsolutePositioned?: boolean;
    error?: string;
    openingDirection?: 'up' | 'down';
    required?: boolean;
    paginationRef?: (node?: Element) => void;
};

function BasicDropdownNew<TValue>(props: BasicDropdownNewProps<TValue>): JSX.Element {
    const {
        className,
        itemClassName,
        style,
        height,
        label,
        preselectedIndex,
        options,
        onChange,
        onClose,
        customIcon,
        disabled,
        isDropdownAbsolutePositioned,
        placeholder,
        error,
        openingDirection,
        paginationRef
    } = props;

    const dropdownRef = useRef(null);

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState<number>(
        placeholder && preselectedIndex === undefined ? -1 : preselectedIndex ? preselectedIndex : 0
    );
    const [focused, setFocused] = useState(false);

    function handleClickOutside(event: Event) {
        if (!dropdownRef?.current?.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    // Update the current selected option if the preselectedIndex changes.
    useEffect(() => {
        setSelectedOption(placeholder && preselectedIndex === undefined ? -1 : preselectedIndex ? preselectedIndex : 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preselectedIndex]);

    useEffect(() => {
        if (!isDropdownOpen && onClose) {
            onClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDropdownOpen]);

    let labelColor = 'text-gray-transparent';
    let focusedLabelColor = 'text-cyan';
    let selectedItemBackgroundColor: string;
    let itemTextClassname: string;
    let listBackgroundColor: string;
    let selectableItemBackgroundColor: string;

    switch (style && typeof style !== 'string' ? style.style : style) {
        case BasicDropdownNewStyle.dropdown_on_white_bg:
            labelColor = 'text-darkBlue-transparent';
            focusedLabelColor = 'text-darkBlue';
            selectedItemBackgroundColor = classnames(
                'bg-gray-light hover:bg-cyan hover:bg-opacity-20 active:opacity-80',
                style && typeof style !== 'string' && 'selectedItemClassName' in style
                    ? style.selectedItemClassName
                    : ''
            );
            listBackgroundColor = classnames(
                style && typeof style !== 'string' && 'listBgColorClass' in style
                    ? style.listBgColorClass
                    : 'bg-darkBlue bg-opacity-20'
            );
            selectableItemBackgroundColor = classnames(
                'bg-gray-light hover:bg-cyan hover:bg-opacity-20 active:opacity-80',
                style && typeof style !== 'string' && 'itemBgColorClass' in style ? style.itemBgColorClass : ''
            );
            break;
        case BasicDropdownNewStyle.light:
            labelColor = 'text-darkBlue-transparent';
            focusedLabelColor = 'text-darkBlue';
            selectedItemBackgroundColor = classnames(
                'hover:bg-cyan hover:bg-opacity-20 active:opacity-80',
                style && typeof style !== 'string' && 'selectedItemClassName' in style
                    ? style.selectedItemClassName
                    : ''
            );
            listBackgroundColor = classnames(
                style && typeof style !== 'string' && 'listBgColorClass' in style
                    ? style.listBgColorClass
                    : 'bg-white bg-opacity-80'
            );
            selectableItemBackgroundColor = classnames(
                'hover:bg-cyan hover:bg-opacity-20 active:opacity-80',
                style && typeof style !== 'string' && 'itemBgColorClass' in style ? style.itemBgColorClass : ''
            );
            break;
        case BasicDropdownNewStyle.bg_white:
            selectedItemBackgroundColor = classnames(
                'bg-darkBlue bg-opacity-20 hover:bg-cyan hover:bg-opacity-20 active:opacity-80',
                style && typeof style !== 'string' && 'selectedItemClassName' in style
                    ? style.selectedItemClassName
                    : 'bg-opacity-20'
            );
            listBackgroundColor = classnames(
                style && typeof style !== 'string' && 'listBgColorClass' in style ? style.listBgColorClass : 'bg-white'
            );
            itemTextClassname = 'text-darkBlue text-body-14 font-medium';
            selectableItemBackgroundColor = classnames(
                'hover:bg-opacity-50 active:opacity-80',
                style && typeof style !== 'string' && 'itemBgColorClass' in style
                    ? style.itemBgColorClass
                    : 'hover:bg-gray'
            );
            break;
        default:
            selectedItemBackgroundColor = classnames(
                'bg-white bg-opacity-20 hover:bg-cyan hover:bg-opacity-20 active:opacity-80',
                style && typeof style !== 'string' && 'selectedItemClassName' in style
                    ? style.selectedItemClassName
                    : 'bg-opacity-20'
            );
            listBackgroundColor = classnames(
                style && typeof style !== 'string' && 'listBgColorClass' in style
                    ? style.listBgColorClass
                    : 'bg-white bg-opacity-20'
            );
            selectableItemBackgroundColor = classnames(
                'hover:bg-opacity-20 active:opacity-80',
                style && typeof style !== 'string' && 'itemBgColorClass' in style
                    ? style.itemBgColorClass
                    : 'bg-white bg-opacity-20 hover:bg-cyan'
            );
    }

    function onDropdownOpenClickHandler() {
        setIsDropdownOpen(!isDropdownOpen);
    }

    function enterFocus() {
        if (disabled !== true) {
            setFocused(true);
        }
    }

    function exitFocus() {
        setFocused(false);
    }

    function onChangeInternal(option: BasicDropdownNewOption<TValue>, index: number) {
        setIsDropdownOpen(false);
        if (onChange) {
            const apply = onChange(option, index);
            if (apply !== false) {
                setSelectedOption(index);
            }
        } else {
            setSelectedOption(index);
        }
    }

    return (
        <div
            className={classnames(className, 'w-full flex flex-col')}
            onFocus={enterFocus}
            onBlur={exitFocus}
            ref={dropdownRef}>
            {label && (
                <InputLabel
                    label={label}
                    labelStyle={classnames(
                        { 'opacity-100': focused },
                        { [focusedLabelColor]: focused },
                        { [labelColor]: !focused }
                    )}
                    requiredLabel={props.required}
                />
            )}
            <div
                className={classnames('relative', {
                    'h-48': height !== 'custom' && isDropdownAbsolutePositioned !== false,
                    'h-full': height === 'custom' || isDropdownAbsolutePositioned
                })}>
                <div
                    className={classnames(
                        'flex flex-row items-center justify-between h-full select-none space-x-12',
                        'transition-colors transition-opacity duration-100',
                        'active:opacity-80',
                        selectedItemBackgroundColor,
                        {
                            'px-12 py-6': !(
                                options?.[selectedOption]?.label && React.isValidElement(options[selectedOption].label)
                            ),
                            'rounded-t-4': openingDirection !== 'up' || (!isDropdownOpen && openingDirection === 'up'),
                            'rounded-b-4': (!isDropdownOpen && openingDirection !== 'up') || openingDirection === 'up'
                        }
                    )}
                    onClick={onDropdownOpenClickHandler}>
                    {selectedOption === -1 ? (
                        <span>{placeholder}</span>
                    ) : (
                        <span>
                            {options?.[selectedOption]?.isSelectable !== false &&
                                (options?.[selectedOption]?.label ?? '-')}
                        </span>
                    )}
                    {customIcon || (
                        <div>
                            <IconDown16
                                className={classnames('transition-all duration-100 flex-none', {
                                    'mr-12':
                                        options?.[selectedOption]?.label &&
                                        React.isValidElement(options[selectedOption].label),
                                    'rotate-180': isDropdownOpen
                                })}
                            />
                        </div>
                    )}
                </div>
                {isDropdownOpen && (
                    <div
                        className={classnames('w-full h-1', listBackgroundColor, {
                            absolute: isDropdownAbsolutePositioned !== false,
                            'bottom-full': openingDirection === 'up'
                        })}
                    />
                )}
                {isDropdownOpen && (
                    <div
                        className={classnames(
                            'w-full overflow-hidden z-modal-view',
                            {
                                absolute: isDropdownAbsolutePositioned !== false,
                                'bottom-full rounded-t-4': openingDirection === 'up',
                                'top-full rounded-b-4': openingDirection !== 'up'
                            },
                            listBackgroundColor
                        )}>
                        <div className="overflow-auto max-h-480">
                            {options?.map((it, index) => (
                                <div
                                    key={`dropdownItem#${index}`}
                                    className={classnames(
                                        'transition-all duration-100',
                                        {
                                            'px-12 py-6': !React.isValidElement(it.label)
                                        },
                                        {
                                            'cursor-pointer': it.isSelectable !== false
                                        },
                                        it.isSelectable !== false && selectableItemBackgroundColor,
                                        itemClassName,
                                        itemTextClassname
                                    )}
                                    onClick={() => {
                                        if (it.isSelectable !== false) {
                                            onChangeInternal(it, index);
                                        }
                                    }}>
                                    {it.label ?? '-'}
                                </div>
                            ))}
                            {paginationRef && <div ref={paginationRef} />}
                        </div>
                    </div>
                )}
            </div>
            {error && <ErrorLabel error={error} />}
        </div>
    );
}

export default BasicDropdownNew;
