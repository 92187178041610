import { MovieDto } from '../../models/movie/MovieDto.types';

import { CountryCode } from '../../types/CountryCode';

import { UrlQueryParameter, buildUrlQuery } from '../../helper/url-query-helper';

import { MovieListResponse } from '../ApiListResponse.types';
import { ApiResponse } from '../ApiResponse.types';
import apiClient from '../cineamoApiClient';

// <------------------------ Movies ------------------------>

type GetMoviesQueryParameter = UrlQueryParameter & {
    releaseDateStart?: string;
    releaseDateEnd?: string;
    region?: string;
    isSlugMissing?: boolean;
    distributorId?: string;
    isShowingNow?: boolean;
    cinemaCountryCode?: CountryCode;
};

/**
 * This endpoint returns a list of movies.
 *
 * @example
 * // Get a list of movies:
 * getMovies({...urlParams})
 *
 * @param urlParams
 * @returns {ApiResponse<MovieListResponse>}
 */

export async function getMovies(urlParams?: GetMoviesQueryParameter): Promise<ApiResponse<MovieListResponse>> {
    return apiClient
        .get(`/movies${buildUrlQuery(urlParams)}`)
        .then((response) => response.data)
        .catch(() => {
            return null;
        });
}

// <------------------------ Movie ------------------------>

/**
 * This endpoint returns a movie.
 *
 * @example
 * // Get a movie by id:
 * getMovies(1)
 *
 * @param movieId
 * @returns {ApiResponse<MovieDto>}
 */

export async function getMovieById(movieId: string): Promise<ApiResponse<MovieDto>> {
    return apiClient
        .get(`/movies/${movieId}`)
        .then((response) => response.data)
        .catch(() => {
            return null;
        });
}

/**
 * This endpoint syncs Movie with TMDB.
 * @param movieId
 */
export async function fetchTmdbMovie(movieId: number | string): Promise<boolean> {
    return apiClient
        .post(`/movies/${movieId}/fetch-tmdb`)
        .then((response) => response.status === 204)
        .catch(() => false);
}
