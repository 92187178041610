import { useEffect, useState } from 'react';

import { ApiResponse } from '../api/ApiResponse.types';

type UseGetAllListItemsReturn<TItemDto> = {
    data: TItemDto[];
    isLoading: boolean;
    /** @deprecated Replaced with `isLoading` only. */
    isFetching: boolean;
    reload: () => void;
    refetch: () => void;
};

export function useGetAllListItems<TItemDto, TList extends Record<string, unknown>>(
    queryFn: (page: number) => Promise<ApiResponse<unknown, TList>>,
    listAccessor: keyof TList,
    dependencies?: (string | unknown)[]
): UseGetAllListItemsReturn<TItemDto> {
    const [isLoading, setIsLoading] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [data, setData] = useState<TItemDto[]>([]);

    async function getItems(page = 1): Promise<TItemDto[]> {
        setIsFetching(true);
        const items = await getAllListItems<TItemDto, TList>({
            queryFn: queryFn,
            listAccessor: listAccessor,
            page: page
        });
        setIsFetching(false);
        setIsLoading(false);
        return items;
    }

    function refetch() {
        getItems().then((items) => setData(items));
    }

    function reload() {
        setIsLoading(true);
        refetch();
    }

    useEffect(() => {
        if (isLoading) {
            return;
        }
        setIsLoading(true);
        getItems().then((items) => setData(items));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies || []);

    return {
        data: data,
        isLoading: isLoading,
        isFetching: isFetching,
        reload: reload,
        refetch: refetch
    };
}

export async function getAllListItems<TItemDto, TList extends Record<string, unknown>>(options: {
    queryFn: (page: number) => Promise<ApiResponse<unknown, TList>>;
    listAccessor: keyof TList;
    page?: number;
}): Promise<TItemDto[]> {
    const page = options.page || 1;
    const queryFn = options.queryFn;
    const listAccessor = options.listAccessor;
    const firstPage: ApiResponse<unknown, TList & Record<string, unknown>> = await queryFn(page);
    const pageRequests: Promise<ApiResponse<unknown, TList & Record<string, unknown>>>[] = [];
    if (firstPage?._page_count > 1) {
        for (let nextPage = page + 1; nextPage <= firstPage._page_count; nextPage++) {
            pageRequests.push(queryFn(nextPage));
        }
    }
    const otherPages = await Promise.all(pageRequests);
    const pages = [firstPage, ...otherPages];
    return pages.flatMap((page) =>
        page?._embedded && listAccessor in page._embedded
            ? (page._embedded?.[listAccessor as string] as TItemDto[])
            : []
    );
}
