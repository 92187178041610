import React from 'react';

function TabListLine(): JSX.Element {
    return (
        <div className="absolute left-0 bottom-0 w-full">
            <div className="-mt-2 border-b-2 border-white border-opacity-20" />
        </div>
    );
}

export default TabListLine;
