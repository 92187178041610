import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import {
    ANALYSIS_COOKIES_PROPERTY_NAME,
    CookieStateListType,
    ESSENTIAL_COOKIES_PROPERTY_NAME,
    FUNCTIONAL_COOKIES_PROPERTY_NAME
} from '../constants/cookie-constants';

import { cookieStorage } from '../helper/cookie-helper';

type UseCookiesReturn = {
    cookiesState: CookieStateListType;
    essentialCookiesPermissionState: boolean;
    functionalCookiesPermissionState: boolean;
    analysisCookiesPermissionState: boolean;
    setFunctionalCookiesPermissionState: Dispatch<SetStateAction<boolean>>;
    setAnalysisCookiesPermissionState: Dispatch<SetStateAction<boolean>>;
    acceptAllOptionalCookies: () => void;
    rejectAllOptionalCookies: () => void;
    save: () => void;
};

export default function useCookies(): UseCookiesReturn {
    const [essentialCookiesPermissionState] = useState<boolean>(
        cookieStorage.getItem(ESSENTIAL_COOKIES_PROPERTY_NAME) ?? true
    );
    const [functionalCookiesPermissionState, setFunctionalCookiesPermissionState] = useState<boolean>(
        cookieStorage.getItem(FUNCTIONAL_COOKIES_PROPERTY_NAME) ?? true
    );
    const [analysisCookiesPermissionState, setAnalysisCookiesPermissionState] = useState<boolean>(
        cookieStorage.getItem(ANALYSIS_COOKIES_PROPERTY_NAME) ?? true
    );

    const liveCookieStates = {
        essentialCookiesPermissionState: cookieStorage.getItem(ESSENTIAL_COOKIES_PROPERTY_NAME) ?? true,
        functionalCookiesPermissionState: cookieStorage.getItem(FUNCTIONAL_COOKIES_PROPERTY_NAME) ?? true,
        analysisCookiesPermissionState: cookieStorage.getItem(ANALYSIS_COOKIES_PROPERTY_NAME) ?? true
    };

    const cookiesState: CookieStateListType = {
        essentialCookieState: {
            string: ESSENTIAL_COOKIES_PROPERTY_NAME,
            value: true
        },
        functionalCookieState: {
            string: FUNCTIONAL_COOKIES_PROPERTY_NAME,
            value: functionalCookiesPermissionState
        },
        analysisCookieState: {
            string: ANALYSIS_COOKIES_PROPERTY_NAME,
            value: analysisCookiesPermissionState
        }
    };

    function acceptAllOptionalCookies() {
        cookiesState.functionalCookieState.value = true;
        cookiesState.analysisCookieState.value = true;

        setFunctionalCookiesPermissionState(true);
        setAnalysisCookiesPermissionState(true);

        saveCookiesPreferences();
    }

    function rejectAllOptionalCookies() {
        cookiesState.functionalCookieState.value = false;
        cookiesState.analysisCookieState.value = false;

        setFunctionalCookiesPermissionState(false);
        setAnalysisCookiesPermissionState(false);

        saveCookiesPreferences();
    }

    function saveCookiesPreferences() {
        Object.keys(cookiesState).forEach((key) => {
            cookieStorage.setItem(cookiesState[key].string, cookiesState[key].value);
        });
    }

    useEffect(() => {
        const functionalCookiesPropertyValue = liveCookieStates.functionalCookiesPermissionState;
        const analysisCookiesPropertyName = liveCookieStates.analysisCookiesPermissionState;
        setFunctionalCookiesPermissionState(functionalCookiesPropertyValue);
        setAnalysisCookiesPermissionState(analysisCookiesPropertyName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(liveCookieStates)]);

    return {
        cookiesState: cookiesState,
        essentialCookiesPermissionState: essentialCookiesPermissionState,
        functionalCookiesPermissionState: functionalCookiesPermissionState,
        analysisCookiesPermissionState: analysisCookiesPermissionState,
        setFunctionalCookiesPermissionState: setFunctionalCookiesPermissionState,
        setAnalysisCookiesPermissionState: setAnalysisCookiesPermissionState,
        acceptAllOptionalCookies: acceptAllOptionalCookies,
        rejectAllOptionalCookies: rejectAllOptionalCookies,
        save: saveCookiesPreferences
    };
}
