import classnames from 'classnames';
import { useTranslation } from 'next-i18next';
import React from 'react';

import LinkWithStyle from '@/components/ui/link/LinkWithStyle';

import { IconSearch24 } from '@/cineamo-frontend-lib/icons/menu/ic-search';

export type MovieSearchButtonProps = {
    className?: string;
};

function MovieSearchButton(props: MovieSearchButtonProps): JSX.Element {
    const { className } = props;

    const { t } = useTranslation(['common']);

    return (
        <LinkWithStyle
            className={classnames(
                className,
                'flex items-center justify-center w-48 h-48',
                'bg-white bg-opacity-20 active:opacity-50 rounded-8',
                'transition-all duration-150'
            )}
            accessibilityLabel={t('navigation.movie-search')}
            href={'/movies'}>
            <IconSearch24 className="flex-none text-white" />
        </LinkWithStyle>
    );
}

export default MovieSearchButton;
