import { ReactNode } from 'react';

export type CenterLayoutProps = {
    children?: ReactNode;
    className?: string;
};

function CenterLayout(props: CenterLayoutProps): JSX.Element {
    const { className } = props;

    return <div className={`${className} container mx-auto`}>{props.children}</div>;
}

export default CenterLayout;
