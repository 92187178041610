import create from 'zustand';

import { useLocationBrowserStorage } from '@/src/hooks/useLocationBrowserStorage';

import { IsomorphicStore, createIsomorphicStore } from '@/store/index';
import { LocationState, LocationStore } from '@/store/location/locationStore.types';

export const initialState: LocationState = {
    longitude: undefined,
    latitude: undefined,
    locationLevel: 'ip',
    useLocation: undefined,
    isCustomDistance: false,
    distance: 5000 // meters,
};

const { setLocationStateInBrowserStorage } = useLocationBrowserStorage();

const initializeStore: IsomorphicStore<LocationStore, LocationState> = (preloadedState) => {
    const combinedState = { ...initialState, ...preloadedState };
    if (typeof document !== 'undefined' && preloadedState.locationLevel === 'ip') {
        setLocationStateInBrowserStorage(preloadedState);
    }
    return create<LocationStore>((set) => ({
        ...combinedState,
        setLocation(longitude, latitude) {
            combinedState.longitude = longitude;
            combinedState.latitude = latitude;
            set(combinedState);
            setLocationStateInBrowserStorage(combinedState);
        },
        setDistance(distance) {
            combinedState.distance = distance;
            set(combinedState);
            setLocationStateInBrowserStorage(combinedState);
        },
        unsetLocation() {
            combinedState.longitude = undefined;
            combinedState.latitude = undefined;
            set(combinedState);
            setLocationStateInBrowserStorage(combinedState);
        },
        setLocationLevel(level: undefined | 'ip' | 'browser') {
            combinedState.locationLevel = !level ? 'ip' : level;
            set(combinedState);
            setLocationStateInBrowserStorage(combinedState);
        },
        setUseLocation(state: boolean) {
            combinedState.useLocation = state;
            set(combinedState);
            setLocationStateInBrowserStorage(combinedState);
        },
        setIsCustomDistance() {
            combinedState.isCustomDistance = true;
            set(combinedState);
            setLocationStateInBrowserStorage(combinedState);
        },
        isAllLocationDetailsAvailable(): boolean {
            return (
                !(combinedState.longitude === null || combinedState.longitude === undefined) &&
                !(combinedState.latitude === null || combinedState.latitude === undefined)
            );
        }
    }));
};

const {
    StoreProvider: LocationStoreProvider,
    useStore: useLocationStore,
    useCreateStore: useCreateLocationStore
} = createIsomorphicStore<typeof initializeStore, LocationStore, LocationStore>(initializeStore);

export { LocationStoreProvider, useLocationStore, useCreateLocationStore };
