import { Tab } from '@headlessui/react';
import classnames from 'classnames';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useRef } from 'react';
import ReactDOM from 'react-dom';

import MobileNavigationItem from '@/components/navigation/MobileNavigationItem';

import { StyleFadeDirection, styleFaded } from '@/cineamo-frontend-lib/helper/styleHelper';

import { useWebappStore } from '@/store/webapp/webappStore';

import { IconDeals24 } from '@/cineamo-frontend-lib/icons/ic-deals';
import { IconHome24 } from '@/cineamo-frontend-lib/icons/ic-home';
import { IconProfile24 } from '@/cineamo-frontend-lib/icons/ic-profile';
import { IconProgram24 } from '@/cineamo-frontend-lib/icons/ic-program';
import { IconSearch24 } from '@/cineamo-frontend-lib/icons/menu/ic-search';

function NavigationWebappMobile(): JSX.Element {
    const { t } = useTranslation('common');

    const { hideNavigationMobile } = useWebappStore();

    const router = useRouter();

    // Hide component on movie detail page
    const isPageMovieDetail = router.pathname.includes('/movies/[movieId]');

    const element = (
        <div
            className={classnames(
                'relative',
                'h-72'
                // showNavigationMobile ? 'h-auto py-12' : 'h-0 py-0'
            )}>
            <div
                className={classnames(
                    'absolute top-0 left-0 h-full w-full',
                    'bg-darkBlue bg-opacity-70 backdrop-filter backdrop-blur-xl'
                )}
                style={styleFaded(StyleFadeDirection.Top, 30)}></div>

            <div className="absolute top-0 z-modal-view w-full py-12">
                <Tab.Group>
                    <Tab.List className="w-full grid grid-cols-5 center justify-center items-center">
                        <MobileNavigationItem
                            leadingIcon={<IconHome24 className="flex-none" />}
                            title={t('navigation.home')}
                            href={'/'}
                            as={'/'}
                            isNot={['/', '/my-profile', '/movies', '/program', '/deals']}
                        />
                        <MobileNavigationItem
                            leadingIcon={<IconSearch24 />}
                            title={t('navigation.search')}
                            href={'/movies'}
                            as={'/movies'}
                            isNot={['/', '/my-profile', '/program', '/deals']}
                        />
                        <MobileNavigationItem
                            leadingIcon={<IconProgram24 />}
                            title={t('navigation.program')}
                            href={'/program'}
                            as={'/program'}
                            isNot={['/', '/my-profile', '/search', '/deals']}
                        />
                        <MobileNavigationItem
                            leadingIcon={<IconDeals24 className="flex-none" />}
                            title={t('navigation.deals')}
                            href={'/deals'}
                            as={'/deals'}
                            isNot={['/', '/my-profile', '/movies', '/program']}
                        />
                        <MobileNavigationItem
                            leadingIcon={<IconProfile24 />}
                            title={t('navigation.profile')}
                            href={'/my-profile'}
                            as={'/my-profile'}
                            isNot={['/', '/movies', '/program', '/deals']}
                        />
                    </Tab.List>
                </Tab.Group>
            </div>
        </div>
    );

    const mobileMenuContainer = useRef(typeof document !== 'undefined' && document.getElementById('mobile-nav'));

    if (mobileMenuContainer.current && !hideNavigationMobile && !isPageMovieDetail) {
        return ReactDOM.createPortal(element, mobileMenuContainer.current);
    }

    return null;
}

export default NavigationWebappMobile;
