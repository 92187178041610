import { CustomSVGProps } from '../../types/CustomSVGProps.types';

export function IconMenuUnread24(props:CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M24 21L-3.47901e-07 21L7.47917e-07 19L24 19L24 21Z"
                  fill={props.fill || "#F2F2F2"}/>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M-5.61131e-07 11L24 11L24 13L-4.76837e-07 13L-5.61131e-07 11Z"
                  fill="#F2F2F2"/>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M17 3L0 3.00003L3.8147e-06 5.00003L17 5V3Z"
                  fill={props.fill || "#F2F2F2"}/>
            <rect x="19" width="5" height="5" rx="1" fill="#00FFDC"/>
        </svg>
    );
}
