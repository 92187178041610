import {CustomSVGProps} from '../../types/CustomSVGProps.types';

export function IconDistributorApp(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="325" height="325" viewBox="0 0 325 325" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 81.25C0 36.3769 36.3769 0 81.25 0H243.75C288.623 0 325 36.3769 325 81.25V243.75C325 288.623 288.623 325 243.75 325H81.25C36.3769 325 0 288.623 0 243.75V81.25Z"
                fill="#000023"/>
            <path
                d="M0 81.25C0 36.3769 36.3769 0 81.25 0H243.75C288.623 0 325 36.3769 325 81.25V243.75C325 288.623 288.623 325 243.75 325H81.25C36.3769 325 0 288.623 0 243.75V81.25Z"
                fill="url(#paint0_radial_3483_51576)" fillOpacity="0.9"/>
            <path
                d="M0 81.25C0 36.3769 36.3769 0 81.25 0H243.75C288.623 0 325 36.3769 325 81.25V243.75C325 288.623 288.623 325 243.75 325H81.25C36.3769 325 0 288.623 0 243.75V81.25Z"
                fill="url(#paint1_radial_3483_51576)" fillOpacity="0.9"/>
            <path
                d="M0 81.25C0 36.3769 36.3769 0 81.25 0H243.75C288.623 0 325 36.3769 325 81.25V243.75C325 288.623 288.623 325 243.75 325H81.25C36.3769 325 0 288.623 0 243.75V81.25Z"
                fill="url(#paint2_radial_3483_51576)" fillOpacity="0.9"/>
            <path
                d="M0 81.25C0 36.3769 36.3769 0 81.25 0H243.75C288.623 0 325 36.3769 325 81.25V243.75C325 288.623 288.623 325 243.75 325H81.25C36.3769 325 0 288.623 0 243.75V81.25Z"
                fill="url(#paint3_radial_3483_51576)" fillOpacity="0.9"/>
            <path
                d="M0 81.25C0 36.3769 36.3769 0 81.25 0H243.75C288.623 0 325 36.3769 325 81.25V243.75C325 288.623 288.623 325 243.75 325H81.25C36.3769 325 0 288.623 0 243.75V81.25Z"
                fill="url(#paint4_radial_3483_51576)" fillOpacity="0.9"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M61.1562 71C55.5471 71 51 75.5471 51 81.1563V242.844C51 248.453 55.5471 253 61.1563 253H264.844C270.453 253 275 248.453 275 242.844V81.1562C275 75.5471 270.453 71 264.844 71H61.1562ZM197.838 155.539L132.805 126.635C127.767 124.397 122.094 128.084 122.094 133.596V191.404C122.094 196.916 127.767 200.603 132.805 198.365L197.838 169.461C203.87 166.78 203.87 158.22 197.838 155.539ZM223 86.0781C223 83.2736 225.274 81 228.078 81H248.922C251.726 81 254 83.2736 254 86.0781V96.9219C254 99.7264 251.726 102 248.922 102H228.078C225.274 102 223 99.7264 223 96.9219V86.0781ZM178.078 81C175.274 81 173 83.2736 173 86.0781V96.9219C173 99.7264 175.274 102 178.078 102H197.922C200.726 102 203 99.7264 203 96.9219V86.0781C203 83.2736 200.726 81 197.922 81H178.078ZM122 86.0781C122 83.2736 124.274 81 127.078 81H147.922C150.726 81 153 83.2736 153 86.0781V96.9219C153 99.7264 150.726 102 147.922 102H127.078C124.274 102 122 99.7264 122 96.9219V86.0781ZM76.0781 81C73.2736 81 71 83.2736 71 86.0781V96.9219C71 99.7264 73.2736 102 76.0781 102H96.9219C99.7264 102 102 99.7264 102 96.9219V86.0781C102 83.2736 99.7264 81 96.9219 81H76.0781ZM223 228.078C223 225.274 225.274 223 228.078 223H248.922C251.726 223 254 225.274 254 228.078V238.922C254 241.726 251.726 244 248.922 244H228.078C225.274 244 223 241.726 223 238.922V228.078ZM178.078 223C175.274 223 173 225.274 173 228.078V238.922C173 241.726 175.274 244 178.078 244H198.922C201.726 244 204 241.726 204 238.922V228.078C204 225.274 201.726 223 198.922 223H178.078ZM122 228.078C122 225.274 124.274 223 127.078 223H147.922C150.726 223 153 225.274 153 228.078V238.922C153 241.726 150.726 244 147.922 244H127.078C124.274 244 122 241.726 122 238.922V228.078ZM76.0781 223C73.2736 223 71 225.274 71 228.078V238.922C71 241.726 73.2736 244 76.0781 244H96.9219C99.7264 244 102 241.726 102 238.922V228.078C102 225.274 99.7264 223 96.9219 223H76.0781Z"
                  fill="url(#paint5_linear_3483_51576)"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M245 5H80C38.5786 5 5 38.5786 5 80V245C5 286.421 38.5786 320 80 320H245C286.421 320 320 286.421 320 245V80C320 38.5786 286.421 5 245 5ZM80 0C35.8172 0 0 35.8172 0 80V245C0 289.183 35.8172 325 80 325H245C289.183 325 325 289.183 325 245V80C325 35.8172 289.183 0 245 0H80Z"
                  fill="#C2C2C2"/>
            <defs>
                <radialGradient id="paint0_radial_3483_51576" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(315.467 328.002) rotate(-116.343) scale(361.321 583.749)">
                    <stop stopColor="#3815C2"/>
                    <stop offset="1" stopColor="#3815C2" stopOpacity="0.49"/>
                </radialGradient>
                <radialGradient id="paint1_radial_3483_51576" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(55.7465 270.569) rotate(-37.7276) scale(205.597 344.322)">
                    <stop offset="0.345246" stopColor="#18185F"/>
                    <stop offset="1" stopColor="#1E1E41" stopOpacity="0"/>
                </radialGradient>
                <radialGradient id="paint2_radial_3483_51576" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(316.767 5.2032) rotate(42.1729) scale(440.863 281.043)">
                    <stop stopColor="#29D798"/>
                    <stop offset="0.308137" stopColor="#29D798" stopOpacity="0.61"/>
                    <stop offset="1" stopColor="#18AB76" stopOpacity="0"/>
                </radialGradient>
                <radialGradient id="paint3_radial_3483_51576" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(37.0703 3.147) rotate(56.6834) scale(335.399 415.262)">
                    <stop stopColor="#610769"/>
                    <stop offset="0.156083" stopColor="#5A0662" stopOpacity="0.781367"/>
                    <stop offset="1" stopColor="#410247" stopOpacity="0"/>
                </radialGradient>
                <radialGradient id="paint4_radial_3483_51576" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(162.5 237.878) rotate(-86.8059) scale(141.773 143.432)">
                    <stop stopColor="#35008F" stopOpacity="0.47"/>
                    <stop offset="1" stopColor="#35008F" stopOpacity="0"/>
                </radialGradient>
                <linearGradient id="paint5_linear_3483_51576" x1="54.9742" y1="81.25" x2="54.9742" y2="226.775"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00FFDC"/>
                    <stop offset="0.509799" stopColor="#00FFDC"/>
                    <stop offset="1" stopColor="#00AAA0"/>
                </linearGradient>
            </defs>
        </svg>
    );
}