import { initialState } from '@/store/location/locationStore';
import { LocationState, LocationStore } from '@/store/location/locationStore.types';

// KEY for location in browser storage
const CINEAMO_LOCATION_BROWSER_STORAGE_KEY = 'cineamo-location-browser-storage';

// Reuse LocationState from store added lastUpdated
type LocationStateBrowserStorage = {
    lastUpdated: Date;
} & LocationStore;

type LocationBrowserStorageReturn = {
    getLocationStateFromBrowserStorage: () => LocationStateBrowserStorage;
    setLocationStateInBrowserStorage: (state: LocationState) => void;
};

export function useLocationBrowserStorage(): LocationBrowserStorageReturn {
    function getLocationStateFromBrowserStorage(): LocationStateBrowserStorage {
        const locationFromBrowserStorageString =
            typeof document !== 'undefined' ? localStorage.getItem(CINEAMO_LOCATION_BROWSER_STORAGE_KEY) : '';
        return locationFromBrowserStorageString ? JSON.parse(locationFromBrowserStorageString) : initialState;
    }
    function setLocationStateInBrowserStorage(locationState: LocationState) {
        const locationStateWithLastUpdated = {
            lastUpdated: new Date(),
            ...locationState
        };
        localStorage.setItem(CINEAMO_LOCATION_BROWSER_STORAGE_KEY, JSON.stringify(locationStateWithLastUpdated));
    }

    return {
        getLocationStateFromBrowserStorage: getLocationStateFromBrowserStorage,
        setLocationStateInBrowserStorage: setLocationStateInBrowserStorage
    };
}
