const cineamoTheme = {
    theme: {
        extend: {
            width: {
                '42': '4.2rem',
                '50': '5.0rem',
                '56': '5.6rem',
                '60': '6.0rem',
                '72': '7.2rem',
                '78': '7.8rem',
                '80': '8.0rem',
                '100': '10rem',
                '110': '11rem',
                '112': '11.2rem',
                '125': '12.5rem',
                '130': '13rem',
                '140': '14rem',
                '150': '15rem',
                '160': '16rem',
                '170': '17rem',
                '175': '17.5rem',
                '180': '18rem',
                '200': '20rem',
                '215': '21.5rem',
                '220': '22rem',
                '240': '24rem',
                '250': '25rem',
                '260': '26rem',
                '300': '30rem',
                '340': '34rem',
                '400': '40rem',
                '440': '44rem',
                '500': '50rem',
                '618': '61.8rem',
                '660': '66rem',
                '700': '70rem',
                'icon': '4rem',
                'icon-lg': '6rem',
                'list-backdrop': '10.7rem',
                'mobile-modal': '90%',
                'two-column-image': '80%'
            },
            height: {
                'icon': '4rem',
                'icon-lg': '6rem',
                '30': '3.0rem',
                '42': '4.2rem',
                '50': '5.0rem',
                '56': '5.6rem',
                '60': '6.0rem',
                '72': '7.2rem',
                '80': '8.0rem',
                '130': '13rem',
                '140': '14rem',
                '160': '16rem',
                '175': '17.5rem',
                '180': '18rem',
                '192': '19.2rem',
                '200': '20rem',
                '220': '22rem',
                '225': '22.5rem',
                '230': '23.0rem',
                '240': '24rem',
                '250': '25rem',
                '260': '26rem',
                '300': '30rem',
                '330': '33rem',
                '400': '40rem',
                '500': '50rem',
                '640': '64rem',
                '700': '70rem',
                '800': '80rem',
                '864': '80.64rem',
                '900': '90rem',
                'mobile-modal': '90%',
                'navbar': '6rem',
                'list-backdrop': '16rem',
            },
            minWidth: {
                'icon': '4rem',
                'icon-lg': '6rem',
                '32': '3.2rem',
                '46': '4.6rem',
                '48': '4.8rem',
                '72': '7.2rem',
                '82': '8.2rem',
                '100': '10rem',
                '124': '12.4rem',
                '140': '14rem',
                '148': '14.8rem',
                '215': '21.5rem',
                '300': '30rem',
                '1/3': '33.333333%',
                '2/5': '40%',
                '3/5': '60%',
                '2/3': '66.666666%',
            },
            maxWidth: {
                'icon': '4rem',
                'icon-lg': '6rem',
                '100': '10rem',
                '140': '14rem',
                '300': '30rem',
                '2/5': '40%',
                '1/2': '50%',
                '3/5': '60%',
                '2/3': '66.666666%',
            },
            minHeight: {
                'icon': '4rem',
                'icon-lg': '6rem',
                '24': '2.4rem',
                '32': '3.2rem',
                '48': '4.8rem',
                '50': '5rem',
                '64': '6.4rem',
                '72': '7.2rem',
                '80': '8rem',
                '225': '22.5rem',
                '300': '30.0rem',
                '400': '40.0rem',
                '500': '50.0rem',
                '1/2': '50%',
                '3/5': '60%',
                '2/3': '66.666666%',
            },
            maxHeight: {
                'icon': '4rem',
                'icon-lg': '6rem',
                '26': '2.6rem',
                '32': '3.2rem',
                '42': '4.2rem',
                '160': '16.0rem',
                '180': '18.0rem',
                '400': '40.0rem',
                '480': '48.0rem',
                '500': '50rem',
                'mobile-modal': '90%',
            },
            spacing: {
                'screen-inset': '0.8rem',
                'screen-inset-md': '1.6rem',
                'screen-inset-lg': '4.8rem',
                '1': '0.1rem',
                '2': '0.2rem',
                '4': '0.4rem',
                '5': '0.5rem',
                '6': '0.6rem',
                '7': '0.7rem',
                '8': '0.8rem',
                '9': '0.9rem',
                '10': '1.0rem',
                '11': '1.1rem',
                '12': '1.2rem',
                '14': '1.4rem',
                '16': '1.6rem',
                '18': '1.8rem',
                '19': '1.9rem',
                '20': '2.0rem',
                '22': '2.2rem',
                '24': '2.4rem',
                '28': '2.8rem',
                '32': '3.2rem',
                '36': '3.6rem',
                '38': '3.8rem',
                '40': '4.0rem',
                '44': '4.4rem',
                '48': '4.8rem',
                '50': '5.0rem',
                '52': '5.2rem',
                '56': '5.6rem',
                '57': '5.7rem',
                '60': '6.0rem',
                '64': '6.4rem',
                '65': '6.5rem',
                '72': '7.2rem',
                '84': '8.4rem',
                '96': '9.6rem',
                '100': '10rem',
                '108': '10.8rem',
                '120': '12rem',
                '138': '13.8rem',
                '148': '14.8rem',
                '164': '16.4rem',
                '264': '26.4rem',
                '320': '32rem',
                '1/10': '10%',
                '2/10': '20%'
            },
            lineHeight: {
                '22': '2.2rem',
                '24': '2.4rem',
                '28': '2.8rem',
                '38': '3.8rem',
                '48': '4.8rem',
            },
            borderWidth: {
                '1': '0.1rem'
            },
            borderSpacing: {
                '10': '1.0rem',
            },
            opacity: {
                '15': '0.15',
                '65': '0.65'
            },
            transitionProperty: {
                'height': 'height, max-height',
            },
            keyframes: {
                progressBar: {
                    '0%': { transform: 'scaleX(0)' },
                    '100%': { transform: 'scaleX(1)' },
                },
                pingWithPauseKeyframes: {
                    '0%, 70%': {
                        transform: 'scale(1)',
                        opacity: 1
                    },
                    '90%, 100%': {
                        transform: 'scale(2)',
                        opacity: 0
                    }
                }
            },
            animation: {
                progressBar2500: 'progressBar 2500ms',
                bounce200: 'bounce 1s infinite 200ms',
                bounce400: 'bounce 1s infinite 400ms',
                pingWithPause: 'pingWithPauseKeyframes 3s cubic-bezier(0, 0, 0.2, 1) infinite'
            },
            zIndex: {
                'navigation-dropdown': '-1',
                'navigation': '49',
                'dropdown': '50',
                'menu-button': '150',
                'modal-view': '9000',
                'loading-overlay': '9999',
            },
            boxShadow: {
                cyan: '0 0 10px 0 #00FFDC',
                'cyan-transparent': '0 0 4px 0 #00FFDC80',
                'darkBlue-20': '0 0 6px 0 #00002332',
            },
            gradientColorStopPositions: {
                20: '20%',
            },
            lineClamp: {
                2: '2',
                10: '10'
            }
        },

        letterSpacing: {
            'small': '0.02em',
            'regular': '0.035em',
            'medium': '0.05em',
            'wide': '0.15em'
        },

        fontSize: {
            'headline-72': '7.2rem',
            'headline-56': '5.6rem',
            'headline-42': '4.2rem',
            'headline-32': '3.2rem',
            'headline-24': '2.4rem',
            'headline-20': '2.0rem',
            'headline-18': '1.8rem',

            'body-20': '2.0rem',
            'body-16': '1.6rem',
            'body-14': '1.4rem',
            'body-12': '1.2rem',
            'body-11': '1.1rem',
            'body-10': '1.0rem',

            'body-bold-20': '2.0rem',
            'body-bold-16': '1.6rem',
            'body-bold-14': '1.4rem',
            'body-bold-12': '1.2rem',
            'body-bold-10': '1.0rem',

            'overline-16': '1.6rem',
            'overline-14': '1.4rem',
            'overline-12': '1.2rem',
            'overline-10': '1.0rem',

            'button-16': '1.6rem',
            'button-14': '1.4rem',
            'button-12': '1.2rem',
            'button-10': '1.0rem',

            'input-labels-12': '1.2rem',

            // '2xl': '3.2rem',
        },

        borderRadius: {
            '2': '0.2rem',
            '3': '0.3rem',
            '4': '0.4rem',
            '8': '0.8rem',
            '12': '1.2rem',
            '16': '1.6rem',
            'switch': '1.2rem',
            'full': '100%'
        },

        colors: {
            transparent: 'transparent',
            current: 'currentColor',
            cyan: {
                DEFAULT: '#00FFDC',
                light: '#3fffe4',
                middle: '#13E2C6',
                dark: '#00AAA0',
                'high-contrast': '#07757C',
                transparent: '#00FFDC80',
                'transparent-20': 'rgba(0,255,220,0.2)',
                link: '#79F9E7'
            },
            //todo use dash to separate words
            darkBlue: {
                DEFAULT: '#000023',
                light: {
                    DEFAULT: '#1A0A49',
                    50: '#1A0A4980',
                },
                lightest: '#000063',
                transparent: '#00002380',
                supertransparent: '#00002333',
                'transparent-30': '#0000234C',
                'bannerBg': '#231A39'
            },
            blue: {
                DEFAULT: '#0071F7',
                light: '#6BAFFF',
                lightest: '#C5DEFC',
                transparent: '#0071F780',
                'transparent-20': '#0071F754',
                supertransparent: '#0071F733'
            },
            purple: {
                DEFAULT: '#9B51E0',
                light: '#DD82FF',
                lightest: '#EAC4F9',
                transparent: '#9B51E080',
                'transparent-20': '#9B51E054',
                'light-transparent-20': '#DD82FF54'
            },
            green: {
                DEFAULT: '#40E587',
                light: '#79E9A8',
                lightest: '#B6F8D1',
            },
            berry: {
                DEFAULT: '#FC0073',
                light: '#FF4387',
                lightest: '#FC94C3',
            },
            black: {
                light: '#222222',
                DEFAULT: '#000000',
            },
            white: {
                DEFAULT: '#F2F2F2',
                50: '#F2F2F280',
                38: '#F2F2F260',
                20: '#F2F2F234',
                10: '#F2F2F219',
            },
            gray: {
                DEFAULT: '#CECED9',
                light: '#DBDBE2',
                middle: '#B3B3BD',
                dark: '#808091',
                transparent: '#F2F2F280',
            },
            'dark-gray': {
                DEFAULT: '#808091',
            },
            'movie-pilot': {
                orange: '#F4645A'
            },
            red: {
                DEFAULT: '#FF3933',
            },
            orange: {
                DEFAULT: '#FFA620',
                dark: '#E06019',
                light: '#FFDD5C',
                gradient: '#ED820E',
            },
            yellow: {
                DEFAULT: '#FFDE2E',
            },
            violetGrey: {
                DEFAULT: '#887D9A',
            }
        },

        scale: {
            '-100': '-1',
            '0': '0',
            '01': '0.001',
            '1': '0.01',
            '25': '.25',
            '50': '.5',
            '75': '.75',
            '90': '.9',
            '95': '.95',
            '100': '1',
            '101': '1.01',
            '105': '1.05',
            '110': '1.1',
            '125': '1.25',
            '150': '1.5',
            '200': '2',
        }
    }
}

module.exports = cineamoTheme