import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { CartItemDto } from '@/cineamo-frontend-lib/models/cart-item/CartItemDto.types';
import { CartDto } from '@/cineamo-frontend-lib/models/cart/CartDto.types';
import { ProductDto } from '@/cineamo-frontend-lib/models/product/ProductDto.types';

import { ReasonCode } from '@/cineamo-frontend-lib/constants/error-code-constants';

import { CartItemListResponse } from '@/cineamo-frontend-lib/api/ApiListResponse.types';
import {
    addCartItem,
    deleteCartItemById,
    getCartItems,
    updateCartItem
} from '@/cineamo-frontend-lib/api/cart-item/cart-item-api';
import { createCart, getCartById } from '@/cineamo-frontend-lib/api/cart/cart-api';
import { setCartSessionId } from '@/cineamo-frontend-lib/api/cineamoApiClient';
import { getConfidentialClientAccessToken } from '@/src/api/confidential-user/confidential-user-api';

import { apiResponseHandler } from '@/cineamo-frontend-lib/helper/api-response-helper/ApiResponseHelper';

import { getAllListItems } from '@/cineamo-frontend-lib/hooks/useGetAllListItems';
import useLocalStorage from '@/cineamo-frontend-lib/hooks/useLocalStorage';

import { useUserStore } from '@/store/user/userStore';

interface CartProviderState {
    dto: CartDto;
    paidCartUuid: CartDto['uuid'];
    items: CartItemDto[];
    isLoaded: boolean;
    total: number;
    currency: string;
    addProduct(product: ProductDto, quantity?: number): Promise<void>;
    updateProduct(product: ProductDto, quantity?: number): Promise<void>;
    removeProduct(productId: ProductDto['id']): Promise<void>;
}

const CartContext = createContext<CartProviderState>(undefined);

function useCart() {
    const context = useContext(CartContext);
    if (!context) throw new Error('Expected to be wrapped in a CartProvider');

    return context;
}

function CartProvider({
    children,
    cartUuidStorageKey = 'cineamo-cart-uuid',
    useStorage = useLocalStorage
}: {
    children: React.ReactNode;
    cartUuidStorageKey?: string;
    useStorage?(key: string, defaultValue: string): [string, (value: string | ((value: string) => string)) => void];
}): JSX.Element {
    const [cartUuid, setCartUuid] = useStorage(cartUuidStorageKey, '');
    const [paidCartUuid, setPaidCartUuid] = useStorage(`${cartUuidStorageKey}-paid`, '');

    const { user } = useUserStore();

    const [cart, setCart] = useState<CartDto>();
    const [cartItems, setCartItems] = useState<CartItemDto[]>([]);
    const [isLoaded, setIsLoaded] = useState(false);

    const total = useMemo(
        () => cartItems.reduce((total, item) => total + item._embedded.product.price * item.quantity, 0),
        [cartItems]
    );

    setCartSessionId(cartUuid);

    useEffect(() => {
        if (cart?.uuid) {
            setCartUuid(cart.uuid);
        }
    }, [cart?.uuid, setCartUuid]);

    const deleteCartItems = useCallback(
        async (itemsToDelete: CartItemDto[]) => {
            if (!itemsToDelete.length) {
                return;
            }

            apiResponseHandler(
                await Promise.all(itemsToDelete.map((item) => deleteCartItemById(item.id, cartUuid))),
                () => {
                    setCartItems((items) => items.filter((item) => !itemsToDelete.includes(item)));
                },
                (errorResponse) => {
                    console.error(errorResponse);
                }
            );
        },
        [cartUuid]
    );

    const initEmptyCart = useCallback(async () => {
        const options = { confidentialClientAccessToken: null };
        if (!user) {
            const confidentialClientAccessTokenResponse = await getConfidentialClientAccessToken();
            options.confidentialClientAccessToken = confidentialClientAccessTokenResponse.access_token;

            // clear old CartSessionId, otherwise it will override the Authorization header
            setCartSessionId('');
        }

        apiResponseHandler(
            await createCart({ items: [] }, options),
            (cartDto) => {
                setCart(cartDto);
            },
            (errorResponse) => {
                console.error(errorResponse);
            }
        );
    }, [user]);

    const loadCart = useCallback(async () => {
        if (!cartUuid) {
            await initEmptyCart();
            return;
        }

        await new Promise<void>((resolve) => {
            (async () => {
                apiResponseHandler(
                    await getCartById(cartUuid),
                    async (cart) => {
                        const cartItems = await getAllListItems<CartItemDto, CartItemListResponse>({
                            queryFn: (page: number) => getCartItems({ cartUuid, page }),
                            listAccessor: 'cart-items'
                        });
                        setCart(cart);
                        setCartItems(cartItems.filter((item) => !!item.productId));
                        deleteCartItems(cartItems.filter((item) => !item.productId)).then();
                        resolve();
                    },
                    async (error) => {
                        if (error.response?.data?.reason_code === ReasonCode.CART_IS_PAID) {
                            setPaidCartUuid(cartUuid);
                        }
                        await initEmptyCart();
                        resolve();
                    }
                );
            })();
        });
    }, [cartUuid, deleteCartItems, initEmptyCart]);

    // load Cart by UUID, otherwise create a new one
    useEffect(() => {
        loadCart().then(() => {
            setIsLoaded(true);
        });
    }, [loadCart]);

    const findCartItemByProductId = useCallback(
        (productId: ProductDto['id']) => cartItems.find((item) => item.productId === productId),
        [cartItems]
    );

    const addProduct = useCallback<CartProviderState['addProduct']>(
        async (product, quantity = 1) => {
            const cartItem = findCartItemByProductId(product.id);
            if (cartItem) return; // or increase quantity (not for SeatLocks)?

            apiResponseHandler(
                await addCartItem(
                    {
                        product: product.id,
                        quantity
                    },
                    cartUuid
                ),
                (cartItemDto) => {
                    setCartItems((cartItems) => [...cartItems, cartItemDto]);
                },
                (errorResponse) => {
                    console.error(errorResponse);
                }
            );
        },
        [cartUuid, findCartItemByProductId]
    );

    const updateProduct = useCallback<CartProviderState['updateProduct']>(
        async (product, quantity = undefined) => {
            const cartItem = findCartItemByProductId(product.id);
            if (!cartItem) return;

            if (quantity && cartItem.quantity !== quantity) {
                apiResponseHandler(
                    await updateCartItem({ quantity }, cartUuid),
                    () => {
                        cartItem.quantity = quantity;
                    },
                    (errorResponse) => {
                        console.error(errorResponse);
                    }
                );
            }

            cartItem._embedded.product = product;

            // update state
            setCartItems((cartItems) => [...cartItems]);
        },
        [cartUuid, findCartItemByProductId]
    );

    const removeProduct = useCallback<CartProviderState['removeProduct']>(
        async (productId) => {
            const cartItem = findCartItemByProductId(productId);
            if (!cartItem) return; // or decrease quantity?

            await deleteCartItems([cartItem]);
        },
        [deleteCartItems, findCartItemByProductId]
    );

    return (
        <CartContext.Provider
            value={{
                dto: cart,
                paidCartUuid,
                items: cartItems,
                isLoaded,
                total,
                currency: 'EUR', // TODO: remove hardcoded currency (and locale)
                addProduct,
                updateProduct,
                removeProduct
            }}>
            {children}
        </CartContext.Provider>
    );
}

export { useCart, CartProvider };
