import { useTranslation } from 'next-i18next';

import HorizontalView from '../horizontal-view/HorizontalView';
import LinkWithStyle from '../link/LinkWithStyle';
import VerticalView from '../vertical-view/VerticalView';

const data = [
    {
        title: 'navigation-footer.cineamo',
        titleHref: '#',
        items: [
            {
                title: 'navigation-footer.cinema-visitor',
                href: '/cinema-visitor/'
            },
            {
                title: 'navigation-footer.cinema-operators',
                href: '/cinema-operator'
            }
        ]
    },
    {
        title: 'navigation-footer.inspiration',
        items: [
            {
                title: 'navigation-footer.book-cinema',
                href: '/cinema-visitor/rent-a-cinema'
            },
            {
                title: 'navigation-footer.company-event',
                href: '/cinema-visitor/business-event'
            },
            {
                title: 'navigation-footer.school-performances',
                href: '/cinema-visitor/schools-and-teacher'
            },
            {
                title: 'navigation-footer.birthday-in-cinema',
                href: '/cinema-visitor/birthday-event'
            },
            {
                title: 'navigation-footer.gaming-in-cinema',
                href: '/cinema-visitor/gaming-event'
            }
        ]
    },
    {
        title: 'navigation-footer.about-cineamo',
        items: [
            {
                title: 'navigation-footer.vision',
                href: '/about-cineamo'
            },
            {
                title: 'navigation-footer.team',
                href: '/team'
            },
            {
                title: 'navigation-footer.careers',
                href: '/careers'
            },
            {
                title: 'navigation-footer.press',
                href: '/press'
            },
            {
                title: 'navigation-footer.newsroom',
                href: 'https://blog.cineamo.com/'
            }
        ]
    },
    {
        title: 'navigation-footer.more',
        items: [
            {
                title: 'navigation-footer.faq',
                href: '/faq'
            },
            {
                title: 'navigation-footer.prices-services',
                href: '/cinema-operator/price-and-performance'
            },
            {
                title: 'navigation-footer.marketing-assistant',
                href: '/cinema-operator/marketing-assistant'
            }
        ]
    }
];

export default function PageFooter(): JSX.Element {
    const { t } = useTranslation('common');

    return (
        <footer className="w-full flex-none text-body-12 text-gray-light">
            <VerticalView className="w-full px-48 py-24">
                <HorizontalView className="grid grid-cols-2 lg:grid-cols-4 gap-x-48 gap-y-24">
                    {data.map(({ title, titleHref, items }, idx) => (
                        <VerticalView
                            className=""
                            title={t(title)}
                            titelHref={titleHref}
                            key={idx}>
                            <ul className="mb-20">
                                {items.map(({ title, href }, idx) => (
                                    <li
                                        className="mt-20"
                                        key={idx}>
                                        <LinkWithStyle
                                            className="text-gray-light no-underline"
                                            href={href}>
                                            {t(title)}
                                        </LinkWithStyle>
                                    </li>
                                ))}
                            </ul>
                        </VerticalView>
                    ))}
                </HorizontalView>
            </VerticalView>
        </footer>
    );
}
