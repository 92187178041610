import { useState } from 'react';

type Value = string | ((value: string) => string);

export default function useLocalStorage(key: string, defaultValue: string): [string, (value: Value) => void] {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = window.localStorage.getItem(key);

            return item ? item : defaultValue;
        } catch (error) {
            return defaultValue;
        }
    });

    const setValue = (value: Value) => {
        try {
            const valueToStore = value instanceof Function ? value(storedValue) : value;

            setStoredValue(valueToStore);

            window.localStorage.setItem(key, valueToStore);
        } catch (error) {
            console.error(error);
        }
    };

    return [storedValue, setValue];
}
