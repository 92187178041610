import tailwindConfig from 'tailwind.config';
import resolveConfig from 'tailwindcss/resolveConfig';

import { Config } from 'tailwindcss/types/config';

export const tailwindColors = resolveConfig(tailwindConfig as unknown as Config).theme.colors as Record<
    string,
    Record<string, string>
>;

export const tailwindBreakpoints = resolveConfig(tailwindConfig as unknown as Config).theme.screens;
