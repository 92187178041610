import React, { HTMLAttributeAnchorTarget } from 'react';

import LinkWithStyle from '../link/LinkWithStyle';
import Badge, { BadgeStyle } from './Badge';

export type TrailingBadgeLinkProps = {
    children?: React.ReactNode;
    className?: string;
    href?: string;
    as?: string;
    onClick?: (event) => void;
    target?: HTMLAttributeAnchorTarget;
    titleOnHover?: string;
    trailingBadgeText?: string;
};

export default function TrailingBadgeLink(props: TrailingBadgeLinkProps): JSX.Element {
    const { children, className, href, onClick, target, titleOnHover, trailingBadgeText } = props;

    const childrenAndBadge = (
        <div className="flex flex-row w-full items-center space-x-8">
            {children && children}
            {trailingBadgeText && (
                <Badge
                    name={trailingBadgeText}
                    badgeStyle={BadgeStyle.large}
                    bgColor="bg-cyan-middle bg-opacity-100"
                    textColor="text-darkBlue"
                />
            )}
        </div>
    );

    return (
        <LinkWithStyle
            className={className}
            href={href}
            onClick={onClick}
            target={target}
            titleOnHover={titleOnHover}>
            {childrenAndBadge}
        </LinkWithStyle>
    );
}
