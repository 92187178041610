import {CustomSVGProps} from '../../types/CustomSVGProps.types';


export function IconLogout16(props: CustomSVGProps) {
    return(
    <svg  {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M16 8L11.2928 12.7071L9.87863 11.2929L13.1716 8L9.87865 4.70711L11.2929 3.29289L16 8Z"
              fill={props.fill || "currentColor"}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M4 7L14 7L14 9L4 9L4 7Z"
              fill={props.fill || "currentColor"}/>
        <path d="M6 1H2C1.44772 1 1 1.44772 1 2V14C1 14.5523 1.44772 15 2 15H6"
              stroke={props.fill || "currentColor"} strokeWidth="2"/>
    </svg>
)
}
export function IconLogout24(props: CustomSVGProps) {
    return(
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.1716 12L15.3934 17.7782L16.8076 19.1924L24 12L16.8076 4.80761L15.3934 6.22182L21.1716 12Z"
                fill={props.fill || "currentColor"}/>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 11L22 11L22 13L4 13L4 11Z"
                fill={props.fill || "currentColor"}/>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 3C0 1.34314 1.34315 0 3 0H9V2H3C2.44771 2 2 2.44772 2 3V21C2 21.5523 2.44772 22 3 22H9V24H3C1.34315 24 0 22.6569 0 21V3Z"
                fill={props.fill || "currentColor"}/>
        </svg>
)
}
