export const FUNCTIONAL_COOKIES_PROPERTY_NAME = '__functional_cookies_opt_in';
export const ANALYSIS_COOKIES_PROPERTY_NAME = '__analysis_cookies_opt_in';
export const ESSENTIAL_COOKIES_PROPERTY_NAME = '__essential_cookies_initial_opt_in';

export type CookieStateType = { string: string; value: boolean };
export type CookieStateListType = {
    essentialCookieState: CookieStateType;
    functionalCookieState: CookieStateType;
    analysisCookieState: CookieStateType;
};
