import { useEffect, useState } from 'react';

import { useIsMobile } from '@/src/helper/device/screen-type';

export type MobileOrDefaultRendererProps = {
    mobileContent?: JSX.Element;
    defaultContent?: JSX.Element;
    breakpoint?: 'lg' | 'md';
};

function MobileOrDefaultRenderer(props: MobileOrDefaultRendererProps): JSX.Element | null {
    const isMobile = useIsMobile(props.breakpoint);

    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        setMounted(true);
    }, []);

    if (!mounted || (!props.defaultContent && !props.mobileContent)) {
        return null;
    }

    if (isMobile) {
        return props.mobileContent || null;
    } else {
        return props.defaultContent || null;
    }
}

export default MobileOrDefaultRenderer;
