import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import { ReactNode, createContext, useContext, useState } from 'react';

const LoadingAnimation = dynamic(() => import('@/cineamo-frontend-lib/components/ui/animation/LoadingAnimation'));

const LoadingAnimationContext = createContext({
    isLoading: false,
    showLoadingAnimation: function () {
        // placeholder
    },
    hideLoadingAnimation: function () {
        // placeholder
    }
});

export type LoadingAnimationContextProviderProps = {
    children?: ReactNode;
};

export function LoadingAnimationContextProvider(props: LoadingAnimationContextProviderProps): JSX.Element {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);

    function showLoadingAnimation() {
        setLoading(true);
    }

    function hideLoadingAnimation() {
        setLoading(false);
    }

    const context = {
        isLoading: loading,
        showLoadingAnimation: showLoadingAnimation,
        hideLoadingAnimation: hideLoadingAnimation
    };

    return (
        <LoadingAnimationContext.Provider value={context}>
            {loading && <LoadingAnimation loadingText={t('common:loading')} />}
            {props.children}
        </LoadingAnimationContext.Provider>
    );
}

export function isLoading(): boolean {
    const loadingAnimationContext = useContext(LoadingAnimationContext);
    return loadingAnimationContext.isLoading;
}

export function useLoadingAnimation(): {
    isLoading?: boolean;
    showLoadingAnimation?: () => void;
    hideLoadingAnimation?: () => void;
} {
    const loadingAnimation = useContext(LoadingAnimationContext);
    return {
        isLoading: loadingAnimation.isLoading,
        showLoadingAnimation: loadingAnimation.showLoadingAnimation,
        hideLoadingAnimation: loadingAnimation.hideLoadingAnimation
    };
}

export default LoadingAnimationContext;
