export const DOMAIN_URL = process.env.NEXT_PUBLIC_ENV_LEGACY_HOST_NAME?.replace('/de', '') || '';
export const AUTH_CLIENT_URL = process.env.NEXT_PUBLIC_ENV_AUTH_CLIENT_URL;
export const PAYMENT_CLIENT_URL = process.env.NEXT_PUBLIC_ENV_PAYMENT_HOST_NAME;

export const SIGNOUT_URL = (locale?: string): string => {
    return !AUTH_CLIENT_URL?.startsWith('/') // Check if AUTH_URL is equal to e.g. '/login' or another domain like 'staging.cineamo-debug.org'
        ? AUTH_CLIENT_URL + (locale ? `/${locale}` : '') + '/logout'
        : (locale ? `/${locale}` : '') + '/';
};

export const SIGNIN_URL = (locale?: string, urlParams?: string): string => {
    let url = !AUTH_CLIENT_URL?.startsWith('/') // Check if AUTH_URL is equal to e.g. '/login' or another domain like 'staging.cineamo-debug.org'
        ? AUTH_CLIENT_URL + (locale ? `/${locale}` : '')
        : AUTH_CLIENT_URL + urlParams;
    if (!!urlParams && urlParams.length > 0) {
        url += urlParams;
    }
    return url;
};

export const ADMIN_URL = process.env.NEXT_PUBLIC_ENV_ADMIN_HOST_NAME;
export const CINEMA_URL = process.env.NEXT_PUBLIC_ENV_CINEMA_HOST_NAME;
export const DISTRIBUTOR_URL = process.env.NEXT_PUBLIC_ENV_DISTRIBUTOR_HOST_NAME;

export const AUTH_URL = process.env.NEXT_PUBLIC_ENV_AUTH_URL;

export const ANDROID_APP_ID = 'org.mpp.cineamo';
export const APPLE_APP_ID = '1447730281';
export const PLAY_STORE_URL = `https://play.google.com/store/apps/details?id=${ANDROID_APP_ID}&referrer=utm_source%3Dcineamo.com%26utm_medium%3Dlandingpage%26utm_campaign%3Dwebsite-cta`;
export const APP_STORE_URL = `https://apps.apple.com/app/apple-store/id${APPLE_APP_ID}?pt=120760330&ct=Cineamo-Landing-Page&mt=8`;
