import React, { ReactNode, useState } from 'react';

import Navigation from '@/components/navigation/Navigation';
import SelectLoginTargetModal from '@/components/ui/modal/SelectLoginTargetModal';
import Footer from '@/components/ui/page/Footer';

import { useUserStore } from '@/store/user/userStore';
import { useWebappStore } from '@/store/webapp/webappStore';

import PageContent from '../ui/page/PageContent';

export type RootLayoutProps = {
    children?: ReactNode;
};

function RootLayout(props: RootLayoutProps): JSX.Element {
    const { children } = props;

    const [selectLoginTargetModalIsOpen, setSelectLoginTargetModalIsOpen] = useState(false);

    const { showNavigation } = useWebappStore();

    const { user } = useUserStore();

    // useEffect(() => {
    //     if (!meUser) return;
    //
    //     if (router.asPath.includes('?target=')) return;
    //
    //     if (meUser?.roles?.length > 0) {
    //         setSelectLoginTargetModalIsOpen(true);
    //     } else if (meUser?.roles?.length <= 0 && !isWebapp) {
    //         showToastNew({
    //             toastType: toast.info,
    //             messageTitle: stringFormat(t('welcome-back-user'), meUser.username),
    //             messageBody: <div className="flex items-center">
    //                 <IconWebApp />
    //                 <div className="flex-1 ml-8 w-full">
    //                     <LinkWithStyle href="/name-of-cinema/events">
    //                         <Button
    //                             leadingIcon={<IconRightArrow24/>}
    //                             style={ButtonStyle.default}
    //                             className="uppercase w-full"
    //                         >{t('go-to-webapp')}
    //                         </Button>
    //                     </LinkWithStyle>
    //                 </div>
    //             </div>,
    //             backgroundColor: '#fff',
    //             textColor: '#000023'
    //         });
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [meUser]);

    // useEffect(() => {
    //     if (meUser) {
    //         if (router.locale !== meUser.preferredLanguage) {
    //             setPreferredLanguage(meUser.preferredLanguage).then();
    //             router.replace({
    //                 pathname: router.pathname,
    //                 query: router.query
    //             }, undefined, {
    //                 locale: meUser.preferredLanguage
    //             }).then();
    //         }
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [meUser]);

    // InitializeBackground();

    return (
        <div className="flex flex-col h-[100dvh] font-montserrat-regular tracking-regular">
            <SelectLoginTargetModal
                modalIsOpen={selectLoginTargetModalIsOpen}
                setModalIsOpen={setSelectLoginTargetModalIsOpen}
                user={user}
            />

            {showNavigation && <Navigation />}

            <PageContent className="relative flex flex-col pb-96 lg:pb-0 z-10">
                <div className="flex-auto flex-shrink">{children}</div>

                <Footer />
            </PageContent>
        </div>
    );
}

export default RootLayout;
