import { TFunction } from 'next-i18next';

import { MessageDto } from '../models/message/MessageDto.types';
import { ShowingDto } from '../models/showing/ShowingDto.types';

import { updateMessageById } from '../api/message/message-api';

import { apiResponseHandler } from './api-response-helper/ApiResponseHelper';

export function getNotificationTypeFromPath(path: string): string {
    const pathSegmentsArray = path.split('/');
    return pathSegmentsArray[1];
}

export function getShowingIdFromPath(path: string): string {
    const pathSegmentsArray = path.split('/');
    return pathSegmentsArray[2];
}

export function getNotificationTypeSpecificLink(notification: MessageDto, showingResponse: ShowingDto): string {
    let webappLink;

    const { path } = notification;

    const notificationType = getNotificationTypeFromPath(path);

    const pathSegmentsArray = path.split('/');
    const targetId = pathSegmentsArray[2];

    const notificationContentId: number = showingResponse?.contentId;

    switch (notificationType) {
        case 'showing':
            webappLink = `/program/${notificationContentId}?showingId=${targetId}`;
            if (notification.eventName.includes('MarketingAssistant')) {
                webappLink += '&isMarketingAssistant=true';
            }
            break;
        case 'request':
            webappLink = `/program/request/${targetId}`;
            break;
        case 'offer':
            webappLink = `/deals/${targetId}`;
            break;
    }

    return webappLink;
}

export function getShareStringFromPath(path: string, t: TFunction): string {
    const notificationType = getNotificationTypeFromPath(path);
    let shareString;

    switch (notificationType) {
        case 'request':
            shareString = t('common:notifications.share-your-event');
            break;

        case 'showing':
            shareString = t('common:notifications.share-your-showing');
            break;

        case 'thread':
            shareString = t('common:notifications.share-this-thread');
            break;

        case 'offer':
            shareString = t('common:notifications.share-this-deal');
            break;
    }
    return shareString ? `${shareString}!` : null;
}

export async function handleNotificationReadClick(
    notification: MessageDto,
    setIsRead: () => void,
    onReadClick: (notification: MessageDto) => void
) {
    const clickUpdateValues = { isRead: true, isClicked: true };
    setIsRead();
    const res = await updateMessageById(notification.id, clickUpdateValues);
    apiResponseHandler(
        res,
        (successResponse) => onReadClick(successResponse),
        (errorResponse) => console.error(errorResponse)
    );
}
