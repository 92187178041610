import classnames from 'classnames';
import { Link, translateUrl } from 'next-translate-routes';
import { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { Children } from 'react';

export type ActiveLinkProps = {
    children?;
    activeClassName?: string;
    inactiveClassName?: string;
    className?: string;
    href?: string;
    as?: string;
    isNot?: string[];
} & LinkProps;

const ActiveLink = ({ children, activeClassName, inactiveClassName, ...props }: ActiveLinkProps): JSX.Element => {
    const { asPath: asPathFromRouter, locale, locales } = useRouter();

    const asPathWithoutQueryParams = asPathFromRouter?.split('?')?.[0] || asPathFromRouter;

    function translatedLink(href?: string): string {
        if (!href) return null;
        let translatedUrlWithoutLang = translateUrl(href.split('?')?.[0] || href, locale);
        const startsWithLeadingSlash = translatedUrlWithoutLang.startsWith('/');
        const langPos = startsWithLeadingSlash ? 1 : 0;
        const urlParts = translatedUrlWithoutLang.split('/').filter((part) => part);
        if (urlParts.length > langPos && locales.indexOf(urlParts[langPos]) !== -1) {
            urlParts.splice(langPos, 1);
            translatedUrlWithoutLang = urlParts.join('/');
        }
        return translatedUrlWithoutLang;
    }

    const translatedHref = translatedLink(props.href);
    const translatedIsNot: string[] = props.isNot?.map((isNot) => translatedLink(isNot)) || [];
    const translatedAsValueWithoutLang = '/' + translatedLink(props.as).split('/')[2];
    const child = Children.only(children);
    const childClassName = child.props.className || '';

    // pages/index.js will be matched via props.href
    // pages/about-cineamo.js will be matched via props.href
    // pages/[slug].js will be matched via props.as

    const indexOfFirstSlash = asPathWithoutQueryParams.indexOf('/', 0);
    const indexOfSecondSlash = asPathWithoutQueryParams.indexOf('/', indexOfFirstSlash + 1);

    let indexOfThirdSlash = undefined;

    if (indexOfSecondSlash > 1) {
        indexOfThirdSlash = asPathWithoutQueryParams.indexOf('/', indexOfSecondSlash + 1);
    }

    const subRoute = asPathWithoutQueryParams.substring(0, indexOfThirdSlash);

    const asPathWithoutQuery = asPathWithoutQueryParams.replace(/\?(.*)/g, '');

    const isActive =
        (asPathWithoutQuery === translatedHref ||
            subRoute === translatedHref ||
            asPathWithoutQueryParams === props.as ||
            // (props.as !== '/' && asPathWithoutQueryParams.startsWith(props.as))) &&
            (props.as !== '/' && asPathWithoutQueryParams.startsWith(translatedAsValueWithoutLang))) &&
        !translatedIsNot.some((isNot) => isNot === asPathWithoutQueryParams); // <--- TODO: this is a temporary solution for `/movies/[movieId]`

    const className = classnames(childClassName, {
        [activeClassName]: isActive,
        [inactiveClassName]: !isActive
    });

    return (
        <Link
            {...props}
            href={translatedHref}>
            <a className="">
                {React.cloneElement(child, {
                    className
                })}
            </a>
        </Link>
    );
};

ActiveLink.propTypes = {
    activeClassName: PropTypes.string.isRequired
};

export default ActiveLink;
