import { Tab } from '@headlessui/react';
import { useTranslation } from 'next-i18next';
import React from 'react';

import TabListLine from '@/components/misc/TabListLine';
import ActiveLink from '@/components/navigation/ActiveLink';

import { IconFilmSerie24 } from '@/cineamo-frontend-lib/icons/categories/ic-filmSerie';
import { IconDeals24 } from '@/cineamo-frontend-lib/icons/ic-deals';
import { IconHome24 } from '@/cineamo-frontend-lib/icons/ic-home';
import { IconProfile24 } from '@/cineamo-frontend-lib/icons/ic-profile';
import { IconProgram24 } from '@/cineamo-frontend-lib/icons/ic-program';

function NavigationWebappDefault(): JSX.Element {
    const { t } = useTranslation('common');

    return (
        <div className="flex flex-row justify-center">
            {/* This div is necessary to render the navigation 'centered' */}
            <div>
                <Tab.Group>
                    <Tab.List className="w-full flex flex-row">
                        <WebappDefaultNavigationItem
                            leadingIcon={<IconHome24 className="flex-none" />}
                            title={t('navigation.home')}
                            href={'/'}
                            as={'/'}
                            isNot={['/my-profile', '/search', '/program', '/deals', '/movies']}
                        />
                        {/*<WebappDefaultNavigationItem*/}
                        {/*    leadingIcon={<IconSearch24 />}*/}
                        {/*    title={t('navigation.search')}*/}
                        {/*    href={`/search`}*/}
                        {/*    isNot={[`/search`]}*/}
                        {/*/>*/}
                        <WebappDefaultNavigationItem
                            leadingIcon={<IconFilmSerie24 />}
                            title={t('navigation.movies')}
                            href={'/movies'}
                            as={'/movies'}
                            isNot={['/', '/my-profile', '/search', '/program', '/deals']}
                        />
                        <WebappDefaultNavigationItem
                            leadingIcon={<IconProgram24 />}
                            title={t('navigation.program')}
                            href={'/program'}
                            as={'/program'}
                            isNot={['/my-profile', '/search', '/', '/deals', '/movies']}
                        />
                        <WebappDefaultNavigationItem
                            leadingIcon={<IconDeals24 className="flex-none" />}
                            title={t('navigation.deals')}
                            href={'/deals'}
                            as={'/deals'}
                            isNot={['/my-profile', '/search', '/', '/program', '/movies']}
                        />

                        <WebappDefaultNavigationItem
                            leadingIcon={<IconProfile24 />}
                            title={t('navigation.profile')}
                            href={'/my-profile'}
                            as={'/my-profile'}
                            isNot={['/program', '/search', '/', '/deals']}
                        />
                    </Tab.List>
                </Tab.Group>

                <TabListLine />
            </div>
        </div>
    );
}

export default NavigationWebappDefault;

export type WebappDefaultNavigationItemProps = {
    title: string;
    href: string;
    as?: string;
    isNot?: string[];
    leadingIcon?: JSX.Element;
};

function WebappDefaultNavigationItem(props: WebappDefaultNavigationItemProps) {
    return (
        <Tab className="flex flex-row justify-center cursor-pointer min-w-124 xl:min-w-148 group">
            <ActiveLink
                activeClassName="border-b-2 border-cyan text-cyan font-montserrat-semibold px-16 xl:px-24"
                inactiveClassName="opacity-70 hover:opacity-100 active:opacity-100 transition-all duration-150"
                href={props.href}
                as={props.as}
                isNot={props.isNot}>
                <div className="relative flex flex-row pt-24 pb-16 space-x-12 items-center">
                    <div className="flex-none">{props.leadingIcon}</div>
                    <span className="text-body-bold-14">{props.title}</span>
                </div>
            </ActiveLink>
        </Tab>
    );
}
