import { TFunction, useTranslation } from 'next-i18next';
import Image from 'next/image';
import { useEffect, useState } from 'react';

import { CinemaDto } from '../../../models/cinema/CinemaDto.types';
import { MessageDto } from '../../../models/message/MessageDto.types';

import { loadAsset } from '../../../helper/asset-helper';
import { handleNotificationReadClick } from '../../../helper/message-helper';
import { timeAgo } from '../../../helper/time-helper';

import LinkWithStyle from '../link/LinkWithStyle';

const notificationImage = loadAsset('/images/illustrations/im-notificationBell.png');

export type NavNotificationsListItemProps = {
    notification: MessageDto;
    selectedNotification: MessageDto;
    onReadClick: (notification: MessageDto) => void;
    t: TFunction;
};

export default function NavNotificationsListItem(props: NavNotificationsListItemProps): JSX.Element {
    const { notification, selectedNotification, onReadClick } = props;
    const notificationId = notification.id;

    const t = props.t || useTranslation('common').t;

    const [hasImage, setHasImage] = useState<boolean>(false);
    const [isRead, setIsRead] = useState<boolean>(notification?.isRead);
    const [notificationImageError, setNotificationImageError] = useState<boolean>(false);

    const baseMessage = notification?.baseMessage;
    const user = notification?._embedded?.user;

    const image: string | StaticImageData = baseMessage?.image ? baseMessage.image : user?.profileImageUrl;

    const notificationCinema: CinemaDto = notification?._embedded?.cinema;

    useEffect(() => {
        if (notification?.baseMessage?.image) {
            setHasImage(true);
        }

        if (notification?.isRead === true) {
            setIsRead(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedNotification]);

    useEffect(() => {
        setIsRead(notification?.isRead);
    }, [notification]);

    // When 'isRead' changes, call the updated message object from the backend
    useEffect(() => {
        return;
    }, [isRead]);

    function styleSelectedMessage() {
        if (selectedNotification) {
            return selectedNotification.id === notification.id ? 'bg-darkBlue bg-opacity-5' : '';
        }
    }

    return (
        <LinkWithStyle
            href={{
                pathname: '/notifications',
                query: {
                    messageId: notificationId
                }
            }}>
            <div
                className={`w-full ${isRead ? '' : 'bg-cyan bg-opacity-20'} ${styleSelectedMessage()}  flex p-16
            transition duration-200
            cursor-pointer
            `}
                onClick={() => handleNotificationReadClick(notification, () => setIsRead(true), onReadClick)}>
                <div className="flex flex-1 w-full">
                    {hasImage && (
                        <div className="mr-20 w-auto h-full self-center justify-self-end">
                            {/* has to be changed... just for styling purpose */}
                            <div className="h-48 w-48 relative">
                                <Image
                                    layout="fill"
                                    alt="notification image"
                                    className="rounded-full object-cover bg-darkBlue bg-opacity-50"
                                    src={
                                        notificationCinema?.profileImageUrl
                                            ? notificationCinema.profileImageUrl
                                            : !notificationImageError && notification?.baseMessage?.image
                                            ? notification.baseMessage.image
                                            : notificationImage
                                    }
                                    blurDataURL={image || notificationImage}
                                    onError={() => setNotificationImageError(true)}
                                />
                            </div>
                        </div>
                    )}

                    {notification?.baseMessage && (
                        <div className="w-full flex-1 overflow-hidden space-y-4">
                            <div className="w-full flex flex-row whitespace-nowrap space-x-8">
                                <div className="flex-auto w-0 text-body-bold-12 overflow-ellipsis overflow-hidden">
                                    {notification?.baseMessage.title}
                                </div>
                                <div className="flex-shrink-0 ml-auto mr-1 text-overline-10 font-montserrat-semibold self-center justify-self-end text-gray uppercase tracking-wider">
                                    {timeAgo(new Date(notification?.baseMessage.createdDatetime), null, { t: t })}
                                </div>
                            </div>

                            <div className="w-full text-body-12 mt-1 overflow-hidden overflow-ellipsis whitespace-nowrap">
                                {notification?.baseMessage.text}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </LinkWithStyle>
    );
}
