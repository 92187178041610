import React, { useRef } from 'react';
import ReactDOM from 'react-dom';

import Button from '@/cineamo-frontend-lib/components/ui/buttons/Button';
import LinkWithStyle from '@/components/ui/link/LinkWithStyle';

import { StyleFadeDirection, styleFaded } from '@/cineamo-frontend-lib/helper/styleHelper';

import { useWebappStore } from '@/store/webapp/webappStore';

export function BottomButtonWebappMobile() {
    const { showBottomButton, bottomButtonProps } = useWebappStore();

    const buttonElement = (
        <div className="relative flex flex-col">
            <div
                className="absolute top-0 left-0 w-full h-full z-0 bg-darkBlue bg-opacity-70 backdrop-filter backdrop-blur-xl"
                style={styleFaded(StyleFadeDirection.Top, 60)}></div>
            <LinkWithStyle
                onClick={bottomButtonProps?.onClick}
                href={bottomButtonProps?.href}
                className="relative mx-36 mb-24">
                <Button
                    className="h-48 w-full"
                    {...bottomButtonProps?.buttonProps}>
                    {bottomButtonProps?.title}
                </Button>
            </LinkWithStyle>
        </div>
    );

    const bottomButtonContainer = useRef(typeof document !== 'undefined' && document.getElementById('bottom-button'));

    if (bottomButtonContainer.current && showBottomButton) {
        return ReactDOM.createPortal(buttonElement, bottomButtonContainer.current);
    } else return null;
}
