import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React from 'react';

import MenuButtonWithItems from '@/cineamo-frontend-lib/components/ui/buttons/MenuButtonWithItems';

import logoutUser from '@/src/helper/LogOutHelper';

import { useNotificationsStore } from '@/cineamo-frontend-lib/store/notifications/notificationsStore';
import { useUserStore } from '@/store/user/userStore';

import { IconFilmSerie16 } from '@/cineamo-frontend-lib/icons/categories/ic-filmSerie';
import { IconNewRequest24 } from '@/cineamo-frontend-lib/icons/ic-newRequest';
import { IconRingingBell16 } from '@/cineamo-frontend-lib/icons/ic-ringingBell';
import { IconSeat16 } from '@/cineamo-frontend-lib/icons/ic-seat';
import { IconLogout24 } from '@/cineamo-frontend-lib/icons/menu/ic-logout';
import { IconSettingsCog16 } from '@/cineamo-frontend-lib/icons/menu/ic-settings-cog';

type NavigationDefaultMenuButtonItemsProps = {
    onNotificationsClick: () => void;
};

export default function NavigationDefaultMenuButtonItems(props: NavigationDefaultMenuButtonItemsProps) {
    const { t } = useTranslation('common');

    const locale = useRouter().locale;

    const { onNotificationsClick } = props;

    const { user } = useUserStore();
    const { unreadNotificationsCount: unreadMessagesCount } = useNotificationsStore();

    return (
        <>
            <MenuButtonWithItems.MenuItem
                leadingIcon={<IconRingingBell16 />}
                title={t('navigation.notifications')}
                onClick={onNotificationsClick}
                trailingBadgeText={unreadMessagesCount > 0 && unreadMessagesCount.toString()}
            />
            <MenuButtonWithItems.MenuItem
                leadingIcon={<IconSeat16 />}
                title={t('navigation.my-cinemas')}
                href="/my-profile/cinemas"
            />
            <MenuButtonWithItems.MenuItem
                leadingIcon={<IconNewRequest24 className="h-16 w-16" />}
                title={t('navigation.my-requests')}
                href="/my-profile/event-requests"
            />
            <MenuButtonWithItems.MenuItem
                leadingIcon={<IconFilmSerie16 />}
                title={t('navigation.movie-list')}
                href="/my-profile/user-movie-lists"
            />

            <hr className="w-auto mx-10 text-gray opacity-50" />

            {/*//TODO: reinstate invite friends*/}
            {/*<MenuButtonWithItems.MenuItem*/}
            {/*    leadingIcon={<IconPeople16 />}*/}
            {/*    title={t('navigation.invite-friends')}*/}
            {/*    href={null}*/}
            {/*/>*/}

            <MenuButtonWithItems.MenuItem
                leadingIcon={<IconSettingsCog16 />}
                title={t('navigation.account-settings')}
                href={'/settings'}
            />

            <hr className="w-auto mx-10 text-gray opacity-50" />

            <MenuButtonWithItems.MenuItem
                leadingIcon={<IconLogout24 className="h-16 w-16" />}
                title={`${t('log-out')} ${user.username}`}
                onClick={() => logoutUser(locale)}
            />
            <div className="h-0.5 w-auto mx-10 block bg-gray bg-opacity-50" />
        </>
    );
}
