import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { Locale } from '../constants/locale-constants';

import { getLanguageCodeFromLocale, getLocaleFromLanguage } from '../helper/locale-helper';
import { getPreferredLocale } from '../helper/preferredLanguageHelper';

type UseBrowserLocaleReturn = {
    locale: Locale;
    localeAlt: string;
};

export default function useLocale(): UseBrowserLocaleReturn {
    const router = useRouter();
    const [browserLocale, setBrowserLocale] = useState<string>();
    const locale = browserLocale?.startsWith(router.locale?.substring(0, 2))
        ? getLocaleFromLanguage(router.locale)
        : getLocaleFromLanguage(browserLocale);

    useEffect(() => {
        getPreferredLocale().then((preferredLocale) => {
            const localeToUse =
                preferredLocale ||
                getLocaleFromLanguage(typeof navigator !== 'undefined' ? navigator.language : undefined);
            setBrowserLocale(localeToUse);

            // Only do a router.replace if the localeToUse != the router.locale
            if (router.locale === getLanguageCodeFromLocale(localeToUse)) {
                return;
            }

            router
                .replace(
                    {
                        pathname: router.pathname,
                        query: router.query
                    },
                    undefined,
                    { locale: getLanguageCodeFromLocale(localeToUse) }
                )
                .then();
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        locale: browserLocale ? locale : undefined,
        localeAlt: browserLocale
    };
}
