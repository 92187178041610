import classnames from 'classnames';
import { ReactNode } from 'react';

export type PageContentProps = {
    children?: ReactNode;
    className?: string;
};

function PageContent(props: PageContentProps): JSX.Element {
    return <div className={classnames(props.className, 'h-0 flex-auto m-0 text-gray-light')}>{props.children}</div>;
}

export default PageContent;
