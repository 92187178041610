import classnames from 'classnames';
import Image from 'next/image';
import React, { useEffect, useRef, useState } from 'react';
import { Path, UseFormRegister } from 'react-hook-form';

import ErrorLabel from '../label/ErrorLabel';
import InputLabel from '../label/InputLabel';

export enum BasicInputStyle {
    default = 'default',
    gray = 'gray',
    gray_on_white_bg = 'gray_on_white_bg',
    light = 'light',
    default_bg_opacity_50 = 'default_bg_opacity_50',
    frameless = 'frameless'
}

export type BasicInputProps<TFieldName> = {
    id?: string;
    className?: string;
    inputWrapperClassName?: string;
    inputRadiusClassName?: string;
    inputBackgroundClassName?: string;
    inputTextClassName?: string;
    style?: BasicInputStyle;
    type?: string;
    label?: string;
    labelClassName?: string;
    value?: string | number;
    error?: string;
    onChange?: (value: string, event) => void;
    onFocusChange?: (state: boolean) => void;
    onEnterPress?: (value: string) => void;
    placeholder?: string;
    title?: string;
    leadingIcon?: string | JSX.Element | StaticImageData;
    trailingIcon?: string | JSX.Element | StaticImageData;
    classNameTrailingIcon?: string;
    trailingIconAction?: () => void;
    onClick?: (event, input) => void;
    readOnly?: boolean;
    disabled?: boolean;
    inputRef?;
    register?: UseFormRegister<TFieldName>;
    formFieldName?: Path<TFieldName>;
    required?: boolean;
    onBlur?: () => void;
};

function BasicInput<TFieldName>(props: BasicInputProps<TFieldName>): JSX.Element {
    const {
        id,
        className,
        inputWrapperClassName,
        inputBackgroundClassName,
        inputRadiusClassName,
        inputTextClassName,
        style,
        type,
        label,
        labelClassName,
        value,
        error,
        onChange,
        onFocusChange,
        onEnterPress,
        placeholder,
        title,
        leadingIcon,
        trailingIcon,
        classNameTrailingIcon,
        trailingIconAction,
        onClick,
        register,
        formFieldName,
        required
    } = props;

    const [focused, setFocused] = useState(false);
    const [currentValue, setCurrentValue] = useState(value);

    useEffect(() => {
        setCurrentValue(value);
    }, [value]);

    let { inputRef } = props;
    if (!inputRef && !register) {
        inputRef = useRef(value || '');
    }

    let { readOnly } = props;
    if (readOnly === undefined) {
        readOnly = false;
    }

    const { disabled } = props;
    if (disabled === true) {
        readOnly = true;
    }

    function focusInputHandler() {
        if (readOnly !== true && disabled !== true) {
            inputRef?.current?.focus();
        }
    }

    function onInputFocus() {
        if (readOnly !== true && disabled !== true) {
            setFocused(true);
        }
    }

    function onInputBlur() {
        props.onBlur && props.onBlur();
        setFocused(false);
    }

    function onInputChange(event) {
        if (readOnly) {
            return;
        }

        setCurrentValue(inputRef?.current?.value);

        if (onChange) {
            onChange(inputRef?.current?.value, event);
        }
    }

    function onInputClick(event) {
        if (onClick && disabled !== true) {
            onClick(event, inputRef?.current);
        }
    }

    function onKeyDown(event) {
        if (event.key === 'Enter' && onEnterPress) {
            onEnterPress(inputRef?.current.value);
        }
    }

    function onTrailingIconClick() {
        focusInputHandler();
        if (trailingIconAction) {
            trailingIconAction();
        }
    }
    useEffect(() => {
        if (inputRef?.current) {
            try {
                inputRef.current.value = currentValue || '';
            } catch {
                try {
                    inputRef.current = currentValue;
                } catch {
                    // Error
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentValue]);

    useEffect(() => {
        if (onFocusChange) {
            onFocusChange(focused);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [focused]);

    // TODO: refactoring needed! This component must be stateless.
    //  It triggers the onChange() callback with the previous value,
    //  when initial value changes causing issues with proper value storage.
    // useEffect(() => {
    //     onInputChange(null);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [inputRef?.current?.value]);

    const inputStyle = '__remove__input__outline focus:outline-none';

    let labelStyle: string;
    let inputWrapperStyle: string;
    let borderStyle: string;
    let textStyle: string;
    switch (style) {
        case BasicInputStyle.light:
            inputWrapperStyle = `border-b-1 ${focused ? 'border-cyan-middle' : 'border-darkBlue-transparent'}`;
            textStyle = inputTextClassName || 'text-darkBlue input-light';
            labelStyle = `${focused ? 'opacity-100' : 'opacity-50'}`;
            borderStyle = '';
            break;
        case BasicInputStyle.gray:
            inputWrapperStyle = classnames(
                inputWrapperClassName || ' bg-darkBlue bg-opacity-20',
                'rounded-4 border-1 border-transparent'
            );
            textStyle = inputTextClassName || 'text-white';
            labelStyle = `${focused ? 'opacity-100 text-darkBlue' : 'opacity-50'}`;
            borderStyle = `${focused ? 'border-1 border-solid border-darkBlue' : ''}`;
            break;
        case BasicInputStyle.gray_on_white_bg:
            inputWrapperStyle = classnames(
                inputWrapperClassName,
                inputBackgroundClassName || ' bg-gray-light ',
                'rounded-4 border-1 border-transparent'
            );
            textStyle = inputTextClassName || 'text-darkBlue';
            labelStyle = `${focused ? 'opacity-100 text-darkBlue' : 'text-darkBlue opacity-50'}`;
            borderStyle = `${focused ? 'border-1 border-solid border-darkBlue' : ''}`;
            break;
        case BasicInputStyle.default_bg_opacity_50:
            inputWrapperStyle = classnames(
                inputWrapperClassName,
                inputRadiusClassName || 'rounded-4',
                inputBackgroundClassName || 'bg-darkBlue bg-opacity-20',
                'border-1 border-transparent'
            );
            textStyle = inputTextClassName || 'text-white';
            labelStyle = `${focused ? 'opacity-100 text-cyan' : 'text-gray-transparent'}`;
            borderStyle = `${focused ? 'border-1 border-solid border-cyan' : ''}`;
            break;
        case BasicInputStyle.frameless:
            inputWrapperStyle = classnames(inputWrapperClassName, inputRadiusClassName, 'border-1 border-transparent');
            textStyle = inputTextClassName || 'text-white';
            labelStyle = `${focused ? 'opacity-100 text-cyan' : 'text-gray-transparent'}`;
            borderStyle = `${focused ? 'border-1 border-solid border-cyan' : ''}`;
            break;
        default:
            inputWrapperStyle = classnames(
                inputWrapperClassName,
                inputRadiusClassName || 'rounded-4',
                inputBackgroundClassName || 'bg-darkBlue bg-opacity-20',
                'border-1 border-transparent'
            );
            textStyle = inputTextClassName || 'text-white';
            labelStyle = `${focused ? 'opacity-100 text-cyan' : 'text-gray-transparent'}`;
            borderStyle = `${focused ? 'border-1 border-solid border-cyan' : ''}`;
            break;
    }

    const inputClassName = classnames(
        className,
        inputStyle,
        textStyle,
        'w-full h-full px-3 m-auto',
        '__remove__input__outline text-body-16 font-montserrat-regular',
        'bg-transparent bg-none focus:outline-none outline-none border-none',
        disabled === true ? 'cursor-default' : 'cursor-text'
    );

    const input = () => {
        if (register) {
            return (
                <input
                    className={inputClassName}
                    id={id}
                    title={title}
                    type={type}
                    onChange={onInputChange}
                    onFocus={onInputFocus}
                    onBlur={onInputBlur}
                    placeholder={placeholder}
                    readOnly={readOnly}
                    onClick={onInputClick}
                    {...register(formFieldName, {
                        required
                    })}
                />
            );
        }
        return (
            <input
                className={inputClassName}
                id={id}
                title={title}
                type={type}
                value={inputRef?.current?.value || ''}
                onChange={onInputChange}
                onFocus={onInputFocus}
                onBlur={onInputBlur}
                placeholder={placeholder}
                onClick={onInputClick}
                readOnly={readOnly}
                ref={inputRef}
            />
        );
    };

    const trailingElement = () => {
        if (trailingIcon && typeof trailingIcon === 'string') {
            return (
                <button
                    className={classnames(
                        classNameTrailingIcon,
                        'w-48 h-48 relative my-auto justify-center',
                        'p-10',
                        disabled === true ? 'cursor-default' : 'cursor-text'
                    )}
                    type="button"
                    onClick={onTrailingIconClick}>
                    <Image
                        layout="fill"
                        src={trailingIcon}
                        alt=""
                        sizes="100%"
                        objectFit="contain"
                    />
                </button>
            );
        } else if (trailingIcon && React.isValidElement(trailingIcon)) {
            return (
                <button
                    className={classnames(classNameTrailingIcon, 'relative my-auto p-10 self-center')}
                    onClick={onTrailingIconClick}
                    type="button">
                    {trailingIcon}
                </button>
            );
        }

        return null;
    };

    return (
        <div
            className="w-full flex flex-col"
            onBlur={onInputBlur}
            onKeyDown={onKeyDown}>
            {label && (
                <InputLabel
                    label={label}
                    textSizeClassName={labelClassName}
                    labelStyle={labelStyle}
                    requiredLabel={props.required}
                />
            )}
            <div
                className={classnames(
                    inputWrapperStyle,
                    borderStyle,
                    'w-auto h-48 flex-auto flex',
                    disabled === true ? 'pointer-events-none cursor-not-allowed' : ''
                )}>
                <span
                    className={`w-48 h-48 my-auto m-0 flex 
            ${leadingIcon || 'hidden'}`}
                    onClick={focusInputHandler}
                />

                {input()}

                {trailingElement()}
            </div>

            {error && <ErrorLabel error={error} />}
        </div>
    );
}

export default BasicInput;
