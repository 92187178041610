import {CustomSVGProps} from '../../types/CustomSVGProps.types';

export function IconCinemaApp(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="325" height="325" viewBox="0 0 325 325" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 81.25C0 36.3769 36.3769 0 81.25 0H243.75C288.623 0 325 36.3769 325 81.25V243.75C325 288.623 288.623 325 243.75 325H81.25C36.3769 325 0 288.623 0 243.75V81.25Z"
                fill="#000023"/>
            <path
                d="M0 81.25C0 36.3769 36.3769 0 81.25 0H243.75C288.623 0 325 36.3769 325 81.25V243.75C325 288.623 288.623 325 243.75 325H81.25C36.3769 325 0 288.623 0 243.75V81.25Z"
                fill="url(#paint0_radial_3483_51575)" fillOpacity="0.9"/>
            <path
                d="M0 81.25C0 36.3769 36.3769 0 81.25 0H243.75C288.623 0 325 36.3769 325 81.25V243.75C325 288.623 288.623 325 243.75 325H81.25C36.3769 325 0 288.623 0 243.75V81.25Z"
                fill="url(#paint1_radial_3483_51575)" fillOpacity="0.9"/>
            <path
                d="M0 81.25C0 36.3769 36.3769 0 81.25 0H243.75C288.623 0 325 36.3769 325 81.25V243.75C325 288.623 288.623 325 243.75 325H81.25C36.3769 325 0 288.623 0 243.75V81.25Z"
                fill="url(#paint2_radial_3483_51575)" fillOpacity="0.9"/>
            <path
                d="M0 81.25C0 36.3769 36.3769 0 81.25 0H243.75C288.623 0 325 36.3769 325 81.25V243.75C325 288.623 288.623 325 243.75 325H81.25C36.3769 325 0 288.623 0 243.75V81.25Z"
                fill="url(#paint3_radial_3483_51575)" fillOpacity="0.9"/>
            <path
                d="M0 81.25C0 36.3769 36.3769 0 81.25 0H243.75C288.623 0 325 36.3769 325 81.25V243.75C325 288.623 288.623 325 243.75 325H81.25C36.3769 325 0 288.623 0 243.75V81.25Z"
                fill="url(#paint4_radial_3483_51575)" fillOpacity="0.9"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M46.9533 82.9592C43.8309 82.4561 41 84.8674 41 88.0301V237.969C41 241.132 43.8314 243.544 46.9544 243.041C123.495 230.711 202.401 230.803 278.958 243.035C282.104 243.537 285 241.114 285 237.928V88.071C285 84.8854 282.105 82.4627 278.959 82.9654C202.402 95.2 123.495 95.292 46.9533 82.9592ZM213.89 153.274L136.648 117.773C129.919 114.681 122.25 119.597 122.25 127.002V198.003C122.25 205.408 129.919 210.323 136.648 207.231L213.89 171.731C221.777 168.106 221.777 156.899 213.89 153.274Z"
                  fill="url(#paint5_linear_3483_51575)"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M245 5H80C38.5786 5 5 38.5786 5 80V245C5 286.421 38.5786 320 80 320H245C286.421 320 320 286.421 320 245V80C320 38.5786 286.421 5 245 5ZM80 0C35.8172 0 0 35.8172 0 80V245C0 289.183 35.8172 325 80 325H245C289.183 325 325 289.183 325 245V80C325 35.8172 289.183 0 245 0H80Z"
                  fill="#C2C2C2"/>
            <defs>
                <radialGradient id="paint0_radial_3483_51575" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(315.467 328.002) rotate(-116.343) scale(361.321 583.749)">
                    <stop stopColor="#3815C2"/>
                    <stop offset="1" stopColor="#3815C2" stopOpacity="0.49"/>
                </radialGradient>
                <radialGradient id="paint1_radial_3483_51575" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(55.7465 270.569) rotate(-37.7276) scale(205.597 344.322)">
                    <stop offset="0.345246" stopColor="#18185F"/>
                    <stop offset="1" stopColor="#1E1E41" stopOpacity="0"/>
                </radialGradient>
                <radialGradient id="paint2_radial_3483_51575" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(316.767 5.2032) rotate(42.1729) scale(440.863 281.043)">
                    <stop stopColor="#29D798"/>
                    <stop offset="0.308137" stopColor="#29D798" stopOpacity="0.61"/>
                    <stop offset="1" stopColor="#18AB76" stopOpacity="0"/>
                </radialGradient>
                <radialGradient id="paint3_radial_3483_51575" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(37.0703 3.147) rotate(56.6834) scale(335.399 415.262)">
                    <stop stopColor="#610769"/>
                    <stop offset="0.156083" stopColor="#5A0662" stopOpacity="0.781367"/>
                    <stop offset="1" stopColor="#410247" stopOpacity="0"/>
                </radialGradient>
                <radialGradient id="paint4_radial_3483_51575" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(162.5 237.878) rotate(-86.8059) scale(141.773 143.432)">
                    <stop stopColor="#35008F" stopOpacity="0.47"/>
                    <stop offset="1" stopColor="#35008F" stopOpacity="0"/>
                </radialGradient>
                <linearGradient id="paint5_linear_3483_51575" x1="41" y1="162.5" x2="284.75" y2="162.5"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00AAA0"/>
                    <stop offset="0.322917" stopColor="#00FFDC"/>
                    <stop offset="0.6875" stopColor="#00FFDC"/>
                    <stop offset="1" stopColor="#00AAA0"/>
                </linearGradient>
            </defs>
        </svg>
    );
}