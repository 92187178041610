import classnames from 'classnames';
import { useRouter } from 'next/router';
import React from 'react';

import MobileOrDefaultRenderer from '@/components/layouts/MobileOrDefaultRenderer';
import MobileMenu from '@/components/navigation/MobileMenu';
import NavMovieSearchBar from '@/components/navigation/NavMovieSearchBar';
import NavigationDefaultProfileComponent from '@/components/navigation/NavigationDefaultProfileComponent';

import { loadAsset } from '@/cineamo-frontend-lib/helper/asset-helper';

import { useNotificationsStore } from '@/cineamo-frontend-lib/store/notifications/notificationsStore';
import { useUserStore } from '@/store/user/userStore';

const defaultUserImage = loadAsset('/images/placeholders/im-avatar.png');

function NavigationSearchProfileAndNotificationComponent() {
    const locale = useRouter().locale;

    const { user } = useUserStore();

    const { unreadNotificationsCount } = useNotificationsStore();

    const className = 'flex justify-end w-full';

    return (
        <MobileOrDefaultRenderer
            mobileContent={
                <div className={className}>
                    <MobileMenu
                        avatar={user?.profileImageUrl ? user.profileImageUrl : defaultUserImage}
                        avatarFallback={defaultUserImage}
                        avatarAlt={user?.username ? `${user?.username} Profile Image` : 'User Profile Image'}
                        unreadNotificationsNumber={unreadNotificationsCount}
                        locale={locale}
                    />
                </div>
            }
            defaultContent={
                <div className={className}>
                    <div className="flex flex-row w-auto flex-auto space-x-24 justify-end">
                        <div className="relative flex flex-row w-auto flex-auto items-center justify-end space-x-16">
                            <NavMovieSearchBar
                                searchBarClassName={classnames('transition-all duration-300', 'bg-white bg-opacity-20')}
                                inputClassName={classnames('placeholder-white-50')}
                                locale={locale}
                            />

                            <NavigationDefaultProfileComponent />
                        </div>
                    </div>
                </div>
            }
        />
    );
}

export default NavigationSearchProfileAndNotificationComponent;
