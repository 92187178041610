import {CustomSVGProps} from '../../types/CustomSVGProps.types';

export function IconAdminApp(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props} 
             width="325" height="325" viewBox="0 0 325 325" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 81.25C0 36.3769 36.3769 0 81.25 0H243.75C288.623 0 325 36.3769 325 81.25V243.75C325 288.623 288.623 325 243.75 325H81.25C36.3769 325 0 288.623 0 243.75V81.25Z" fill="#000023"/>
            <path d="M0 81.25C0 36.3769 36.3769 0 81.25 0H243.75C288.623 0 325 36.3769 325 81.25V243.75C325 288.623 288.623 325 243.75 325H81.25C36.3769 325 0 288.623 0 243.75V81.25Z" fill="url(#paint0_radial_3483_51574)" fillOpacity="0.9"/>
            <path d="M0 81.25C0 36.3769 36.3769 0 81.25 0H243.75C288.623 0 325 36.3769 325 81.25V243.75C325 288.623 288.623 325 243.75 325H81.25C36.3769 325 0 288.623 0 243.75V81.25Z" fill="url(#paint1_radial_3483_51574)" fillOpacity="0.9"/>
            <path d="M0 81.25C0 36.3769 36.3769 0 81.25 0H243.75C288.623 0 325 36.3769 325 81.25V243.75C325 288.623 288.623 325 243.75 325H81.25C36.3769 325 0 288.623 0 243.75V81.25Z" fill="url(#paint2_radial_3483_51574)" fillOpacity="0.9"/>
            <path d="M0 81.25C0 36.3769 36.3769 0 81.25 0H243.75C288.623 0 325 36.3769 325 81.25V243.75C325 288.623 288.623 325 243.75 325H81.25C36.3769 325 0 288.623 0 243.75V81.25Z" fill="url(#paint3_radial_3483_51574)" fillOpacity="0.9"/>
            <path d="M0 81.25C0 36.3769 36.3769 0 81.25 0H243.75C288.623 0 325 36.3769 325 81.25V243.75C325 288.623 288.623 325 243.75 325H81.25C36.3769 325 0 288.623 0 243.75V81.25Z" fill="url(#paint4_radial_3483_51574)" fillOpacity="0.9"/>
            <path d="M107.089 64.6704C109.854 56.3759 117.616 50.7812 126.359 50.7812H198.641C207.384 50.7812 215.146 56.3759 217.911 64.6704L276.883 241.587C279.707 250.057 270.819 258.218 262.374 255.319C250.433 251.22 235.658 247.224 223.438 247.224C198.353 247.224 187.585 258.45 162.5 258.45C137.415 258.45 126.648 247.224 101.563 247.224C89.3426 247.224 74.5672 251.22 62.6261 255.319C54.1813 258.218 45.2937 250.057 48.1171 241.587L107.089 64.6704Z" fill="url(#paint5_linear_3483_51574)"/>
            <path d="M162.5 111.719L112.761 90.402C101.459 85.5583 88.4788 91.7881 85.1878 103.636L42.5276 257.212C41.3851 261.325 42.9431 265.709 46.6462 267.832C57.8672 274.267 82.5592 285.621 101.562 274.219C126.953 258.984 137.415 111.719 162.5 111.719Z" fill="url(#paint6_linear_3483_51574)"/>
            <path d="M162.5 111.719L212.239 90.402C223.541 85.5583 236.521 91.7881 239.812 103.636L282.472 257.212C283.615 261.325 282.057 265.709 278.354 267.832C267.133 274.267 242.441 285.621 223.438 274.219C198.047 258.984 187.585 111.719 162.5 111.719Z" fill="url(#paint7_linear_3483_51574)"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M159.784 40.625C129.008 40.625 99.4929 52.8507 77.731 74.6126C74.5319 77.8117 75.537 83.2248 79.6713 85.0622L150.127 116.375C158.005 119.877 166.997 119.877 174.876 116.375L245.329 85.0623C249.463 83.2248 250.468 77.8118 247.269 74.6127C225.507 52.8507 195.991 40.625 165.215 40.625H159.784ZM209.473 76.6796C185.028 47.3452 139.973 47.3452 115.528 76.6796C113.526 79.0813 114.14 82.7024 116.821 84.3109L146.824 102.313C156.473 108.102 168.528 108.102 178.177 102.313L208.18 84.3109C210.861 82.7024 211.475 79.0813 209.473 76.6796Z" fill="url(#paint8_linear_3483_51574)"/>
            <path d="M187.969 121.875C187.969 135.941 176.566 147.344 162.5 147.344C148.434 147.344 137.031 135.941 137.031 121.875C137.031 107.809 148.434 96.4062 162.5 96.4062C176.566 96.4062 187.969 107.809 187.969 121.875Z" fill="url(#paint9_linear_3483_51574)"/>
            <path d="M187.969 121.875C187.969 135.941 176.566 147.344 162.5 147.344C148.434 147.344 137.031 135.941 137.031 121.875C137.031 107.809 148.434 96.4062 162.5 96.4062C176.566 96.4062 187.969 107.809 187.969 121.875Z" stroke="#000023" strokeWidth="10"/>
            <path d="M187.969 121.875C187.969 135.941 176.566 147.344 162.5 147.344C148.434 147.344 137.031 135.941 137.031 121.875C137.031 107.809 148.434 96.4062 162.5 96.4062C176.566 96.4062 187.969 107.809 187.969 121.875Z" stroke="url(#paint10_radial_3483_51574)" strokeOpacity="0.9" strokeWidth="10"/>
            <path d="M187.969 121.875C187.969 135.941 176.566 147.344 162.5 147.344C148.434 147.344 137.031 135.941 137.031 121.875C137.031 107.809 148.434 96.4062 162.5 96.4062C176.566 96.4062 187.969 107.809 187.969 121.875Z" stroke="url(#paint11_radial_3483_51574)" strokeOpacity="0.9" strokeWidth="10"/>
            <path d="M187.969 121.875C187.969 135.941 176.566 147.344 162.5 147.344C148.434 147.344 137.031 135.941 137.031 121.875C137.031 107.809 148.434 96.4062 162.5 96.4062C176.566 96.4062 187.969 107.809 187.969 121.875Z" stroke="url(#paint12_radial_3483_51574)" strokeOpacity="0.9" strokeWidth="10"/>
            <path d="M187.969 121.875C187.969 135.941 176.566 147.344 162.5 147.344C148.434 147.344 137.031 135.941 137.031 121.875C137.031 107.809 148.434 96.4062 162.5 96.4062C176.566 96.4062 187.969 107.809 187.969 121.875Z" stroke="url(#paint13_radial_3483_51574)" strokeOpacity="0.9" strokeWidth="10"/>
            <path d="M187.969 121.875C187.969 135.941 176.566 147.344 162.5 147.344C148.434 147.344 137.031 135.941 137.031 121.875C137.031 107.809 148.434 96.4062 162.5 96.4062C176.566 96.4062 187.969 107.809 187.969 121.875Z" stroke="url(#paint14_radial_3483_51574)" strokeOpacity="0.9" strokeWidth="10"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M245 5H80C38.5786 5 5 38.5786 5 80V245C5 286.421 38.5786 320 80 320H245C286.421 320 320 286.421 320 245V80C320 38.5786 286.421 5 245 5ZM80 0C35.8172 0 0 35.8172 0 80V245C0 289.183 35.8172 325 80 325H245C289.183 325 325 289.183 325 245V80C325 35.8172 289.183 0 245 0H80Z" fill="#C2C2C2"/>
            <defs>
                <radialGradient id="paint0_radial_3483_51574" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(315.467 328.002) rotate(-116.343) scale(361.321 583.749)">
                    <stop stopColor="#3815C2"/>
                    <stop offset="1" stopColor="#3815C2" stopOpacity="0.49"/>
                </radialGradient>
                <radialGradient id="paint1_radial_3483_51574" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(55.7465 270.569) rotate(-37.7276) scale(205.597 344.322)">
                    <stop offset="0.345246" stopColor="#18185F"/>
                    <stop offset="1" stopColor="#1E1E41" stopOpacity="0"/>
                </radialGradient>
                <radialGradient id="paint2_radial_3483_51574" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(316.767 5.2032) rotate(42.1729) scale(440.863 281.043)">
                    <stop stopColor="#29D798"/>
                    <stop offset="0.308137" stopColor="#29D798" stopOpacity="0.61"/>
                    <stop offset="1" stopColor="#18AB76" stopOpacity="0"/>
                </radialGradient>
                <radialGradient id="paint3_radial_3483_51574" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(37.0703 3.147) rotate(56.6834) scale(335.399 415.262)">
                    <stop stopColor="#610769"/>
                    <stop offset="0.156083" stopColor="#5A0662" stopOpacity="0.781367"/>
                    <stop offset="1" stopColor="#410247" stopOpacity="0"/>
                </radialGradient>
                <radialGradient id="paint4_radial_3483_51574" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(162.5 237.878) rotate(-86.8059) scale(141.773 143.432)">
                    <stop stopColor="#35008F" stopOpacity="0.47"/>
                    <stop offset="1" stopColor="#35008F" stopOpacity="0"/>
                </radialGradient>
                <linearGradient id="paint5_linear_3483_51574" x1="40.6252" y1="275.288" x2="40.6252" y2="50.7812" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00FFDC"/>
                    <stop offset="0.630208" stopColor="#006270"/>
                </linearGradient>
                <linearGradient id="paint6_linear_3483_51574" x1="40.6248" y1="273.684" x2="40.6248" y2="81.25" gradientUnits="userSpaceOnUse">
                    <stop offset="0.6875" stopColor="#00FFDC"/>
                    <stop offset="1" stopColor="#006270"/>
                </linearGradient>
                <linearGradient id="paint7_linear_3483_51574" x1="284.375" y1="273.684" x2="284.375" y2="81.25" gradientUnits="userSpaceOnUse">
                    <stop offset="0.6875" stopColor="#00FFDC"/>
                    <stop offset="1" stopColor="#006270"/>
                </linearGradient>
                <linearGradient id="paint8_linear_3483_51574" x1="78.924" y1="40.625" x2="78.924" y2="116.213" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00FFDC"/>
                    <stop offset="0.509799" stopColor="#00FFDC"/>
                    <stop offset="1" stopColor="#00AAA0"/>
                </linearGradient>
                <linearGradient id="paint9_linear_3483_51574" x1="162.5" y1="101.562" x2="162.5" y2="142.187" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#887D9A"/>
                    <stop offset="1" stopColor="#F2F2F2"/>
                </linearGradient>
                <radialGradient id="paint10_radial_3483_51574" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(191.181 152.907) rotate(-116.343) scale(67.7477 109.453)">
                    <stop stopColor="#3815C2"/>
                    <stop offset="1" stopColor="#3815C2" stopOpacity="0.49"/>
                </radialGradient>
                <radialGradient id="paint11_radial_3483_51574" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(142.484 142.138) rotate(-37.7276) scale(38.5495 64.5604)">
                    <stop offset="0.345246" stopColor="#18185F"/>
                    <stop offset="1" stopColor="#1E1E41" stopOpacity="0"/>
                </radialGradient>
                <radialGradient id="paint12_radial_3483_51574" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(191.425 92.3818) rotate(42.1729) scale(82.6618 52.6956)">
                    <stop stopColor="#29D798"/>
                    <stop offset="0.308137" stopColor="#29D798" stopOpacity="0.61"/>
                    <stop offset="1" stopColor="#18AB76" stopOpacity="0"/>
                </radialGradient>
                <radialGradient id="paint13_radial_3483_51574" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(138.982 91.9963) rotate(56.6834) scale(62.8874 77.8617)">
                    <stop stopColor="#610769"/>
                    <stop offset="0.156083" stopColor="#5A0662" stopOpacity="0.781367"/>
                    <stop offset="1" stopColor="#410247" stopOpacity="0"/>
                </radialGradient>
                <radialGradient id="paint14_radial_3483_51574" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(162.5 136.008) rotate(-86.8059) scale(26.5824 26.8935)">
                    <stop stopColor="#35008F" stopOpacity="0.47"/>
                    <stop offset="1" stopColor="#35008F" stopOpacity="0"/>
                </radialGradient>
            </defs>
        </svg>

    );
}