import classnames from 'classnames';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { stringFormatJsx } from '../../../helper/string-helper';

import useCookies from '../../../hooks/useCookies';

import { IconLock16 } from '../../../icons/ic-lock';

import Button, { ButtonStyle } from '../buttons/Button';
import TwoButtonComponent from '../buttons/TwoButtonComponent';
import LinkWithStyle from '../link/LinkWithStyle';
import { TabSwitch } from '../switch/TabSwitch';
import Modal, { ModalProps } from './Modal';

type CookiePreferencesModalProps = ModalProps & {
    onBackClick?: () => void;
};

type CookiePreferencesModalSwitchAndTextContent = {
    title: string;
    text: string;
    isCookieUserEditable?: boolean;
    onSwitchChange?: (state: boolean) => void;
    switchState?: boolean;
};

export function CookiePreferencesModal(props: CookiePreferencesModalProps) {
    const t = props.t || useTranslation('common').t;

    const {
        essentialCookiesPermissionState,
        functionalCookiesPermissionState,
        analysisCookiesPermissionState,
        setFunctionalCookiesPermissionState,
        setAnalysisCookiesPermissionState,
        save,
        rejectAllOptionalCookies
    } = useCookies();

    const switchAndTextContents: CookiePreferencesModalSwitchAndTextContent[] = [
        {
            title: t('common:cookie-preferences-modal.essential.title'),
            text: t('common:cookie-preferences-modal.essential.text'),
            isCookieUserEditable: false
        },
        {
            title: t('common:cookie-preferences-modal.functional.title'),
            text: t('common:cookie-preferences-modal.functional.text'),
            onSwitchChange: (state) => setFunctionalCookiesPermissionState(state),
            switchState: functionalCookiesPermissionState
        },
        {
            title: t('common:cookie-preferences-modal.analysis.title'),
            text: t('common:cookie-preferences-modal.analysis.text'),
            onSwitchChange: (state) => setAnalysisCookiesPermissionState(state),
            switchState: analysisCookiesPermissionState
        }
    ];

    function saveCookiesPreferenceAndClose() {
        save();
        props.setModalIsOpen(false);
    }

    function rejectAllOptionalCookiesAndClose() {
        rejectAllOptionalCookies();
        props.setModalIsOpen(false);
    }

    return (
        <Modal
            {...props}
            onClose={props.onBackClick || null}
            className="h-full !max-h-full !my-24">
            <div className="flex flex-col h-full space-y-6 overflow-hidden">
                <div className="flex flex-row space-x-12 items-center">
                    <span className="text-body-bold-16">{t('common:cookies-modal.your-privacy')}</span>
                    <IconLock16 className="flex-none text-cyan-dark" />
                </div>
                <div className="flex flex-col flex-auto space-y-16 overflow-auto">
                    <div className="flex flex-col sm:h-full flex-auto space-y-24">
                        <span className="text-body-12">
                            {stringFormatJsx(
                                t('common:cookies-modal.description'),
                                <LinkWithStyle
                                    className="underline"
                                    href={'/data-protection'}>
                                    {t('common:cookies-modal.privacy-policy')}
                                </LinkWithStyle>
                            )}
                        </span>
                        <div className="flex flex-col w-full space-y-16">
                            {switchAndTextContents.map((item, index) => (
                                <div
                                    key={index}
                                    className="w-full flex flex-col md:flex-row md:space-x-24">
                                    <div className="flex flex-col w-full md:w-1/4 order-2 md:order-1">
                                        {item.isCookieUserEditable !== false ? (
                                            <TabSwitch
                                                className="w-full rounded-4 bg-gray"
                                                textClassName="uppercase"
                                                tabClassName="max-h-32"
                                                activeTabClassName="opacity-100 bg-cyan-middle max-h-32 rounded-4"
                                                state={item.switchState}
                                                onChange={item.onSwitchChange}
                                                onText={t('common:yes')}
                                                offText={t('common:no')}
                                            />
                                        ) : (
                                            <Button
                                                className="w-full"
                                                style={ButtonStyle.default_on_white_bg}
                                                disabled>
                                                {essentialCookiesPermissionState ? t('common:yes') : t('common:no')}
                                            </Button>
                                        )}
                                    </div>
                                    <div className="flex flex-col w-full md:w-3/4 order-1 md:order-2 mb-8 md:mb-0">
                                        <span className="text-body-bold-16">{item.title}</span>
                                        <span className="text-body-12">{item.text}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="text-body-12">{t('common:cookie-preferences-modal.footer-text')}</div>
                    </div>

                    <TwoButtonComponent
                        primaryLabel={t('common:cookie-preferences-modal.button.save')}
                        primaryAction={saveCookiesPreferenceAndClose}
                        primaryButtonProps={{
                            className: classnames('h-48')
                        }}
                        secondaryLabel={t('common:cookie-preferences-modal.button.reject')}
                        secondaryAction={rejectAllOptionalCookiesAndClose}
                        secondaryButtonProps={{
                            className: classnames('h-48')
                        }}
                        stretched
                    />
                </div>
            </div>
        </Modal>
    );
}
