import classnames from 'classnames';
import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useInterval, useLocalStorage } from 'react-use';

import { IconClose16 } from '@/cineamo-frontend-lib/icons/menu/ic-close';

export type BannerStorage = {
    id: string;
    display: boolean;
    created: Date;
    lastAccessed: Date;
    expires: number;
};

export type BannerContainerProps = {
    id: string;
    text: string | string[];
    options?: {
        minPauseBetweenText?: number;
        pausePerCharacter?: number;
        showUpDelay?: number;
        isClosable?: boolean;
        pauseOnHover?: boolean;
        keepClosedForMillis?: number;
    };
};

function BannerContainer(props: BannerContainerProps) {
    const ref = useRef(null);

    const [isBannerVisible, setIsBannerVisible] = useState(false);
    const [bannerTexts, setBannerTexts] = useState<string | string[]>([]);
    const [currentBannerTextIndex, setCurrentBannerTextIndex] = useState(0);
    const [isPause, setIsPause] = useState(false);
    const [currentPause, setCurrentPause] = useState(2000);
    const [isFirstLoopDone, setIsFirstLoopDone] = useState(false);
    const [animateNext, setAnimateNext] = useState(true);

    // Reset initial state values if new banner should be shown!
    useEffect(() => {
        checkAndUpdateLocalStorage();
        setIsBannerVisible(false);
        setCurrentBannerTextIndex(0);
        setIsPause(false);
        setCurrentPause(2000);
        setIsFirstLoopDone(false);
        setAnimateNext(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.id]);

    if (!props.id) {
        throw new Error('Please set a banner ID!');
    }

    const prefix = 'banner_';
    const bannerId = prefix + props.id;
    const minPauseBetweenText =
        props.options?.minPauseBetweenText ?? BannerContainer.defaultProps.options.minPauseBetweenText;
    const pausePerCharacter =
        props.options?.pausePerCharacter ?? BannerContainer.defaultProps.options.pausePerCharacter;
    const showUpDelay = props.options?.showUpDelay ?? BannerContainer.defaultProps.options.showUpDelay;
    const isClosable = props.options?.isClosable ?? BannerContainer.defaultProps.options.isClosable;
    const pauseOnHover = props.options?.pauseOnHover ?? BannerContainer.defaultProps.options.pauseOnHover;
    const expirationTime =
        props.options?.keepClosedForMillis ?? BannerContainer.defaultProps.options.keepClosedForMillis;

    const [localStorage, setLocalStorage] = useLocalStorage<BannerStorage[]>('banner', []);

    useEffect(() => {
        if (!checkAndUpdateLocalStorage()) {
            return;
        }

        setTimeout(() => {
            setIsBannerVisible(true);
        }, showUpDelay);

        if (typeof props.text !== 'string' && props.text.length > 0) {
            setBannerTexts([...props.text, props.text[0]]);
        } else if (typeof props.text === 'string') {
            setBannerTexts(props.text);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isBannerVisible]);

    useEffect(() => {
        setCurrentPause(Math.max(minPauseBetweenText, (ref.current?.innerText?.length || 0) * pausePerCharacter));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentBannerTextIndex]);

    useEffect(() => {
        if (!animateNext) {
            setCurrentBannerTextIndex(0);
        }
    }, [animateNext]);

    useInterval(
        () => {
            if (!isBannerVisible || isPause) {
                return;
            }

            let newIndex;
            if (currentBannerTextIndex >= bannerTexts.length - 1) {
                newIndex = 0;
                setIsFirstLoopDone(true);
            } else {
                newIndex = currentBannerTextIndex + 1;
            }
            setAnimateNext(newIndex < bannerTexts.length);
            setCurrentBannerTextIndex(newIndex);
        },
        currentBannerTextIndex === 0 && isFirstLoopDone ? 0 : currentPause + (!isFirstLoopDone ? showUpDelay : 0)
    );

    function closeBannerHandler(e: SyntheticEvent) {
        e.stopPropagation();
        setIsBannerVisible(!isBannerVisible);
        setLocalStorage([
            ...(localStorage || []).filter((banner) => banner.id !== bannerId),
            {
                id: bannerId,
                created: new Date(),
                lastAccessed: new Date(),
                expires: expirationTime > 0 ? expirationTime : undefined,
                display: false
            }
        ]);
    }

    function pauseHandler(state: boolean) {
        setIsPause(state);
    }

    function checkAndUpdateLocalStorage(): boolean {
        const currentBannerLocalStorage = localStorage?.find((banner) => banner.id === bannerId);
        let display = true;
        let updatedLocalStorage = localStorage;
        if (currentBannerLocalStorage) {
            display =
                Date.now() > new Date(currentBannerLocalStorage.created).getTime() + currentBannerLocalStorage.expires;
            if (display) {
                setLocalStorage([...localStorage.filter((banner) => banner.id !== bannerId)]);
            } else {
                updatedLocalStorage = [
                    ...(localStorage || []).filter((banner) => banner.id !== bannerId),
                    {
                        ...currentBannerLocalStorage,
                        lastAccessed: new Date(),
                        display: display
                    }
                ];
            }
        }
        if (updatedLocalStorage.length > 0) {
            setLocalStorage(updatedLocalStorage);
        } else {
            window.localStorage.removeItem('banner');
        }
        return display;
    }

    if (localStorage?.find((banner) => banner.id === bannerId)?.display === false) {
        return <div />;
    }

    return (
        <div
            className="top-0 w-full h-auto z-modal-view"
            onMouseEnter={() => pauseHandler(pauseOnHover)}
            onMouseLeave={() => pauseHandler(false)}>
            <div
                className={classnames(
                    'relative flex flex-col lg:flex-row w-full bg-white rounded-b-12 transform overflow-hidden',
                    'justify-center items-center',
                    {
                        'transition-all': showUpDelay > 0,
                        'translate-y-0 h-auto': isBannerVisible,
                        '-translate-y-full h-0 opacity-0': !isBannerVisible
                    }
                )}>
                <div className="relative w-full h-full text-center text-body-bold-14 text-darkBlue px-16 py-16 lg:py-12 order-1">
                    <span
                        className="opacity-0 select-none"
                        dangerouslySetInnerHTML={{
                            __html:
                                typeof bannerTexts === 'string'
                                    ? bannerTexts
                                    : bannerTexts.length > 0
                                    ? bannerTexts.reduce((a, b) => (a.length > b.length ? a : b))
                                    : ''
                        }}
                    />
                    <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center">
                        {typeof bannerTexts === 'string' ? (
                            <span className="select-none">{bannerTexts}</span>
                        ) : (
                            bannerTexts.map((text, textIndex) => {
                                return (
                                    <span
                                        key={`message#${textIndex}`}
                                        ref={textIndex === currentBannerTextIndex ? ref : undefined}
                                        className={classnames(
                                            'w-full h-auto select-none px-16 py-12',
                                            {
                                                'duration-200 transition-all':
                                                    currentBannerTextIndex !== 0 &&
                                                    currentBannerTextIndex > 0 &&
                                                    currentBannerTextIndex <= bannerTexts.length - 1
                                            },
                                            {
                                                'absolute transform -translate-y-100 opacity-0':
                                                    textIndex < currentBannerTextIndex
                                            },
                                            { absolute: textIndex === currentBannerTextIndex },
                                            {
                                                'absolute transform translate-y-100 opacity-0':
                                                    textIndex > currentBannerTextIndex
                                            }
                                        )}
                                        dangerouslySetInnerHTML={{ __html: text }}
                                    />
                                );
                            })
                        )}
                    </div>
                </div>
                {isClosable && (
                    <IconClose16
                        className="lg:absolute self-end order-0 right-0 m-16 clickable-element z-10"
                        onClick={closeBannerHandler}
                    />
                )}
            </div>
        </div>
    );
}

export default BannerContainer;

BannerContainer.defaultProps = {
    options: {
        minPauseBetweenText: 2000,
        pausePerCharacter: 50,
        showUpDelay: 2000,
        isClosable: true,
        pauseOnHover: true,
        keepClosedForMillis: 0
    }
};
