import { CartItemDto } from '../../models/cart-item/CartItemDto.types';
import { CartItemInputDto } from '../../models/cart-item/CartItemInputDto';

import { UrlQueryParameter, buildUrlQuery } from '../../helper/url-query-helper';

import { ApiErrorResponse } from '../ApiErrorResponse.types';
import { CartItemListResponse } from '../ApiListResponse.types';
import { ApiResponse } from '../ApiResponse.types';
import apiClient from '../cineamoApiClient';

export async function getCartItemById(id: number | string, cartUuid: string): Promise<CartItemDto | null> {
    return apiClient
        .get<Promise<CartItemDto | null>>(`/carts/${cartUuid}/cart-items/${id}`)
        .then((response) => response.data)
        .catch(() => null);
}

type GetCartItemsQueryParameter = {
    cartUuid: string;
} & UrlQueryParameter;

export async function getCartItems(params?: GetCartItemsQueryParameter): Promise<ApiResponse<CartItemListResponse>> {
    return apiClient
        .get(`/carts/${params.cartUuid}/cart-items${buildUrlQuery(params)}`)
        .then((response) => response.data)
        .catch(() => null);
}

export async function addCartItem(
    cartItem: CartItemInputDto,
    cartUuid: string
): Promise<ApiResponse<CartItemDto> | ApiErrorResponse<CartItemInputDto>> {
    return apiClient
        .post(`/carts/${cartUuid}/cart-items`, cartItem)
        .then((response) => response.data)
        .catch((error) => error);
}

export async function updateCartItem(cartItem: CartItemInputDto, cartUuid: string): Promise<CartItemDto> {
    return apiClient
        .patch(`/carts/${cartUuid}/cart-items/${cartItem.id}`, cartItem)
        .then((response) => response.data)
        .catch((error) => error);
}

// <------------------------ Delete CartItem ------------------------>

export async function deleteCartItemById(cartItemId: number, cartUuid: string): Promise<boolean> {
    return apiClient
        .delete(`/carts/${cartUuid}/cart-items/${cartItemId}`)
        .then((response) => response.status === 204)
        .catch(() => false);
}
