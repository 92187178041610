import {CustomSVGProps} from '../types/CustomSVGProps.types';

export function IconLock48(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11 23V16H11.0091C11.2721 8.7758 17.2116 3 24.5 3C31.7884 3 37.7279 8.7758 37.9909 16H38V23H41C41.5523 23 42 23.4477 42 24V44C42 44.2655 41.8944 44.5201 41.7065 44.7077C41.5187 44.8953 41.2639 45.0004 40.9984 45L6.99839 44.9454C6.44674 44.9445 6 44.497 6 43.9454V24C6 23.4477 6.44772 23 7 23H11ZM24.5 23C24.0455 23 23.5991 22.996 23.1613 22.9869L23.1414 22.9865L13 22.9772V16.286L13.0078 16.0728C13.2318 9.92016 18.2917 5 24.5 5C30.7083 5 35.7682 9.92016 35.9922 16.0728L36 16.286V22.9982L25.7411 22.9888L25.7215 22.9892C25.3213 22.9967 24.914 23 24.5 23ZM8 25V42.947L40 42.9984V25H8Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>


    );
}

export function IconLock24(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 0C8.13401 0 5 3.13401 5 7V10H2C1.44772 10 1 10.4477 1 11V22.9672C1 23.5189 1.44672 23.9663 1.99836 23.9672L21.9984 24C22.2639 24.0004 22.5186 23.8953 22.7065 23.7077C22.8944 23.5201 23 23.2655 23 23V11C23 10.4477 22.5523 10 22 10H19V7C19 3.13401 15.866 0 12 0ZM7 7C7 4.23858 9.23858 2 12 2C14.7614 2 17 4.23858 17 7V9.99772L7 9.97496V7ZM3 21.9689V12H21V21.9984L3 21.9689Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconLock16(props: CustomSVGProps): JSX.Element {
    return (
        <svg
            {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8 0C5.23858 0 3 2.23858 3 5V7H2C1.44772 7 1 7.44772 1 8V14.9809C1 15.5325 1.44674 15.98 1.99841 15.9809L13.9984 16C14.2639 16.0004 14.5187 15.8953 14.7065 15.7077C14.8944 15.5201 15 15.2655 15 15V8C15 7.44772 14.5523 7 14 7H13V5C13 2.23858 10.7614 0 8 0ZM3 9V13.9825L13 13.9984V9H3ZM5 5C5 3.34315 6.34315 2 8 2C9.65685 2 11 3.34315 11 5V6.99761L5 6.98327V5Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}