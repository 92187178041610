import LinkWithStyle from '../link/LinkWithStyle';
import Button from './Button';

type LoginTargetButtonProps = {
    icon: JSX.Element;
    href?: string;
    onClick?: () => void;
    label: string;
};

function LoginTargetButton(props: LoginTargetButtonProps): JSX.Element {
    return (
        <div className="flex flex-row space-x-16 items-center">
            <div className="relative flex-none w-48 h-48">{props.icon}</div>
            <div className="flex-auto">
                <LinkWithStyle href={props.href}>
                    <Button
                        className="w-full h-40 bg-cyan-middle"
                        onClick={props.onClick}>
                        {props.label}
                    </Button>
                </LinkWithStyle>
            </div>
        </div>
    );
}

export default LoginTargetButton;
