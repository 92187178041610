export const cookieStorage = {
    getItem: (key: string): boolean | undefined => {
        if (typeof document === 'undefined') {
            return undefined;
        }
        const cookies = document.cookie
            .split(';')
            .map((cookie) => cookie.split('='))
            .reduce((acc, [key, value]) => ({ ...acc, [key.trim()]: value }), {});
        switch (cookies[key]) {
            case 'true':
                return true;
            case 'false':
                return false;
            default:
                return undefined;
        }
    },
    setItem: (key: string, value: boolean) => {
        document.cookie = `${key}=${value}`;
    }
};
