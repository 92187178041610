import axios from 'axios';

import { OAuthAccessToken } from '@/cineamo-frontend-lib/models/oauth-access-token/OAuthAccessToken.types';

export async function getConfidentialClientAccessToken(): Promise<OAuthAccessToken> {
    return await axios
        .post(`${process.env.NEXT_PUBLIC_ENV_AUTH_URL}/oauth/token`, {
            grant_type: 'client_credentials',
            client_id: process.env.NEXT_PUBLIC_OAUTH_CLIENT_ID_WEB,
            client_secret: process.env.NEXT_PUBLIC_OAUTH_CLIENT_SECRET_WEB,
            scope: ''
        })
        .then((res) => res?.data);
}
