import React from 'react';

/**
 * Use stringFormatJsx to replace placeholder curly braces like the following example:
 *
 * @example
 * var yourStringWithPlaceholders = "This is your string with a {0} placeholder and another one: {1}"
 *
 * // To replace the two placeholders, do the following:
 * stringFormatJsx(yourStringWithPlaceholders, "placeholderOne", "placeholderTwo");
 *
 * @param str
 * @param params
 */
export function stringFormatJsx(str: string, ...params: (string | JSX.Element)[]): string | JSX.Element {
    const spl = str.split(new RegExp('{[0-9]+}', 'g'));
    return (
        <>
            {spl.map((el, index) => (
                <span key={`span#${index}`}>
                    {el}
                    {params[index]}
                </span>
            ))}
        </>
    );
}

/**
 * Use stringFormat to replace placeholder curly braces like the following example:
 *
 * @example
 * var yourStringWithPlaceholders = "This is your string with a {0} placeholder and another one: {1}"
 *
 * // To replace the two placeholders, do the following:
 * stringFormat(yourStringWithPlaceholders, "placeholderOne", "placeholderTwo");
 *
 * @param str
 * @param params
 */
export function stringFormat(str: string, ...params: string[]): string {
    const spl: string[] = str.split(new RegExp('{[0-9]+}', 'g'));
    return spl
        .map((el, index) => {
            return `${el}${params[index] || ''}`;
        })
        .join('');
}

export enum TrimBy {
    All,
    Words
}

/**
 * Trim a given string to a specified length.
 * If it was trimmed, you can also specify an end-string like '...' (default value).
 * @param text
 * @param length
 * @param trimmedEnd
 * @param trimBy
 * @param callback
 */
export function trimTextToLength(
    text: string,
    length: number,
    trimmedEnd = '...',
    trimBy: TrimBy = TrimBy.All,
    callback?: (timmed: boolean) => void
): string {
    let returnText: string;
    let trimAtCharacterCount: number;

    switch (trimBy) {
        case TrimBy.All:
            trimAtCharacterCount = text.substring(0, length).length;
            break;
        case TrimBy.Words:
            // from 100 word, 50 words <-
            trimAtCharacterCount = text.split(' ', length).join(' ').length;
            break;
    }

    if (text.length > trimAtCharacterCount) {
        returnText = text.substring(0, trimAtCharacterCount);
        returnText += trimmedEnd;
        if (callback) {
            callback(true);
        }
    } else {
        returnText = text;
        if (callback) {
            callback(false);
        }
    }

    return returnText;
}

//TODO add styleFaded to trimTestToLength function...
// <p style={styleFaded(StyleFadeDirection.Bottom, 50)}>{text.slice(0,length)}...</p>
