import { useMediaQuery } from 'react-responsive';

import { tailwindBreakpoints } from '@/src/helper/tailwindConfigHelper';

export const isMobile = typeof window !== 'undefined' && window.innerWidth < 1024;
export const isDesktop = typeof window !== 'undefined' && window.innerWidth >= 1024;

export function useIsMobile(breakpoint: 'lg' | 'md' = 'lg'): boolean {
    const mobileBreakpoint: string = (tailwindBreakpoints as Record<string, string>)?.[breakpoint] || '1024px';
    return useMediaQuery({ query: `(max-width: ${mobileBreakpoint})` });
}

type TailwindSizes = 'sm' | 'md' | 'lg' | 'xl' | '2xl';

export type UseScreenSizeReturn = Record<TailwindSizes | string, boolean>;

export function useScreenSize(): UseScreenSizeReturn {
    const breakpoints: Record<TailwindSizes, string> = tailwindBreakpoints;
    const screenSizeMap: Record<TailwindSizes | string, boolean> = {};
    Object.keys(breakpoints).forEach((breakpoint) => {
        screenSizeMap[breakpoint] = useMediaQuery({ query: `(min-width: ${breakpoints[breakpoint]})` });
    });
    if (!Object.values(screenSizeMap).some((value) => value)) {
        screenSizeMap['sm'] = true;
    }
    return {
        ...screenSizeMap
    };
}
