// TODO: Check if there is another solution for 'const plugin = require()'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const plugin = require('tailwindcss/plugin');

module.exports = {
    mode: 'jit',
    content: [
        './pages/**/*.{js,ts,jsx,tsx}',
        './components/**/*.{js,ts,jsx,tsx}',
        './public/icons/**/*.tsx',
        './src/**/*.tsx',
        'cineamo-frontend-lib/src/**/*.{js,ts,jsx,tsx}',
        '../core/cineamo-frontend-lib/src/**/*.{js,ts,jsx,tsx}'
    ],
    purge: false,
    darkMode: 'media', // or 'media' or 'class'
    presets: [
        require('./tailwind.config/themes.js')
    ],
    variants: {
        padding: ['responsive', 'important'],
        extend: {
            margin: ['first', 'last', 'hover', 'group-hover'],
            padding: ['group-hover'],
            backgroundColor: ['active'],
            backgroundOpacity: ['active'],
            backdropFilter: ['hover'],
            backdropBlur: ['hover'],
            borderStyle: ['first', 'last'],
            borderColor: ['first', 'last'],
            borderRadius: ['first', 'last'],
            borderWidth: ['responsive', 'last', 'hover', 'focus'],
            boxShadow: ['active'],
            translate: ['active'],
            textColor: ['active'],
            opacity: ['active'],
            width: ['hover', 'group-hover'],
            height: ['hover'],
            maxWidth: ['group-hover'],
            maxHeight: ['group-hover'],
            scale: ['group-hover'],
            fill: ['hover', 'focus'],
            zIndex: ['hover', 'group-hover'],
            display: ['group-hover'],
            inset: ['group-hover'],
            rotate: ['group-hover'],
            visibility: ['hover', 'group-hover'],
            fontFamily: ['hover']
        },
    },
    corePlugins: {
        container: false,
    },
    plugins: [
        require('@tailwindcss/forms'),
        require('tailwind-gradient-mask-image'),
        require('@tailwindcss/aspect-ratio'),

        require('./tailwind.config/plugins.js'),

        plugin(function ({ addVariant }) {
            addVariant('important', ({ container }) => {
                container.walkRules(rule => {
                    rule.selector = `.\\!${rule.selector.slice(1)}`;
                    rule.walkDecls(decl => {
                        decl.important = true;
                    });
                });
            });
        }),
    ],
};
