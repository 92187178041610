import classnames from 'classnames';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

import BasicDropdownNew, { BasicDropdownNewOption } from '@/cineamo-frontend-lib/components/ui/input/BasicDropdownNew';
import CookiesModal from '@/cineamo-frontend-lib/components/ui/modal/CookiesModal';
import CenterLayout from '@/cineamo-frontend-lib/components/website/CenterLayout';
import MobileOrDefaultRenderer from '@/components/layouts/MobileOrDefaultRenderer';
import LinkWithStyle from '@/components/ui/link/LinkWithStyle';
import PageFooter from '@/components/ui/page/PageFooter';

import { AvailableLocales, Locale } from '@/cineamo-frontend-lib/constants/locale-constants';

import { cookieStorage } from '@/cineamo-frontend-lib/helper/cookie-helper';
import { formatDate } from '@/cineamo-frontend-lib/helper/date-helper';
import { getLanguageCodeFromLocale, getLocationNameFromLocale } from '@/cineamo-frontend-lib/helper/locale-helper';
import { setPreferredLocale } from '@/cineamo-frontend-lib/helper/preferredLanguageHelper';

import useLocale from '@/cineamo-frontend-lib/hooks/useLocale';

import { IconDown16 } from '@/cineamo-frontend-lib/icons/arrows/ic-down';
import { IconGlobe16 } from '@/cineamo-frontend-lib/icons/ic-globe';

export default function Footer(): JSX.Element {
    const { t } = useTranslation('common');

    const { locale: currentLocale } = useLocale();

    const [privacySettingsModalVisible, setPrivacySettingsModalVisible] = useState<boolean>(true);
    const [isOpenMoreContentFooter, setIsOpenMoreContentFooter] = useState<boolean>(false);

    function showPrivacySettingsModal() {
        setPrivacySettingsModalVisible(!privacySettingsModalVisible);
    }

    const router = useRouter();

    const languageDropdownOptions: BasicDropdownNewOption<Locale>[] = AvailableLocales.map((locale) => ({
        value: locale,
        label: (
            <div className="flex flex-row space-x-6 px-12 py-6">
                <IconGlobe16 className="flex-none" />
                <span className="flex-none">
                    {getLanguageCodeFromLocale(locale).toUpperCase()} - {getLocationNameFromLocale(locale)}
                </span>
            </div>
        )
    }));

    async function onLanguageChange(selectedLocale: Locale) {
        await setPreferredLocale(selectedLocale);
        router
            .replace(
                {
                    pathname: router.pathname,
                    query: router.query
                },
                undefined,
                { locale: getLanguageCodeFromLocale(selectedLocale) }
            )
            .then();
    }

    const largeFooterToggle = () => {
        return (
            <div
                className="flex flex-row flex-none my-8 xl:my-0 space-x-8 items-center clickable-element"
                onClick={() => setIsOpenMoreContentFooter(!isOpenMoreContentFooter)}>
                <span className="flex-none">{t('static-footer.more-content')}</span>
                <IconDown16
                    className={classnames('transition-all duration-150', {
                        'rotate-180': isOpenMoreContentFooter
                    })}
                />
            </div>
        );
    };

    const languageDropdown = () => {
        return (
            <BasicDropdownNew
                className="h-28"
                openingDirection="up"
                height="custom"
                preselectedIndex={languageDropdownOptions.findIndex((it) => it.value === currentLocale)}
                style={{
                    listBgColorClass: 'bg-violetGrey'
                }}
                options={languageDropdownOptions}
                onChange={(selectedItem) => {
                    onLanguageChange(selectedItem.value).then();
                }}
            />
        );
    };

    type StaticFooterItemProps = {
        children?: React.ReactNode;
        href?: string;
        onClick?: () => void;
    };

    function StaticFooterItem(props: StaticFooterItemProps) {
        return (
            <LinkWithStyle
                className="my-8 xl:my-0 flex-auto clickable-element"
                href={props.href}
                onClick={props.onClick}>
                {props.children}
            </LinkWithStyle>
        );
    }

    useEffect(() => {
        if (cookieStorage.getItem('__essential_cookies_initial_opt_in')) {
            setPrivacySettingsModalVisible(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <footer className="w-full flex-none text-body-12 p-default-screen">
            <CookiesModal
                modalIsOpen={privacySettingsModalVisible}
                setModalIsOpen={setPrivacySettingsModalVisible}
                t={t}
            />

            <div className="w-full flex-none relative flex flex-col">
                <div
                    className={classnames(
                        'flex-none w-full transition-all duration-150 overflow-hidden',
                        { 'max-h-0': !isOpenMoreContentFooter },
                        { 'max-h-screen': isOpenMoreContentFooter }
                    )}>
                    <PageFooter />
                </div>

                <div className="w-full -mt-2 border-b-2 border-white border-opacity-20" />

                <CenterLayout className="flex flex-col xl:flex-row justify-between text-body-12 xl:space-x-16 my-16">
                    <MobileOrDefaultRenderer mobileContent={<>{languageDropdown()}</>} />

                    <span
                        className={classnames(
                            'flex-none self-center',
                            'order-1 xl:order-0',
                            'mt-24 xl:mt-0',
                            'text-center xl:text-left'
                        )}>
                        &copy; Cineamo {formatDate(new Date(), undefined, { year: 'numeric' })}
                    </span>

                    <div
                        className={classnames(
                            'relative items-center',
                            'order-0 xl:order-1',
                            'grid',
                            'grid-cols-2 sm:grid-cols-3 lg:grid-cols-4',
                            'xl:flex xl:flex-wrap',
                            'space-x-0 xl:space-x-24',
                            'pt-8'
                        )}>
                        <StaticFooterItem href={'/contact'}>{t('static-footer.contact')}</StaticFooterItem>

                        <StaticFooterItem href="/imprint">{t('static-footer.imprint')}</StaticFooterItem>

                        <StaticFooterItem href="/data-protection">{t('static-footer.data-security')}</StaticFooterItem>

                        <StaticFooterItem onClick={showPrivacySettingsModal}>
                            {t('static-footer.privacy-settings')}
                        </StaticFooterItem>

                        <StaticFooterItem href="/libraries">{t('static-footer.libraries')}</StaticFooterItem>

                        {largeFooterToggle()}

                        <div className="flex-none hidden lg:block">{languageDropdown()}</div>
                    </div>
                </CenterLayout>
            </div>
        </footer>
    );
}
