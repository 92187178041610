const plugin = require('tailwindcss/plugin')

const screenBreakPoints = plugin (
    ({
         addComponents,
         theme
     }) => {
        const containerBreakpoints = {
            '.container': {
                paddingInline: theme('spacing.4'),
                // maxWidth: theme('screens.lg'),
                paddingLeft: '0.9rem',
                paddingRight: '0.9rem',
                width: '100%',

                // Breakpoints
                '@screen sm': {
                    // maxWidth: theme('screens.sm'),
                    paddingLeft: '3.8rem',
                    paddingRight: '3.8rem',
                },
                '@screen md': {
                    // maxWidth: theme('screens.md'),
                    paddingLeft: '3.8rem',
                    paddingRight: '3.8rem',
                },
                '@screen lg': {
                    // maxWidth: theme('screens.lg'),
                    paddingLeft: '4.8rem',
                    paddingRight: '4.8rem',
                },
                '@screen xl': {
                    // maxWidth: theme('screens.xl'),
                    paddingLeft: '4.8rem',
                    paddingRight: '4.8rem',
                },
                '@screen 2xl': {
                    maxWidth: theme('screens.2xl'),
                    paddingLeft: '4.8rem',
                    paddingRight: '4.8rem',
                }
            }
        };
        const screenPaddingBreakpoints = {
            '.screen-padding': {
                paddingLeft: '1.4rem',
                paddingRight: '1.4rem',

                '@screen sm': {
                    paddingLeft: '3.8rem',
                    paddingRight: '3.8rem',
                },
                '@screen md': {
                    paddingLeft: '3.8rem',
                    paddingRight: '3.8rem',
                },
                '@screen lg': {
                    paddingLeft: '4.8rem',
                    paddingRight: '4.8rem',
                },
                '@screen xl': {
                    paddingLeft: '4.8rem',
                    paddingRight: '4.8rem',
                },
                '@screen 2xl': {
                    paddingLeft: '4.8rem',
                    paddingRight: '4.8rem',
                }
            }
        };
        const modalBreakpoints = {
            '.modal-window': {
                paddingInline: theme('spacing.4'),
                maxWidth: '100%',
                paddingLeft: '1.6rem',
                paddingRight: '1.6rem',
                width: '100%',

                // Breakpoints
                '@screen sm': {
                    maxWidth: '550px',
                    paddingLeft: '2.8rem',
                    paddingRight: '2.8rem',
                },
                '@screen md': {
                    maxWidth: '600px',
                    paddingLeft: '3.8rem',
                    paddingRight: '3.8rem',
                },
                '@screen lg': {
                    maxWidth: '700px',
                    paddingLeft: '4.8rem',
                    paddingRight: '4.8rem',
                }
            }
        };

        addComponents([
            containerBreakpoints,
            screenPaddingBreakpoints,
            modalBreakpoints]);
    },
)

module.exports = screenBreakPoints