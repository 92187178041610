import { ReactNode, createContext, useContext, useEffect, useState } from 'react';

import BannerContainer, { BannerContainerProps } from '@/components/ui/banner/BannerContainer';

const BannerContext = createContext({
    banner: undefined,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    show: function (_banner: BannerContainerProps) {
        // placeholder
    },
    hide: function () {
        // placeholder
    }
});

export type BannerContextProviderProps = {
    children?: ReactNode;
};

export function BannerContextProvider(props: BannerContextProviderProps): JSX.Element {
    const [banner, setBanner] = useState<BannerContainerProps>(null);

    function show(banner: BannerContainerProps) {
        setBanner(banner);
    }

    function hide() {
        setBanner(null);
    }

    const context = {
        banner: banner,
        show: show,
        hide: hide
    };

    return (
        <BannerContext.Provider value={context}>
            {banner && <BannerContainer {...banner} />}
            {props.children}
        </BannerContext.Provider>
    );
}

banner.show = (banner: BannerContainerProps) => {
    const bannerContext = useContext(BannerContext);
    useEffect(() => {
        bannerContext.show(banner);
        return () => {
            bannerContext.hide();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

export function banner(): BannerContainerProps {
    return useContext(BannerContext).banner;
}

export function useBanner(): { show?: (banner: BannerContainerProps) => void; hide?: () => void } {
    const banner = useContext(BannerContext);
    return { show: banner.show, hide: banner.hide };
}

export default BannerContext;
