import { useEffect, useState } from 'react';

export type SwitchProps = {
    className?: string;
    state?: boolean;
    disabled?: boolean;
    defaultText?: string;
    offText?: string;
    onText?: string;
    onChange?: (state) => void;
    onColour?: string;
    offColour?: string;
    switchColour?: string;
    stateTextLeft?: boolean;
};

function Switch(props: SwitchProps): JSX.Element {
    const {
        className,
        state,
        disabled,
        defaultText,
        offText,
        onText,
        onChange,
        onColour,
        offColour,
        switchColour,
        stateTextLeft
    } = props;

    const [switchState, setSwitchState] = useState<boolean>(state || false);

    const _defaultText = defaultText || '';
    const _offText = offText || '';
    const _onText = onText || '';
    const _onColour = onColour || 'bg-darkBlue';
    const _offColour = offColour || 'bg-gray';
    const _switchColour = switchColour || 'bg-white';

    useEffect(() => {
        setSwitchState(state);
    }, [state]);

    function toggleSwitch() {
        if (!disabled) {
            const newState = !switchState;
            setSwitchState(newState);
            if (onChange && typeof onChange === 'function') {
                onChange(newState);
            }
        }
    }

    return (
        <div
            className={`flex flex-row gap-8 items-center ${className || ''} ${
                stateTextLeft ? 'flex-row-reverse' : ''
            }`}>
            <span
                className={`w-50 h-24 rounded-switch overflow-hidden px-0 z-0 flex transform duration-200 ${
                    disabled ? 'cursor-default' : 'cursor-pointer'
                } ${switchState ? `${_onColour}` : `${_offColour}`}`}
                onClick={toggleSwitch}>
                <span
                    className={`w-20 h-20 ${_switchColour} box-content shadow-darkBlue-20 rounded-full my-auto relative inline-block transform duration-200`}
                    style={{
                        left: switchState ? 'calc(100% - 2.2rem)' : '0.2rem'
                    }}
                />
            </span>
            <span className="inline-block justify-center select-none">
                {switchState ? _onText || _defaultText : _offText || _defaultText}
            </span>
        </div>
    );
}

export default Switch;
