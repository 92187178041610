import { useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import LoginTargetButton from '@/cineamo-frontend-lib/components/ui/buttons/LoginTargetButton';
import Modal, { ModalProps } from '@/cineamo-frontend-lib/components/ui/modal/Modal';

import { UserMeDto } from '@/cineamo-frontend-lib/models/user/UserMeDto.types';

import { ADMIN_URL, CINEMA_URL, DISTRIBUTOR_URL } from '@/cineamo-frontend-lib/constants/http-constants';
import { Role } from '@/cineamo-frontend-lib/constants/role-constants';

import { getMe } from '@/cineamo-frontend-lib/api/user/user-api';

import { getUserTypeByRoles } from '@/cineamo-frontend-lib/helper/user-helper';
import { stringFormat } from '@/src/helper/stringHelper';

import { IconAdminApp } from '@/cineamo-frontend-lib/icons/app-icon/ic-appiconAdminApp';
import { IconCinemaApp } from '@/cineamo-frontend-lib/icons/app-icon/ic-appiconCinemaApp';
import { IconDistributorApp } from '@/cineamo-frontend-lib/icons/app-icon/ic-appiconDistributorApp';
import { IconWebApp } from '@/cineamo-frontend-lib/icons/app-icon/ic-appiconWebApp';

export type SelectLoginTargetModalProps = {
    user: UserMeDto;
} & ModalProps;

export default function SelectLoginTargetModal(props: SelectLoginTargetModalProps): JSX.Element {
    const { t } = useTranslation('common');

    const { data: me } = useQuery(['me'], async () => {
        return await getMe();
    });

    const { user } = props;

    const userType = getUserTypeByRoles(me?.roles);
    const isRegularUser = userType.includes(Role.ROLE_USER);
    const isAdminUser = userType.includes(Role.ROLE_ADMIN);
    const isCinemaUser = userType.includes(Role.ROLE_CINEMA);
    const isDistributorUser = userType.includes(Role.ROLE_DISTRIBUTOR);

    const [adminAppUrl, setAdminAppUrl] = useState<string>();
    const [cinemaAppUrl, setCinemaAppUrl] = useState<string>();
    const [distributorAppUrl, setDistributorAppUrl] = useState<string>();

    function buildTargetUrl(url: string): string {
        const targetUrl = new URL(url);
        targetUrl.searchParams.set('authentication', 'successful');
        return targetUrl.toString();
    }

    useEffect(() => {
        setAdminAppUrl(buildTargetUrl(ADMIN_URL));
        setCinemaAppUrl(buildTargetUrl(CINEMA_URL));
        setDistributorAppUrl(buildTargetUrl(DISTRIBUTOR_URL));
    }, []);

    if (isRegularUser) {
        return null;
    }

    return (
        <Modal
            title={stringFormat(t('welcome-back-user'), user?.username || '')}
            onClose={null}
            {...props}>
            <div className="flex flex-col w-full space-y-16">
                <span>{t('what-would-you-like-to-do')}</span>
                <div className="flex flex-col w-full space-y-16">
                    <LoginTargetButton
                        icon={<IconWebApp />}
                        label={t('remain-on-website')}
                        onClick={() => props.setModalIsOpen(false)}
                    />
                    {isAdminUser && (
                        <LoginTargetButton
                            icon={<IconAdminApp />}
                            label={t('go-to-admin-app')}
                            href={adminAppUrl}
                            onClick={() => props.setModalIsOpen(false)}
                        />
                    )}
                    {(isDistributorUser || isAdminUser) && (
                        <LoginTargetButton
                            icon={<IconDistributorApp />}
                            label={t('go-to-distributor-app')}
                            href={distributorAppUrl}
                            onClick={() => props.setModalIsOpen(false)}
                        />
                    )}
                    {(isCinemaUser || isAdminUser) && (
                        <LoginTargetButton
                            icon={<IconCinemaApp />}
                            label={t('go-to-cinema-app')}
                            href={cinemaAppUrl}
                            onClick={() => props.setModalIsOpen(false)}
                        />
                    )}
                </div>
            </div>
        </Modal>
    );
}
