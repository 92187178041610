import { ReactNode } from 'react';

export type HorizontalViewProps = {
    children?: ReactNode;
    className?: string;
};

function HorizontalView(props: HorizontalViewProps): JSX.Element {
    const { className } = props;

    return <div className={`flex flex-wrap sm:flex-nowrap flex-row ${className}`}>{props.children}</div>;
}

export default HorizontalView;
