import { CinemaState } from '@/store/cinema/cinemaStore.types';

// KEY for cinemas in browser storage
const CINEMA_BROWSER_STORAGE_KEY = 'cineamo-cinemas-browser-storage';

// Reuse CinemaState from store added lastUpdated
type CinemaStateBrowserStorage = {
    lastUpdated: Date;
} & CinemaState;

type CinemasBrowserStorageReturn = {
    getCinemaStateFromBrowserStorage: () => CinemaStateBrowserStorage;
    setCinemaStateInBrowserStorage: (state: CinemaState) => void;
};

export function useCinemasBrowserStorage(): CinemasBrowserStorageReturn {
    function getCinemaStateFromBrowserStorage(): CinemaStateBrowserStorage {
        const cinemasFromBrowserStorageString =
            typeof document !== 'undefined' ? localStorage.getItem(CINEMA_BROWSER_STORAGE_KEY) : '';
        return cinemasFromBrowserStorageString
            ? JSON.parse(cinemasFromBrowserStorageString)
            : {
                  selectedCinemas: [],
                  favoriteCinemas: [],
                  cinemasNearby: []
              };
    }
    function setCinemaStateInBrowserStorage(cinemaState: CinemaState) {
        const cinemaStateWithLastUpdated = {
            lastUpdated: new Date(),
            ...cinemaState
        };
        localStorage.setItem(CINEMA_BROWSER_STORAGE_KEY, JSON.stringify(cinemaStateWithLastUpdated));
    }

    return {
        getCinemaStateFromBrowserStorage: getCinemaStateFromBrowserStorage,
        setCinemaStateInBrowserStorage: setCinemaStateInBrowserStorage
    };
}
