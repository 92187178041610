import {CustomSVGProps} from '../../types/CustomSVGProps.types';

export function IconWebApp(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="325" height="325" viewBox="0 0 325 325" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 80C0 35.8172 35.8172 0 80 0H245C289.183 0 325 35.8172 325 80V245C325 289.183 289.183 325 245 325H80C35.8172 325 0 289.183 0 245V80Z"
                fill="#000023"/>
            <path
                d="M0 80C0 35.8172 35.8172 0 80 0H245C289.183 0 325 35.8172 325 80V245C325 289.183 289.183 325 245 325H80C35.8172 325 0 289.183 0 245V80Z"
                fill="url(#paint0_radial_1337_8590)" fillOpacity="0.9"/>
            <path
                d="M0 80C0 35.8172 35.8172 0 80 0H245C289.183 0 325 35.8172 325 80V245C325 289.183 289.183 325 245 325H80C35.8172 325 0 289.183 0 245V80Z"
                fill="url(#paint1_radial_1337_8590)" fillOpacity="0.9"/>
            <path
                d="M0 80C0 35.8172 35.8172 0 80 0H245C289.183 0 325 35.8172 325 80V245C325 289.183 289.183 325 245 325H80C35.8172 325 0 289.183 0 245V80Z"
                fill="url(#paint2_radial_1337_8590)" fillOpacity="0.9"/>
            <path
                d="M0 80C0 35.8172 35.8172 0 80 0H245C289.183 0 325 35.8172 325 80V245C325 289.183 289.183 325 245 325H80C35.8172 325 0 289.183 0 245V80Z"
                fill="url(#paint3_radial_1337_8590)" fillOpacity="0.9"/>
            <path
                d="M0 80C0 35.8172 35.8172 0 80 0H245C289.183 0 325 35.8172 325 80V245C325 289.183 289.183 325 245 325H80C35.8172 325 0 289.183 0 245V80Z"
                fill="url(#paint4_radial_1337_8590)" fillOpacity="0.9"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M245 5H80C38.5786 5 5 38.5786 5 80V245C5 286.421 38.5786 320 80 320H245C286.421 320 320 286.421 320 245V80C320 38.5786 286.421 5 245 5ZM80 0C35.8172 0 0 35.8172 0 80V245C0 289.183 35.8172 325 80 325H245C289.183 325 325 289.183 325 245V80C325 35.8172 289.183 0 245 0H80Z"
                  fill="#C2C2C2"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M136.98 59.4698C128.918 59.5385 122.405 66.0947 122.405 74.1728V111.508C122.405 101.097 132.927 93.9848 142.588 97.8643L158.281 104.168L267.73 149.581C271.614 151.18 275.324 146.96 273.239 143.314L229.561 66.8776C226.965 62.3345 222.153 59.5165 216.928 59.4698H136.98Z"
                  fill="url(#paint5_linear_1337_8590)"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M116.101 59.4698C110.876 59.5165 106.064 62.3345 103.467 66.8769L96.4975 79.0746L53.0564 155.098C50.473 159.617 50.473 165.167 53.0564 169.687L90.1067 234.377C93.7769 240.8 101.649 243.496 108.487 240.67L132.109 230.91L141.259 227.151C132.002 229.909 122.405 222.997 122.405 213.05V191.799V74.1728C122.405 66.0947 128.918 59.5385 136.98 59.4698H116.101Z"
                  fill="url(#paint6_linear_1337_8590)"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M273.24 181.471L229.561 257.907C226.943 262.488 222.072 265.315 216.795 265.315H116.234C110.957 265.315 106.086 262.489 103.468 257.907L96.4973 245.71L90.1064 234.377C93.7767 240.801 101.65 243.496 108.487 240.67L132.109 230.91L267.73 175.204C271.614 173.604 275.323 177.825 273.24 181.471Z"
                  fill="#00FFDC"/>
            <defs>
                <radialGradient id="paint0_radial_1337_8590" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(315.467 328.002) rotate(-116.343) scale(361.321 583.749)">
                    <stop stopColor="#3815C2"/>
                    <stop offset="1" stopColor="#3815C2" stopOpacity="0.49"/>
                </radialGradient>
                <radialGradient id="paint1_radial_1337_8590" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(55.7465 270.569) rotate(-37.7276) scale(205.597 344.322)">
                    <stop offset="0.345246" stopColor="#18185F"/>
                    <stop offset="1" stopColor="#1E1E41" stopOpacity="0"/>
                </radialGradient>
                <radialGradient id="paint2_radial_1337_8590" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(316.767 5.2032) rotate(42.1729) scale(440.863 281.043)">
                    <stop stopColor="#29D798"/>
                    <stop offset="0.308137" stopColor="#29D798" stopOpacity="0.61"/>
                    <stop offset="1" stopColor="#18AB76" stopOpacity="0"/>
                </radialGradient>
                <radialGradient id="paint3_radial_1337_8590" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(37.0703 3.147) rotate(56.6834) scale(335.399 415.262)">
                    <stop stopColor="#610769"/>
                    <stop offset="0.156083" stopColor="#5A0662" stopOpacity="0.781367"/>
                    <stop offset="1" stopColor="#410247" stopOpacity="0"/>
                </radialGradient>
                <radialGradient id="paint4_radial_1337_8590" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(162.5 237.878) rotate(-86.8059) scale(141.773 143.432)">
                    <stop stopColor="#35008F" stopOpacity="0.47"/>
                    <stop offset="1" stopColor="#35008F" stopOpacity="0"/>
                </radialGradient>
                <linearGradient id="paint5_linear_1337_8590" x1="324.796" y1="86.3271" x2="227.839" y2="-22.9059"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00FFDC"/>
                    <stop offset="0.490223" stopColor="#00FFDC"/>
                    <stop offset="1" stopColor="#00AAA0"/>
                </linearGradient>
                <linearGradient id="paint6_linear_1337_8590" x1="52.7222" y1="59.4698" x2="52.7222" y2="235.301"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00FFDC"/>
                    <stop offset="0.509799" stopColor="#00FFDC"/>
                    <stop offset="1" stopColor="#00AAA0"/>
                </linearGradient>
            </defs>
        </svg>
    );
}