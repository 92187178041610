import { AxiosRequestConfig } from 'axios';

import { UserDto } from '../../models/user/UserDto.types';
import { UserInputDto } from '../../models/user/UserInputDto.types';
import {
    UserInviteCinemaInputDto,
    UserInviteDistributorInputDto,
    UserInviteInputDto
} from '../../models/user/UserInviteInputDto.types';
import { UserMeDto } from '../../models/user/UserMeDto.types';
import { UserMeInputDto } from '../../models/user/UserMeInputDto.types';

import { Role } from '../../constants/role-constants';

import { UrlQueryParameter, buildUrlQuery } from '../../helper/url-query-helper';

import { ApiErrorResponse } from '../ApiErrorResponse.types';
import { UserListResponse } from '../ApiListResponse.types';
import { ApiResponse } from '../ApiResponse.types';
import apiClient from '../cineamoApiClient';

// <------------------------ Me ------------------------>

/**
 * This endpoint returns a user.
 *
 * @example
 * // Get the user that is currently logged-in:
 * getMe();
 *
 * @returns {ApiResponse<UserMeDto>}
 */

export function getMe(config?: AxiosRequestConfig): Promise<ApiResponse<UserMeDto>> {
    return apiClient
        .get<ApiResponse<UserMeDto>>('/me', config)
        .then<UserMeDto>((res) => {
            return res.data;
        })
        .catch(() => null);
}

// <------------------------ Update Me ------------------------>

/**
 * This endpoint returns a user.
 *
 * @example
 * // Update the user that is currently logged-in:
 * updateMe({...data});
 *
 * @param data
 * @returns {ApiResponse<UserMeDto> | ApiErrorResponse<UserMeDto>}
 */

export function updateMe(data: UserMeInputDto): Promise<ApiResponse<UserMeDto> | ApiErrorResponse<UserMeInputDto>> {
    return apiClient
        .patch('/me', data)
        .then((res) => res.data)
        .catch((error) => error);
}

// <------------------------ User ------------------------>

/**
 * This endpoint returns a user.
 *
 * @example
 * // Get a user by id:
 * getUserById(1);
 *
 * @param id
 * @returns {ApiResponse<UserDto>}
 */

export async function getUserById(id: number | string): Promise<ApiResponse<UserDto>> {
    return apiClient
        .get(`/users/${id}`)
        .then((response) => response.data)
        .catch(() => {
            return null;
        });
}

// <------------------------ Users ------------------------>

export type GetUsersParams = UrlQueryParameter & {
    isActive?: boolean;
    employerDistributor?: string;
    employerCinema?: number | string;
    favoriteCinema?: number | string;
};

/**
 * This endpoint returns a list of users.
 *
 * @example
 * // Get a users according to query:
 * getUsers({isActive: true});
 *
 * @param params
 * @returns {ApiResponse<UserListResponse>}
 */

export async function getUsers(params?: GetUsersParams): Promise<ApiResponse<UserListResponse>> {
    return apiClient
        .get<ApiResponse<UserListResponse>>(`/users${buildUrlQuery(params)}`)
        .then((res) => res.data)
        .catch(() => {
            return null;
        });
}

// <------------------------ Create User ------------------------>

/**
 * This endpoint creates and returns a user.
 *
 * @example
 * // Create a user:
 * createUser({...data});
 *
 * @param data
 * @param options
 * @returns {ApiResponse<UserDto> | ApiErrorResponse<UserInputDto>}
 */

export async function createUser(
    data: UserInputDto,
    options?: { confidentialClientAccessToken?: string }
): Promise<ApiResponse<UserDto> | ApiErrorResponse<UserInputDto>> {
    return await apiClient
        .post('/users', data, {
            headers: options.confidentialClientAccessToken && {
                Authorization: 'Bearer ' + options.confidentialClientAccessToken
            }
        })
        .then((res) => res.data)
        .catch((error) => error);
}

// <------------------------ Delete User ------------------------>

/**
 * This endpoint returns a boolean.
 *
 * @example
 * // Delete user by id:
 * deleteUser(1);
 *
 * @param userId
 * @param options
 * @returns {boolean}
 */

export async function deleteUser(
    userId: number | string,
    options?: { confidentialClientAccessToken?: string }
): Promise<boolean> {
    const config: AxiosRequestConfig = {};
    if (options?.confidentialClientAccessToken) {
        config.headers = {
            Authorization: 'Bearer ' + options.confidentialClientAccessToken
        };
    }
    return apiClient
        .delete<boolean>(`/users/${userId}`, config)
        .then((res) => res.status === 204)
        .catch(() => {
            return false;
        });
}

// <------------------------ Update User ------------------------>

/**
 * This endpoint updates a user by id.
 *
 * @example
 * // Update a user:
 * updateUser({...data});
 *
 * @param id
 * @param data
 * @param options
 * @returns {ApiResponse<UserInputDto> | ApiErrorResponse<UserDto>}
 */

export function updateUser(
    id: number | string,
    data: UserInputDto,
    options?: { confidentialClientAccessToken?: string }
): Promise<ApiResponse<UserDto> | ApiErrorResponse<UserInputDto>> {
    const config: AxiosRequestConfig = {};
    if (options?.confidentialClientAccessToken) {
        config.headers = {
            Authorization: 'Bearer ' + options.confidentialClientAccessToken
        };
    }
    return apiClient
        .patch(`/users/${id}`, data, config)
        .then((res) => res.data)
        .catch((error) => error);
}

export async function setCinemaEmployee(
    userId: number | string,
    cinemaId: number | string
): Promise<ApiResponse<UserDto> | ApiErrorResponse> {
    return apiClient
        .put(`/users/${userId}/cinema-employees/${cinemaId}`)
        .then((response) => response.data)
        .catch((error) => error);
}

export async function deleteCinemaEmployee(
    userId: number | string,
    cinemaId: number | string
): Promise<ApiResponse<UserDto> | ApiErrorResponse> {
    return apiClient
        .delete(`/users/${userId}/cinema-employees/${cinemaId}`)
        .then((response) => response.data)
        .catch((error) => error);
}

export async function inviteCinemaUser(
    invitedCinemaUser: UserInviteCinemaInputDto
): Promise<ApiResponse<UserInviteCinemaInputDto> | ApiErrorResponse<UserInviteCinemaInputDto>> {
    return apiClient
        .post('/users/invite-cinema', invitedCinemaUser)
        .then((response) => response.data)
        .catch((error) => error);
}

export async function inviteDistributorUser(
    invitedDistributorUser: UserInviteDistributorInputDto
): Promise<ApiResponse<UserInviteDistributorInputDto> | ApiErrorResponse<UserInviteDistributorInputDto>> {
    return apiClient
        .post('/users/invite-distributor', invitedDistributorUser)
        .then((response) => response.data)
        .catch((error) => error);
}

export async function inviteAdminUser(
    inviteAdminUser: UserInviteInputDto
): Promise<ApiResponse<UserInviteInputDto> | ApiErrorResponse<UserInviteInputDto>> {
    return apiClient
        .post('/users/invite-admin', inviteAdminUser)
        .then((response) => response.data)
        .catch((error) => error);
}

export async function resendInvitation(
    userId: number | string
): Promise<ApiResponse<UserDto> | ApiErrorResponse<UserInputDto>> {
    return apiClient
        .post(`/users/${userId}/resend-invitation`)
        .then((response) => response.data)
        .catch((error) => error);
}

export async function resendActivation(
    userId: number | string
): Promise<ApiResponse<UserDto> | ApiErrorResponse<UserInputDto>> {
    return apiClient
        .post(`/users/${userId}/resend-activation`)
        .then((response) => response.data)
        .catch((error) => error);
}

export async function setUserRole(
    userId: number | string,
    roleId: Role
): Promise<ApiResponse<UserDto> | ApiErrorResponse> {
    return apiClient
        .put(`/users/${userId}/roles/${roleId}`)
        .then((response) => response.data)
        .catch((error) => error);
}

export async function deleteUserRole(
    userId: number | string,
    roleId: Role
): Promise<ApiResponse<UserDto> | ApiErrorResponse> {
    return apiClient
        .delete(`/users/${userId}/roles/${roleId}`)
        .then((response) => response)
        .catch((error) => error);
}
