export default {
    defaultTitle: 'Cineamo',
    // titleTemplate: 'Cineamo - %s', no need for pages like cinema program, in fact it makes is worse.
    // todo: @simon check if it makes it worse on other sites...
    openGraph: {
        type: 'website',
        locale: 'de',
        url: 'https://www.cineamo.com',
        site_name: 'Cineamo',
    },
};
