import classnames from 'classnames';
import { TFunction } from 'i18next';
import { signIn } from 'next-auth/react';
import { useTranslation } from 'next-i18next';
import { Link, translateUrl } from 'next-translate-routes';
import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import Button, { ButtonStyle } from '@/cineamo-frontend-lib/components/ui/buttons/Button';
import MenuButtonWithItems from '@/cineamo-frontend-lib/components/ui/buttons/MenuButtonWithItems';
import MovieSearchButton from '@/components/navigation/MovieSearchButton';
import LinkWithStyle from '@/components/ui/link/LinkWithStyle';

import logoutUser from '@/src/helper/LogOutHelper';

import useClickOutside from '@/cineamo-frontend-lib/hooks/useClickOutside';

import { useUserStore } from '@/src/../store/user/userStore';

import { IconDown24 } from '@/cineamo-frontend-lib/icons/arrows/ic-down';
import { IconFilmSerie16 } from '@/cineamo-frontend-lib/icons/categories/ic-filmSerie';
import { IconNewRequest16 } from '@/cineamo-frontend-lib/icons/ic-newRequest';
import { IconRingingBell16 } from '@/cineamo-frontend-lib/icons/ic-ringingBell';
import { IconSeat16 } from '@/cineamo-frontend-lib/icons/ic-seat';
import { IconClose24 } from '@/cineamo-frontend-lib/icons/menu/ic-close';
import { IconMenu24 } from '@/cineamo-frontend-lib/icons/menu/ic-menu';
import { IconSettingsCog16 } from '@/cineamo-frontend-lib/icons/menu/ic-settings-cog';
import { IconMenuUnread24 } from '@/cineamo-frontend-lib/icons/menu/ic_menu_unread';

export type MobileMenuContentProps = {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    t: TFunction;
    toggleMenu: () => void;
    menuItems: {
        default: MenuItem[];
        bottom?: BottomMenuItem[];
    };
    locale?: string;
};

const MobileMenuContent = (props: MobileMenuContentProps) => {
    const { isOpen, setIsOpen, t, toggleMenu, menuItems, locale } = props;

    const [submenu, setSubmenu] = useState(-1);

    const { user, logout } = useUserStore();

    const { ref: menuRef } = useClickOutside(onClickOutside);

    function onClickOutside() {
        setIsOpen(false);
    }

    const toggleSubmenu = (submenuId, titleHref?: string) => () => {
        if (submenuId === submenu) {
            if (titleHref) {
                toggleMenu();
                return setSubmenu(-1);
            }
            return setSubmenu(-1);
        }

        setSubmenu(submenuId);
    };

    type SingleLinkItemProps = {
        href: string;
        idx: number;
        exactTitle: boolean;
        title: string;
        onClick: () => void;
        leadingIcon?: JSX.Element;
    };
    const SingleLinkItem = (props: SingleLinkItemProps) => {
        const link = (
            <a
                className="text-black py-8 font-montserrat-regular text-body-14 flex px-16 cursor-pointer items-center space-x-12"
                onClick={() => {
                    if (props.onClick) {
                        props.onClick();
                    }
                    setIsOpen(false);
                    toggleMenu();
                }}>
                {props.leadingIcon}
                <span>{props.exactTitle ? props.title : t(props.title)}</span>
            </a>
        );

        return (
            <React.Fragment key={`mainNavItem#${props.idx}`}>
                {props.onClick ? link : <Link href={props.href}>{link}</Link>}
            </React.Fragment>
        );
    };

    return (
        <>
            <div
                className={classnames('fixed w-screen h-screen left-0 top-0 z-dropdown bg-overlay transition-opacity', {
                    'opacity-0 invisible': !isOpen,
                    'opacity-100 visible': isOpen
                })}
                onClick={toggleMenu}
            />
            <div
                className={classnames(
                    'fixed flex flex-col justify-between h-screen z-dropdown pt-5 pb-24 w-260 top-0 left-0 bg-white rounded-r-4 transform transition-transform duration-200 ease-out overflow-y-auto',
                    {
                        '-translate-x-full': !isOpen,
                        'translate-x-0': isOpen
                    }
                )}
                ref={menuRef}>
                <div>
                    {user &&
                        menuItems.default.map(
                            ({ title, titleHref, exactTitle, href, onClick, items, leadingIcon }, index) =>
                                href || onClick ? (
                                    <SingleLinkItem
                                        key={index}
                                        idx={index}
                                        href={href}
                                        onClick={onClick}
                                        title={title}
                                        exactTitle={exactTitle}
                                        leadingIcon={leadingIcon}
                                    />
                                ) : (
                                    <React.Fragment key={`mainNavItem#${index}`}>
                                        <div
                                            className="text-black py-6 font-montserrat-regular text-body-14"
                                            onClick={toggleSubmenu(index, titleHref)}>
                                            <div>
                                                {index === submenu && (
                                                    <LinkWithStyle
                                                        className="flex items-center justify-between cursor-pointer px-16"
                                                        href={titleHref}>
                                                        <span>{t(title)}</span>
                                                        <IconDown24
                                                            className={classnames('transform duration-200', {
                                                                '-rotate-180': index === submenu
                                                            })}
                                                            stroke={'black'}
                                                            height="16"
                                                            width="16"
                                                        />
                                                    </LinkWithStyle>
                                                )}
                                                {index !== submenu && (
                                                    <div className="flex items-center justify-between cursor-pointer px-16">
                                                        <span>{t(title)}</span>
                                                        <IconDown24
                                                            className={classnames('transform duration-200', {
                                                                '-rotate-180': index === submenu
                                                            })}
                                                            stroke={'black'}
                                                            height="16"
                                                            width="16"
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                            {index === submenu && (
                                                <div className="mt-8">
                                                    {items.map(({ title, items }, index) => (
                                                        <div
                                                            key={`submenuItem#${index}`}
                                                            className="pt-24 px-32 bg-darkBlue bg-opacity-5">
                                                            <h3 className="text-darkBlue font-montserrat-semibold text-body-14 mb-3">
                                                                {t(title)}
                                                            </h3>
                                                            {items.map(({ title, href }, index) => (
                                                                <LinkWithStyle
                                                                    href={href}
                                                                    key={`submenuItemItem#${index}`}>
                                                                    <a className="block py-8">{t(title)}</a>
                                                                </LinkWithStyle>
                                                            ))}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </React.Fragment>
                                )
                        )}
                    <div className="space-y-16 px-16 my-8">
                        {!user ? (
                            <>
                                <div>
                                    <LinkWithStyle onClick={() => signIn('cineamo')}>
                                        <Button
                                            className="w-full"
                                            style={ButtonStyle.default_on_white_bg}>
                                            {t('login')}
                                        </Button>
                                    </LinkWithStyle>
                                </div>
                                <div>
                                    <LinkWithStyle href="/registration">
                                        <Button
                                            className="w-full clickable-element"
                                            style={ButtonStyle.ghost}>
                                            {t('join-us')}
                                        </Button>
                                    </LinkWithStyle>
                                </div>
                            </>
                        ) : (
                            <div>
                                <LinkWithStyle onClick={() => logoutUser(locale, logout)}>
                                    <Button className="w-full ">
                                        {user ? `${t('log-out')} ${user.username}` : t('log-out')}
                                    </Button>
                                </LinkWithStyle>
                            </div>
                        )}
                    </div>
                </div>
                <div>
                    {menuItems.bottom &&
                        menuItems.bottom.map(({ title, href }) => (
                            <LinkWithStyle
                                href={href}
                                key={title}>
                                <a className="text-black py-8 font-montserrat-regular text-body-14 flex px-16">
                                    {t(title)}
                                </a>
                            </LinkWithStyle>
                        ))}
                </div>
            </div>
        </>
    );
};

const MobileMenuPortal = (props: MobileMenuContentProps) => {
    const mobileMenuContainer = useRef(typeof document !== 'undefined' && document.getElementById('mobile-sidemenu'));

    if (mobileMenuContainer.current) {
        return ReactDOM.createPortal(<MobileMenuContent {...props} />, mobileMenuContainer.current);
    }

    return null;
};

type MenuItem = {
    title: string;
    titleHref?: string;
    href?: string;
    items?: MenuItem[];
    exactTitle?: boolean;
    noDivider?: boolean;
    onClick?: () => void;
    leadingIcon?: JSX.Element;
};

type BottomMenuItem = {
    title: string;
    href: string;
};

type MobileMenuProps = {
    avatar?: string | StaticImageData;
    avatarFallback: string;
    avatarAlt?: string;
    unreadNotificationsNumber?: number;
    locale?: string;
};

type MenuItemsType = {
    default: MenuItem[];
    bottom?: BottomMenuItem[];
};

const MobileMenu = (props: MobileMenuProps): JSX.Element => {
    const { t } = useTranslation('common');

    const { avatar, avatarFallback, avatarAlt, unreadNotificationsNumber, locale } = props;

    const [isOpen, setIsOpen] = useState(false);
    function renderCorrectMenuIcon() {
        return unreadNotificationsNumber > 0 ? <IconMenuUnread24 /> : <IconMenu24 />;
    }

    const menuItems: MenuItemsType = {
        default: [
            {
                leadingIcon: <IconRingingBell16 />,
                title: 'navigation.notifications',
                href: '/notifications',
                noDivider: true
            },
            {
                leadingIcon: <IconSeat16 />,
                title: 'navigation.my-cinemas',
                href: '/my-profile/cinemas'
            },
            {
                leadingIcon: <IconNewRequest16 />,
                title: 'navigation.my-requests',
                href: '/my-profile/event-requests',
                noDivider: true
            },
            {
                leadingIcon: <IconFilmSerie16 />,
                title: 'navigation.movie-list',
                href: '/my-profile/user-movie-lists'
            },
            {
                leadingIcon: <IconSettingsCog16 />,
                title: 'navigation.account-settings',
                href: translateUrl('/settings', locale),
                noDivider: true
            }
        ]
    };

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <div className="flex flex-row items-end z-navigation cursor-pointer space-x-8">
                <MovieSearchButton />

                <MenuButtonWithItems
                    id="login-menu-button"
                    leadingImageSrc={avatar}
                    leadingImageSrcFallback={avatarFallback}
                    leadingImageAlt={avatarAlt}
                    menuIcon={
                        <div>
                            <IconClose24
                                className={classnames(
                                    'absolute transform duration-150',
                                    isOpen ? '' : 'rotate-45 opacity-0'
                                )}
                            />
                            <div className={classnames({ 'rotate-45 opacity-0': isOpen })}>
                                {renderCorrectMenuIcon()}
                            </div>
                        </div>
                    }
                    menuOpen={isOpen}
                    onClick={(setMenuOpen) => {
                        if (!isOpen) {
                            setMenuOpen(true);
                            setIsOpen(true);
                        }
                    }}
                />
            </div>
            <MobileMenuPortal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                t={t}
                toggleMenu={toggleMenu}
                menuItems={menuItems}
                locale={locale}
            />
        </>
    );
};

export default MobileMenu;
