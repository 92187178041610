import create from 'zustand';

import { IsomorphicStore, createIsomorphicStore } from '@/store/index';
import { WebappState, WebappStore } from '@/store/webapp/webappStore.types';

const initialState: WebappState = {
    isWebapp: false,
    isMobileDevice: false,
    showNavigation: true,
    showFooter: true,
    showBottomButton: false,
    bottomButtonProps: undefined
};

const initializeStore: IsomorphicStore<WebappStore, WebappState> = (preloadedState) => {
    return create<WebappStore>((set) => ({
        ...initialState,
        ...preloadedState,
        isMobileDevice: (typeof window !== 'undefined' ? window.innerWidth : 0) <= 768,
        setWebapp() {
            set({
                isWebapp: true
            });
        },
        setWebsite() {
            set({
                isWebapp: false
            });
        },
        setShowNavigation(state) {
            set({
                showNavigation: state
            });
        },
        setShowFooter(state) {
            set({
                showFooter: state
            });
        },
        setHideNavigationMobile(state) {
            set({
                hideNavigationMobile: state
            });
        },
        setBottomButton(state, bottomButtonProps) {
            set({
                showBottomButton: state,
                bottomButtonProps: bottomButtonProps
            });
        }
    }));
};

const {
    StoreProvider: WebappStoreProvider,
    useStore: useWebappStore,
    useCreateStore: useCreateWebappStore
} = createIsomorphicStore<typeof initializeStore, WebappStore, WebappState>(initializeStore);

export { WebappStoreProvider, useWebappStore, useCreateWebappStore };
