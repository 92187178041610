import classnames from 'classnames';
import React, { CSSProperties, SyntheticEvent } from 'react';

export enum ButtonStyle {
    default = 'default',
    default_on_white_bg = 'default_on_white_bg',
    default_no_padding = 'default_no_padding',
    frameless = 'frameless',
    frameless_on_white_bg = 'frameless_on_white_bg',
    inverted = 'inverted',
    inverted_on_white_bg = 'inverted_on_white_bg',
    outlined = 'outlined',
    outlined_on_white_bg = 'outlined_on_white_bg',
    outlined_gray_on_white_bg = 'outlined_gray_on_white_bg',
    outlined_no_padding = 'outlined_no_padding',
    gray_and_white = 'gray_and_white',
    gray_and_white_no_padding = 'gray_and_white_no_padding',
    event_request_status_button = 'event_request_status_button',
    text_only = 'text_only',
    text_only_no_padding = 'text_only_no_padding',
    ghost = 'ghost',
    delete_action = 'delete_action',
    default_no_padding_on_white_bg = 'default_no_padding_on_white_bg',
    cyan_on_dark_bg = 'cyan_on_dark_bg'
}

export enum ContentAlignment {
    start,
    center,
    end
}

export type ButtonProps = {
    children?: React.ReactNode;
    id?: string;
    className?: string;
    onClick?: (event: SyntheticEvent) => void;
    style?: ButtonStyle | CSSProperties;
    contentAlignment?: ContentAlignment;
    type?: 'button' | 'submit' | 'reset';
    leadingIcon?: string | JSX.Element;
    trailingIcon?: string | JSX.Element;
    disabled?: boolean;
    allowLineBreak?: boolean;
    labelClassName?: string;
    labelNotUppercase?: boolean;
};

function Button(props: ButtonProps, ref: React.LegacyRef<HTMLButtonElement> | undefined): JSX.Element {
    const {
        children,
        id,
        className,
        onClick,
        style,
        contentAlignment,
        type,
        leadingIcon,
        trailingIcon,
        allowLineBreak
    } = props;

    const disabled = props.disabled ?? false;

    let buttonStyle: string, hoverEffect: string;

    switch (style) {
        case ButtonStyle.frameless:
            hoverEffect = 'hover:opacity-80';
            buttonStyle = `text-cyan ${disabled ? '' : hoverEffect}`;
            break;
        case ButtonStyle.frameless_on_white_bg:
            hoverEffect = 'hover:opacity-80';
            buttonStyle = `text-cyan-dark ${disabled ? '' : hoverEffect}`;
            break;
        case ButtonStyle.inverted:
            hoverEffect = 'hover:bg-cyan hover:text-darkBlue';
            buttonStyle = `border-2 border-transparent text-cyan ${disabled ? '' : hoverEffect} py-2 px-32`;
            break;
        case ButtonStyle.inverted_on_white_bg:
            hoverEffect = 'hover:bg-cyan-middle hover:text-darkBlue';
            buttonStyle = `border-2 border-transparent text-darkBlue ${disabled ? '' : hoverEffect} py-2 px-32`;
            break;
        case ButtonStyle.outlined:
            hoverEffect = 'hover:bg-cyan hover:text-darkBlue';
            buttonStyle = `border-solid border-2 border-cyan text-cyan ${disabled ? '' : hoverEffect} py-2 px-32`;
            break;
        case ButtonStyle.outlined_on_white_bg:
            hoverEffect = 'hover:bg-cyan-middle hover:border-transparent';
            buttonStyle = `border-solid border-2 border-darkBlue text-darkBlue ${
                disabled ? '' : hoverEffect
            } py-2 px-32`;
            break;
        case ButtonStyle.outlined_gray_on_white_bg:
            hoverEffect = 'hover:bg-cyan-middle hover:opacity-80 hover:text-darkBlue';
            buttonStyle = `border-solid border-2 border-dark-gray text-dark-gray ${
                disabled ? '' : hoverEffect
            } py-2 px-32`;
            break;
        case ButtonStyle.outlined_no_padding:
            hoverEffect = 'hover:bg-cyan hover:text-darkBlue';
            buttonStyle = `border-solid border-2 border-cyan text-cyan ${disabled ? '' : hoverEffect} py-2 px-8`;
            break;
        case ButtonStyle.gray_and_white:
            hoverEffect = 'hover:opacity-80';
            buttonStyle = `border-2 border-transparent bg-white bg-opacity-20 text-white ${
                disabled ? '' : hoverEffect
            } py-2 px-32`;
            break;
        case ButtonStyle.gray_and_white_no_padding:
            hoverEffect = 'hover:text-dark-gray hover:opacity-80';
            buttonStyle = `border-2 border-transparent bg-white bg-opacity-20 text-white ${
                disabled ? '' : hoverEffect
            } py-2 px-8`;
            break;
        case ButtonStyle.text_only:
            hoverEffect = 'hover:opacity-80';
            buttonStyle = `${disabled ? '' : hoverEffect} py-2 px-32`;
            break;
        case ButtonStyle.text_only_no_padding:
            hoverEffect = 'hover:opacity-80';
            buttonStyle = `${disabled ? '' : hoverEffect}`;
            break;
        case ButtonStyle.default_no_padding:
            hoverEffect = 'hover:text-darkBlue hover:opacity-80';
            buttonStyle = `border-2 border-transparent bg-cyan text-darkBlue ${disabled ? '' : hoverEffect} py-2 px-8`;
            break;
        case ButtonStyle.event_request_status_button:
            hoverEffect = 'hover:text-white hover:border-white hover:opacity-80';
            buttonStyle = `border-2 border-gray bg-transparent text-gray text-body-bold-12 ${
                disabled ? '' : hoverEffect
            } py-2 px-8`;
            break;
        case ButtonStyle.default_on_white_bg:
            hoverEffect = 'hover:text-darkBlue hover:opacity-80';
            buttonStyle = `border-2 border-transparent bg-cyan-middle text-darkBlue ${
                disabled ? '' : hoverEffect
            } py-2 px-32`;
            break;
        case ButtonStyle.default_no_padding_on_white_bg:
            hoverEffect = 'hover:text-darkBlue hover:opacity-80';
            buttonStyle = `border-2 border-transparent bg-cyan-middle text-darkBlue ${disabled ? '' : hoverEffect}`;
            break;
        case ButtonStyle.ghost:
            hoverEffect = 'hover:bg-darkBlue hover:bg-opacity-10 hover:text-darkBlue';
            buttonStyle = `border-solid border-2 border-darkBlue text-darkBlue ${
                disabled ? '' : hoverEffect
            } py-2 px-32`;
            break;
        case ButtonStyle.delete_action:
            hoverEffect = 'hover:text-darkBlue hover:opacity-80';
            buttonStyle = `border-2 border-transparent bg-red text-darkBlue ${disabled ? '' : hoverEffect} py-2 px-32`;
            break;
        case ButtonStyle.cyan_on_dark_bg:
            hoverEffect = 'hover:opacity-80';
            buttonStyle = `border-2 border-transparent bg-cyan bg-opacity-20 text-cyan ${
                disabled ? '' : hoverEffect
            } py-2 px-32`;
            break;
        default:
            hoverEffect = 'hover:text-darkBlue hover:opacity-80';
            buttonStyle = `border-2 border-transparent bg-cyan text-darkBlue ${disabled ? '' : hoverEffect} py-2 px-32`;
            break;
    }

    let btnContentAlignment: string;
    switch (contentAlignment) {
        case ContentAlignment.start:
            break;
        case ContentAlignment.end:
            btnContentAlignment = 'justify-end';
            break;
        default:
            btnContentAlignment = 'justify-center';
            break;
    }

    return (
        <button
            id={id}
            className={classnames(
                className,
                buttonStyle,
                disabled ? 'opacity-50 cursor-not-allowed ' : 'active:opacity-50',
                'flex flex-row space-x-8',
                allowLineBreak === true ? 'w-min xl:w-auto' : 'flex-none',
                'outline-none items-center rounded-3 rounded-4 font-montserrat-bold transition-all duration-150',
                btnContentAlignment,
                props.labelNotUppercase || 'uppercase'
            )}
            style={typeof style === 'string' ? undefined : style}
            disabled={disabled}
            onClick={(event) => {
                onClick ? onClick(event) : null;
            }}
            type={type}
            ref={ref}>
            {leadingIcon && <div>{leadingIcon}</div>}
            {children && (
                <div
                    className={classnames('flex my-auto', props.labelClassName, {
                        'text-button-14 md:text-button-16': !props.labelClassName
                    })}>
                    {children}
                </div>
            )}
            {trailingIcon && <div>{trailingIcon}</div>}
        </button>
    );
}

export default React.forwardRef(Button);
