import classnames from 'classnames';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';

import { stringFormatJsx } from '../../../helper/string-helper';

import useCookies from '../../../hooks/useCookies';

import { IconLock16 } from '../../../icons/ic-lock';

import { ButtonStyle } from '../buttons/Button';
import TwoButtonComponent from '../buttons/TwoButtonComponent';
import LinkWithStyle from '../link/LinkWithStyle';
import { CookiePreferencesModal } from './CookiePreferencesModal';
import Modal, { ModalProps } from './Modal';

type CookieModalProps = ModalProps;

function CookiesModal(props: CookieModalProps) {
    const t = props.t || useTranslation('common').t;

    const [showCookiePreferences, setShowCookiePreferences] = useState<boolean>(false);

    const { acceptAllOptionalCookies, rejectAllOptionalCookies } = useCookies();

    function onShowCookiePreferencesClick() {
        props.setModalIsOpen(false);
        setShowCookiePreferences(!showCookiePreferences);
    }

    function saveCookiesPreferenceAndClose() {
        acceptAllOptionalCookies();
        props.setModalIsOpen(false);
    }

    function rejectAllOptionalCookiesAndClose() {
        rejectAllOptionalCookies();
        props.setModalIsOpen(false);
    }

    return (
        <>
            <CookiePreferencesModal
                modalIsOpen={showCookiePreferences}
                setModalIsOpen={setShowCookiePreferences}
                // TODO: Should we reopen the small cookie modal if the cookie preferences modal has been closed?
                // onBackClick={() => props.setModalIsOpen(true)}
                t={props.t}
            />

            <Modal
                {...props}
                onClose={null}
                className={classnames('!container max-w-full !p-24 !min-h-min !mt-auto')}
                showBackground={false}
                showCloseButton={false}
                closeable={false}
                enableOutsideActions>
                <div className="flex flex-col">
                    <div className="flex flex-col lg:flex-row flex-auto lg:space-x-24 space-y-16 lg:space-y-0">
                        <div className="flex flex-col flex-auto lg:w-1/2 space-y-6">
                            <div className="flex flex-row space-x-12 items-center">
                                <span className="text-body-bold-16">{t('common:cookies-modal.your-privacy')}</span>
                                <IconLock16 className="flex-none text-cyan-dark" />
                            </div>
                            <span className="text-body-12">
                                {stringFormatJsx(
                                    t('common:cookies-modal.description'),
                                    <LinkWithStyle
                                        className="underline"
                                        href={'/data-protection'}>
                                        {t('common:cookies-modal.privacy-policy')}
                                    </LinkWithStyle>
                                )}
                            </span>
                        </div>
                        <div className="flex flex-col lg:w-1/2 lg:justify-center space-y-16">
                            <TwoButtonComponent
                                primaryLabel={t('common:cookies-modal.cookie-preferences')}
                                primaryAction={onShowCookiePreferencesClick}
                                primaryButtonProps={{
                                    className: classnames('h-48'),
                                    style: ButtonStyle.outlined_gray_on_white_bg
                                }}
                                secondaryLabel={t('common:cookies-modal.accept')}
                                secondaryAction={saveCookiesPreferenceAndClose}
                                secondaryButtonProps={{
                                    className: classnames('h-48'),
                                    style: ButtonStyle.default_on_white_bg
                                }}
                                stretched
                            />
                            <span
                                className={classnames('flex clickable-element underline text-darkBlue text-body-12')}
                                onClick={rejectAllOptionalCookiesAndClose}>
                                {t('common:cookies-modal.decline-and-continue')}
                            </span>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default CookiesModal;
