import classnames from 'classnames';
import { TFunction, useTranslation } from 'next-i18next';
import { Url } from 'next-translate-routes/types';
import Image from 'next/future/image';
import React from 'react';

import { MovieDto } from '../../../models/movie/MovieDto.types';

import {
    getMoviePosterBlurDataImage,
    getMoviePosterImage,
    getMovieReleaseDate,
    getMovieTitle
} from '../../../helper/movie-helper';

import useLocale from '../../../hooks/useLocale';

import LinkWithStyle from '../../ui/link/LinkWithStyle';

export type MovieSearchBarSearchResultProps = {
    movie: MovieDto;
    onClick?: (movie: MovieDto) => void;
    onClickHref?: Url;
    locale?: string;
    t: TFunction;
};

function MovieSearchBarSearchResult(props: MovieSearchBarSearchResultProps): JSX.Element {
    const { movie, onClick, locale } = props;

    const { locale: browserLocale } = useLocale();

    const t = props.t || useTranslation('movie').t;
    const movieTitle = getMovieTitle(movie, locale);
    const movieReleaseYear = getMovieReleaseDate(movie, browserLocale)?.substring(0, 4);
    const isSeries = movie?.series || false;

    return (
        <div
            className={classnames(
                'px-12 py-12',
                'hover:bg-darkBlue hover:bg-opacity-10',
                'active:opacity-80 transition-all duration-150'
            )}>
            <LinkWithStyle
                className={classnames('h-72 relative cursor-pointer user-select-none overflow-hidden flex-none')}
                onClick={() => onClick && onClick(movie)}
                href={props.onClickHref}>
                <div className="flex flex-row h-full space-x-16">
                    <Image
                        className="flex-none w-48 h-full overflow-hidden rounded-4 object-cover"
                        src={getMoviePosterImage(movie, locale, true)}
                        blurDataURL={getMoviePosterBlurDataImage(movie, locale, true)}
                        width={200}
                        height={300}
                        alt={movieTitle}
                    />
                    <div className="flex-auto flex flex-row justify-between items-center">
                        <div className="flex flex-col justify-center">
                            <span className="w-full text-body-bold-12 overflow-hidden overflow-ellipsis">
                                {movieTitle}
                            </span>
                            {movieReleaseYear && (
                                <span className="flex-none w-full text-body-bold-12 opacity-50">
                                    {movieReleaseYear}
                                </span>
                            )}
                        </div>
                        <span className="text-body-16">{isSeries ? t('movie:series') : t('movie:movie')}</span>
                    </div>
                </div>
            </LinkWithStyle>
        </div>
    );
}

export default MovieSearchBarSearchResult;
